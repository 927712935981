<template>
  <el-row
    class="h-full w-full flex justify-center bg-fixed-bottom"
    :style="
      'background-image: url(' +
      require('@/assets/img/illustrations/sticky.svg') +
      ');'
    "
  >
    <el-col :span="6">
      <el-form
        ref="ruleForm"
        :model="state.signUpForm"
        status-icon
        :rules="rules"
        label-width="120px"
        :label-position="`top`"
        class="login-form"
        @submit.prevent="handleSubmit"
        @keydown.enter.prevent="handleSubmit"
      >
        <el-form-item style="text-align: center">
          <el-image
            v-if="config && config.logo"
            style="width: 200px"
            :src="config.logo ? imageUrl(config.logo, true, 200) : ``"
            :fit="`contain`"
          ></el-image>
        </el-form-item>
        <el-form-item>
          <p class="font-bold text-xl text-center">Reset your password</p>
        </el-form-item>
        <el-form-item label="New password" prop="password">
          <el-input
            v-model="state.signUpForm.password"
            type="password"
            autocomplete="off"
            auto
          ></el-input>
        </el-form-item>
        <el-form-item label="Confirm Password" prop="confirmPassword">
          <el-input
            v-model="state.signUpForm.confirmPassword"
            type="password"
            autocomplete="off"
            auto
          ></el-input>
        </el-form-item>
      </el-form>
      <div>
        <div class="text-center mt-5">
          <el-button :loading="isLoading" type="primary" @click="handleSubmit">
            <span class="font-bold">Reset password</span>
          </el-button>
        </div>
        <div class="mt-5 text-center">
          <router-link :to="{ path: `/auth/login` }" v-slot="{ href }">
            <el-link type="primary" :href="href">Cancel.</el-link>
          </router-link>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { reactive, ref, computed, push } from 'vue'
// import { useServiceAuth } from '@/services'
import { useNotification } from '@/composables'
import { useRouter } from 'vue-router'
import { imageUrl } from '@/utils/image'
import { useStore } from 'vuex'
import { useResource } from '@/services'
import { emailRule, stringRules } from '@/utils/formRules'

export default {
  setup() {
    const { error, warning } = useNotification()
    const router = useRouter()
    const isLoading = ref(false)
    const store = useStore()
    const state = reactive({
      signUpForm: {
        token: '',
        newPassword: '',
        confirmPassword: '',
      },
    })
    let token = ''
    const href = window.location.href
    if (href?.includes('/auth/reset-password?token=')) {
      const url = new URL(href)
      token = url.searchParams.get('token')
    }
    if (token !== '') {
      state.signUpForm.token = token
    } else {
      router.push('/error')
    }
    const rules = {
      password: stringRules('New password', 6, 128),
      confirmPassword: stringRules('Confirm Password', 6, 128),
    }

    let config = computed(() => {
      return store.getters['app/appConfig']
    })

    const handleSubmit = async e => {
      if (!state.signUpForm.password || state.signUpForm.password === '') {
        warning('Please input new password')
        return
      }
      if (state.signUpForm.password !== state.signUpForm.confirmPassword) {
        warning('Your confirm password not match!')
        return
      }
      e.preventDefault()
      isLoading.value = true

      // detect referralCode

      // sign up
      const { add } = useResource('auth/reset-password')
      await add(state.signUpForm)
      isLoading.value = false
      success('Password has been reseted')
    }

    return {
      rules,
      state,
      handleSubmit,
      config,
      imageUrl,
      isLoading,
    }
  },
}
</script>
<style scoped>
.bg-fixed-bottom {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
  background-attachment: fixed;
  padding-top: 10%;
}
</style>
