import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(_component_el_button, {
    size: _ctx.size || 'small',
    type: "danger",
    title: _ctx.label || 'Delete'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_icon, {
        class: _normalizeClass(_ctx.label ? 'el-icon--left' : '')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Delete)
        ]),
        _: 1
      }, 8, ["class"]),
      _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
    ]),
    _: 1
  }, 8, ["size", "title"]))
}