import { ref, computed, reactive, watch, onMounted } from 'vue'
import { useFetchData } from '@/composables'
import { useResource } from '@/services'
import axios from '@/services/axios'

export default function useSellerTeamRepositories() {
  const state = reactive({})
  // const baseResource = 'http://localhost:3001/api/seller-teams'
  const baseResource = 'seller-teams'
  const { get } = useResource(baseResource)

  const getAllSellerTeam = async (search?: string) => {
    try {
      const { data, status } = await get(`list?search=${search?.trim()}`)
      if (status === 200 || status === 201) {
        return data
      }
      return []
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getFilterType = async (params: any): Promise<any> => {
    try {
      const { documents, fetchData } = useFetchData(
        'seller-teams/filter',
        params
      )
      await fetchData()
      return documents
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getReferralInfo = async (id: string, params: any) => {
    try {
      const resource = `seller-teams/${id}/referrals`
      return await axios.get(resource, { params: params })
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const createPayoutRequest = async (id: string, data: any) => {
    try {
      const resource = `${baseResource}/${id}/payout-request/create`
      return await axios.post(resource, data)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const updatePayoutRequest = async (
    id: string, // sellerTeamId
    payoutRequestId: string,
    data: any //data update
  ) => {
    try {
      const resource = `${baseResource}/${id}/payout-request/${payoutRequestId}/update`
      return await axios.patch(resource, data)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getHistoryPayoutRequestSellerTeam = async (id: string, params: any) => {
    try {
      const resource = `${baseResource}/${id}/payout-request/find`
      return await axios.get(resource, { params: params })
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getSellerTeamInfo = async (id: string) => {
    try {
      const resource = `${baseResource}/${id}`
      return await axios.get(resource)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  return {
    getFilterType,
    getReferralInfo,
    state,
    baseResource,
    createPayoutRequest,
    updatePayoutRequest,
    getHistoryPayoutRequestSellerTeam,
    getAllSellerTeam,
    getSellerTeamInfo,
  }
}
