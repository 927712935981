import { computed, reactive, watch } from 'vue'
import { useServiceTheme } from '@/services'
import { useNotification } from '@/composables'
import { useStore } from 'vuex'
import { generateColors } from '@/utils/color'

export default function useThemeRepositories() {
  const primaryProperty = '--el-color-primary'
  const store = useStore()

  const state = reactive({
    isLoading: true,
  })
  const { success } = useNotification()
  const config = computed(() => {
    return store.getters['app/appConfig']
  })
  const defaultConfig = {
    white: `#ffffff`,
    black: `#000000`,
    primary: `#409eff`,
    success: `#67c23a`,
    warning: `#e6a23c`,
    danger: `#f56c6c`,
    error: `#f56c6c`,
    info: `#909399`,
    logo: `https://minio.lattehub.com/img/500/500/resize/60e6d1a82935f80009d77d65/2021/07/23/lattehub-image-60fa4734c8ab6a0119229c20.png`,
    title: '',
    faviconImg: `https://minio.lattehub.com/img/500/500/resize/60e6d1a82935f80009d77d65/2021/07/23/lattehub-image-60fa4734c8ab6a0119229c20.png`,
    templateFile: {
      importOrderFilePath:
        'https://g7-resources-dest.s3.ap-southeast-1.amazonaws.com/Template-import-orders.xlsx',
      importTrackingFilePath:
        'https://g7-resources-dest.s3.ap-southeast-1.amazonaws.com/Template-import-Tracking.xlsx',
      dataCatalogFile: '',
      importFileToCancel:
        'https://static.latteprint.com/templates/Order-Cancel-Template.xlsx',
      importFileToHold:
        'https://static.latteprint.com/templates/Order-Hold-Template.xlsx',
      importSupplierCode:
        'https://static.latteprint.com/templates/import-supplier-code-template.xlsx',
    },
  }
  const configTheme = reactive({ ...defaultConfig })

  const { getAll, add, getPublic } = useServiceTheme()

  const setRootProperty = (property: string, value: string) => {
    document.documentElement.style.setProperty(property, value)
  }

  const getTheme = async () => {
    try {
      state.isLoading = true
      let data: any = {}
      if (localStorage.getItem(`type`) === 'agency') {
        data = (await getAll({})).data as any
      } else {
        data = (await getPublic({})).data as any
      }
      configTheme.white = data.white || configTheme.white
      configTheme.black = data.black || configTheme.black
      configTheme.primary = data.primary || configTheme.primary
      configTheme.success = data.success || configTheme.success
      configTheme.warning = data.warning || configTheme.warning
      configTheme.danger = data.danger || configTheme.danger
      configTheme.error = data.error || configTheme.error
      configTheme.info = data.info || configTheme.info
      configTheme.logo = data.logo || configTheme.logo
      configTheme.title = data.title || configTheme.title
      configTheme.faviconImg = data.faviconImg || configTheme.faviconImg

      // khangnn: lay data config file template
      if (data.templateFile) {
        if (data.templateFile?.importOrderFilePath !== '') {
          configTheme.templateFile.importOrderFilePath =
            data.templateFile.importOrderFilePath
        }
        if (data.templateFile?.importTrackingFilePath !== '') {
          configTheme.templateFile.importTrackingFilePath =
            data.templateFile.importTrackingFilePath
        }
        if (data.templateFile?.dataCatalogFile !== '') {
          configTheme.templateFile.dataCatalogFile =
            data.templateFile.dataCatalogFile
        }
      }
      document.title = data.title || `latte-print`
      const favicon = document.getElementById('favicon') as any
      if (favicon) {
        favicon.href =
          `${process.env.VUE_APP_IMAGE_URL}${data.faviconImg}` ||
          configTheme.faviconImg
      }

      localStorage.setItem(`config`, JSON.stringify(configTheme))
      store.commit('app/configTheme', configTheme)
      setVarConfig()
      state.isLoading = false
    } catch (error) {
      store.commit('app/configTheme', configTheme)
      localStorage.setItem(`config`, JSON.stringify(configTheme))
      console.log(`error`, error)
      //
    }
  }
  const setFaviIcon = () => {
    const favicon = document.getElementById('favicon') as any
    favicon.href = `${process.env.VUE_APP_IMAGE_URL}${configTheme.faviconImg}`
  }
  const reset = async () => {
    try {
      await add({ config: defaultConfig })
      store.commit('configTheme', defaultConfig)
      localStorage.setItem(`config`, JSON.stringify(defaultConfig))
      success()
    } catch (error) {
      //
    }
  }
  watch(
    () => config,
    () => setVarConfig(),
    {
      deep: true,
    }
  )
  const setVarConfig = () => {
    setRootProperty(primaryProperty, configTheme.primary)

    const tintColors = generateColors(configTheme.primary)

    Object.entries(tintColors).forEach(([key, val]) => {
      setRootProperty(`${primaryProperty}-${key}`, val)
    })

    document.documentElement.style.setProperty(
      `--el-color-white`,
      configTheme.white
    )

    document.documentElement.style.setProperty(
      `--el-color-black`,
      configTheme.black
    )

    document.documentElement.style.setProperty(
      `--el-color-danger`,
      configTheme.danger
    )
    document.documentElement.style.setProperty(
      `--el-color-success`,
      configTheme.success
    )

    document.documentElement.style.setProperty(
      `--el-color-info`,
      configTheme.info
    )
    document.documentElement.style.setProperty(
      `--el-color-warning`,
      configTheme.warning
    )
    document.documentElement.style.setProperty(
      `--el-color-error`,
      configTheme.error
    )
  }

  return { setFaviIcon, getTheme, state, configTheme, setVarConfig, reset }
}
