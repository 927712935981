import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Add = _resolveComponent("Add")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_section_layout_content = _resolveComponent("section-layout-content")!

  return (_openBlock(), _createBlock(_component_section_layout_content, _mergeProps(_ctx.content, { onActionCreate: _ctx.openDialogAddDomain }), {
    default: _withCtx(() => [
      _createVNode(_component_Add, { ref: "addDomain" }, null, 512),
      _createVNode(_component_el_table, {
        data: _ctx.documents,
        style: {"width":"100%"},
        isLoading: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "host",
            label: "Name"
          }),
          _createVNode(_component_el_table_column, {
            prop: "connectStatus",
            label: "Status"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.connectStatus == 1 ? 'connected' : 'not connected'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "created_at",
            label: "Date"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_ctx.fomartDate(scope.row.createdAt)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "isLoading"])
    ]),
    _: 1
  }, 16, ["onActionCreate"]))
}