export default [
  {
    path: '/s/products-warehouse',
    name: 'seller.products-warehouse',
    component: () => import('@/pages/seller/products-warehouse/index.vue'),
  },
  {
    path: '/s/products-warehouse/create',
    name: 'seller.products-warehouse.create',
    component: () =>
      import('@/pages/seller/products-warehouse/components/Create.vue'),
  },
  {
    path: '/s/products-warehouse/update/:id',
    name: 'seller.products-warehouse.update',
    component: () =>
      import('@/pages/seller/products-warehouse/components/Create.vue'),
  },
  {
    path: '/s/orders-warehouse',
    name: 'seller.orders-warehouse',
    component: () => import('@/pages/seller/orders-warehouse/index.vue'),
  },
  {
    path: '/s/orders-warehouse',
    name: 'seller.orders-warehouse.add',
    component: () => import('@/pages/seller/orders-warehouse/Add.vue'),
  },
  {
    path: '/s/orders-warehouse/:id',
    name: 'seller.orders-warehouse.update',
    component: () => import('@/pages/seller/orders-warehouse/Add.vue'),
  },
  {
    path: '/s/warehouses',
    name: 'seller.warehouses',
    component: () => import('@/pages/agency/warehouses/index.vue'),
  },
  {
    path: '/s/warehouses/:id',
    name: 'seller.warehouses.Detail',
    component: () => import('@/pages/agency/warehouses/Detail.vue'),
  },
  {
    path: '/s/integrations',
    name: 'seller.integrations',
    component: () => import('@/pages/seller/stores/index.vue'),
  },
  {
    path: '/s/image-library',
    name: 'seller.image-library',
    component: () => import('@/pages/seller/image-library/index.vue'),
  },
  {
    path: '/s/customers',
    name: 'seller.customers',
    component: () => import('@/pages/seller/customers/index.vue'),
  },
  {
    path: '/s/referrals',
    name: 'seller.referrals',
    component: () => import('@/pages/seller/referrals/index.vue'),
  },
  {
    path: '/s/orders',
    name: 'seller.orders',
    component: () => {
      return import('@/pages/seller/orders/List.vue')
    },
  },
  {
    path: '/s/order-items',
    name: 's.order-items',
    component: () => {
      return import('@/pages/seller/order-items/index.vue')
    },
  },
  {
    path: '/s/users',
    name: 'seller.users',
    component: () => {
      return import('@/pages/seller/users/index.vue')
    },
  },
  {
    path: '/s/orders/:id',
    name: 'seller.orders.detail',
    component: () => import('@/pages/seller/orders/Detail.vue'),
  },
  {
    path: '/s/orders/import/histories',
    name: 'seller.orders.import.histories',
    component: () => import('@/pages/seller/order-histories/index.vue'),
  },
  {
    path: '/s/orders/import/histories/:id',
    name: 'seller.orders.import.histories.detail',
    component: () => import('@/pages/seller/order-histories/Detail.vue'),
  },
  {
    path: '/s/invoices',
    name: 'seller.invoices',
    component: () => import('@/pages/seller/invoices/index.vue'),
  },
  {
    path: '/s/invoices/:id',
    name: 'seller.invoices.detail',
    component: () => import('@/pages/seller/invoices/Detail.vue'),
  },
  {
    path: '/s/balance',
    name: 'seller.balance',
    component: () => import('@/pages/seller/balance/index.vue'),
  },
  {
    path: '/s/products',
    name: 's.products',
    component: () => {
      return import('@/pages/seller/product/index.vue')
    },
  },
  {
    path: '/s/products/:id',
    name: 's.product-detail',
    component: () => import('@/pages/seller/product/Edit.vue'),
  },
  {
    path: '/s/mapping/:id',
    name: 's.product-mapping',
    component: () => import('@/pages/seller/mapping/index.vue'),
  },
  {
    path: '/s/mapping-product/:id',
    name: 's.product-mapping-v2',
    component: () => import('@/pages/seller/product/components/Mapping.vue'),
  },
  {
    path: '/s/mapping-products/:id',
    name: 's.product-mapping-v3',
    component: () =>
      import('@/pages/seller/product/components/MappingManyProducts.vue'),
  },
  {
    path: '/s/catalogs',
    name: 's.catalogs',
    component: () => import('@/pages/seller/catalog/index.vue'),
  },
  {
    path: '/s/catalogs/detail/:id',
    name: 's.catalog-detail',
    component: () => import('@/pages/seller/catalog/Detail.vue'),
  },
  {
    path: '/s/payouts',
    name: 's.payouts',
    component: () => import('@/pages/seller/payouts/index.vue'),
  },
  {
    path: '/s/profit-detail',
    name: 's.profit-detail',
    component: () => import('@/pages/seller/profit-detail/index.vue'),
  },
  {
    path: '/s/estimated-profit',
    name: 's.estimated-profit',
    component: () => import('@/pages/seller/estimated-profit/index.vue'),
  },
]
