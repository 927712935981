import { reactive } from 'vue'
import { useFetchData } from '@/composables'
import { Catalog } from '@/interfaces/catalog'
import { useServiceCatalog } from '@/services/useServiceCatalog'
import axios from '@/services/axios'

export default function userCatalogRepositories() {
  const resource = `/catalogs`

  const catalog = reactive<Catalog>({
    _id: '',
    categories: [],
    name: '',
    description: '',
    designs: [],
    variants: [],
    mockupGallery: [],
    productCode: '',
    sizes: [],
    colors: [],
    production: '',
    shipping: '',
  })
  const state = reactive<{ catalogs: Catalog[]; catalog: Catalog }>({
    catalogs: [],
    catalog: {
      _id: '',
      categories: [],
      name: '',
      description: '',
      designs: [],
      variants: [],
      mockupGallery: [],
      productCode: '',
      sizes: [],
      colors: [],
      production: '',
      shipping: '',
    },
  })
  const getCatalogs = async (params: any) => {
    try {
      const { documents, fetchData } = useFetchData(resource, params)
      await fetchData()
      state.catalogs = documents.value as Catalog[]
      return state.catalogs
    } catch (error) {
      console.log(error)
      return null
    }
  }
  const getCatalogsByBasecostId = async (params: any) => {
    try {
      const { documents, fetchData } = useFetchData(resource, params)
      await fetchData()
      state.catalogs = documents.value as Catalog[]
      return state.catalogs
    } catch (error) {
      console.log(error)
      return null
    }
  }
  const getCatalog = async (_id: string | string[]): Promise<any> => {
    const { get } = useServiceCatalog(resource, _id)
    try {
      const { status, data } = await get(_id)
      if (status === 200) {
        Object.assign(state.catalog, data)
      }
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const getCatalogsDefault = async (params: any) => {
    try {
      const { documents, fetchData } = useFetchData('/catalogs', params)
      await fetchData()
      return documents.value as Catalog[]
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getFulfillmentList = async (resource: string) => {
    try {
      const { documents, fetchData } = useFetchData(resource, {})
      await fetchData()
      return documents.value as any[]
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  // const getCatalogForSeller = async (_id: string | string[]) => {
  //   const { get } = useServiceCatalog(resource, _id)
  //   try {
  //     const { status, data } = await get(_id)
  //     console.log('{ status, data }', { status, data })
  //     if (status === 200) {
  //       Object.assign(state.catalog, data)
  //     }
  //     return { status, data }
  //   } catch (error) {
  //     console.log(error)
  //     return {}
  //   }
  // }
  const addCatalog = async (catalog: Catalog) => {
    const { add } = useServiceCatalog(resource, catalog)
    try {
      const { status, data } = await add(catalog)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const update = async (catalog: Catalog) => {
    const { patch } = useServiceCatalog(`${resource}/${catalog._id}`, catalog)
    try {
      const { status, data } = await patch(catalog)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const updateCatalogVariants = async (catalog: Catalog) => {
    const { patch } = useServiceCatalog(
      `${resource}/${catalog._id}/variants`,
      catalog
    )
    try {
      const { status, data } = await patch(catalog)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const deleteById = async (catalog: Catalog) => {
    const { deleteOne } = useServiceCatalog(resource, catalog)
    try {
      const { status, data } = await deleteOne(catalog._id)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  const updatePublishStatus = async (params: any) => {
    try {
      const res = await axios.post(`${resource}/ask-price`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const exportPriceCatalog = async (params: any) => {
    try {
      const res = await axios.get(`${resource}/export/catalog-price`, {
        params,
      })
      return res
    } catch (error) {
      return null
    }
  }

  const importPriceCatalog = async (formData: FormData) => {
    try {
      const res = (await axios.post(
        `${resource}/import/catalog-price`,
        formData
      )) as any
      return res
    } catch (error) {
      return null
    }
  }

  const getFulfillmentPartners = async () => {
    try {
      const res = await axios.get(`public/catalogs/fulfillment-partners/list`)
      return res
    } catch (error) {
      return null
    }
  }

  return {
    useServiceCatalog,
    state,
    getCatalogs,
    getCatalogsByBasecostId,
    addCatalog,
    update,
    updateCatalogVariants,
    deleteById,
    getCatalog,
    //getCatalogForSeller,
    getCatalogsDefault,
    updatePublishStatus,
    exportPriceCatalog,
    importPriceCatalog,
    getFulfillmentList,
    getFulfillmentPartners,
  }
}
