
import { ref, defineComponent } from 'vue'
// import api from "@/services/axios";
// import axios from 'axios';
// import imageHelpers from '@/helpers/image';
import Editor from '@tinymce/tinymce-vue'
import useUploadRepositories, {
  FileItem,
} from '@/repositories/useUploadRepositories'
// function example_image_upload_handler() {
//   console.log('abd');
// }
export default defineComponent({
  props: {
    model: {
      type: String,
      default: 'bodyHtml',
    },
    height: {
      type: Number,
      default: null,
    },
    minHeight: {
      type: Number,
      default: 500,
    },
    dataSet: {
      type: Object,
      // default: () => {},
    },
    data: {
      type: String,
      default: '',
    },
    index: {
      type: Object,
      // default: () => {},
    },
    heading: {
      type: String,
      default: '',
    },
    toolbar: {
      type: String,
      default:
        'bold italic underline strikethrough  | alignleft aligncenter alignright alignjustify |  image media   link anchor  |outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | fontselect fontsizeselect formatselect code',
    },
  },
  components: {
    editor: Editor,
  },
  setup(props, { emit }) {
    const html = ref<string>(props.data)
    const { onUploadImage } = useUploadRepositories()
    const onChangeImage = async (e: any) => {
      const file = e.target.files[0]
      const res = (await onUploadImage(file)) as FileItem & {
        status: 500
        error: any
      }
      return res.path
    }
    const example_image_upload_handler = async function (
      blobInfo: any,
      success: any
    ) {
      const urlImage = await onChangeImage(blobInfo.blob())
      if (urlImage) success(`${process.env.VUE_APP_IMAGE_URL}${urlImage}`)
    }
    const change = () => {
      console.log(html.value)
      emit('callback', html.value, props.dataSet)
    }
    return {
      html,
      onChangeImage,
      onUploadImage,
      change,
      example_image_upload_handler,
    }
  },
})
