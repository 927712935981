
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import Table from './components/Table.vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import Create from './components/Create.vue'
import { useFetchData } from '@/composables'
import Pagination from '@/components/common/Pagination.vue'

export default defineComponent({
  components: {
    Table,
    sectionLayoutContent,
    FilterTable,
    Create,
    Pagination,
  },
  setup() {
    const breadcrumbs = ['Agencies', 'list']
    const title = 'Agencies'
    let loading = ref(false)
    const baseResource = 'agencies'
    const titleCreate = 'Create agency'

    const params = reactive({ page: 1, limit: 20, search: '' })

    const createAgencyDialog = ref<InstanceType<typeof Create>>()
    const create = () => {
      createAgencyDialog.value?.toggle()
    }

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )
    loading = isLoading
    fetchData()

    const onFilterCallback = (data: any) => {
      params.search = data?.search
    }

    watch(params, fetchData)

    const content = computed(() => {
      return { breadcrumbs, title, titleCreate }
    })

    const forceUpdate = async () => {
      console.log('force update')
      loading.value = true
      await fetchData()
      loading.value = false
    }
    return {
      createAgencyDialog,
      documents,
      loading,
      content,
      create,
      forceUpdate,
      params,
      total,
      onFilterCallback,
    }
  },
})
