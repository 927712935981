import axios from '@/services/axios'

export default function useProfitsRepositories() {
  // const baseResource = 'http://localhost:3001/api/profits'
  const baseResource = 'profits'

  const getProfitsSeller = async () => {
    try {
      const resource = `${baseResource}`
      return await axios.get(resource)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getProfitsAgency = async (id: string) => {
    try {
      const resource = `${baseResource}/${id}`
      return await axios.get(resource)
    } catch (error) {
      console.log(error)
      return null
    }
  }
  const getAvailableProfits = async (id: string) => {
    try {
      const resource = `${baseResource}/${id}/payout`
      return await axios.get(resource)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getDetailProfit = async () => {
    try {
      const resource = `${baseResource}/logs`
      return await axios.get(resource)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getExpectedProfit = async (params: any) => {
    try {
      const resource = `dashboards/profits`
      return await axios.get(resource, { params })
    } catch (error) {
      console.log(error)
      return null
    }
  }

  return {
    baseResource,
    getProfitsSeller,
    getProfitsAgency,
    getAvailableProfits,
    getDetailProfit,
    getExpectedProfit,
  }
}
