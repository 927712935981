
import { defineComponent, reactive, ref } from 'vue'
import { createHandler } from '@/services/createHandler'
import { emailRule, stringRules } from '@/utils/formRules'
import { useNotification } from '@/composables'
import { SellerRoles } from '@/utils/userUtils'
import useProxyRepositories from '@/repositories/useProxyRepositories'

export default defineComponent({
  setup(props: any, context: any) {
    const dialogVisible = ref(false)
    let loading = ref(false)
    const userForm = ref()

    let userInfo: any = undefined

    const { getProxies } = useProxyRepositories()

    const listProxy = ref<any[]>([])
    const loadUserInfo = async () => {
      try {
        userInfo = JSON.parse(localStorage?.getItem('info') || '')
        let res = await getProxies()
        listProxy.value = res as any[]
      } catch (e) {
        error('Can not find your account!')
      }
    }
    loadUserInfo()

    const state = reactive({
      form: {
        fullName: '',
        userType: userInfo?.userType,
        authority: 'staff',
        email: '',
        proxies: [],
        status: false,
        password: '',
        confirmPassword: '',
      },
    })

    const formRules = {
      fullName: stringRules('Full Name', 2, 128),
      email: emailRule,
      password: stringRules('Password', 6, 128),
      confirmPassword: stringRules('Confirm Password', 6, 128),
    }

    const { error, success } = useNotification()
    const onSubmit = () => {
      if (state.form.password !== state.form.confirmPassword) {
        error('Confirm Password not same with Password')
        return
      }
      const resource = `users`
      userForm.value.validate(async (valid: any) => {
        if (valid) {
          const { create, isLoading } = createHandler(resource, state.form)
          loading = isLoading
          const { status, data } = await create()
          if (status === 201) {
            dialogVisible.value = !dialogVisible.value
            success('Created')
            context.emit('forceUpdate')
          } else {
            error(data.message)
          }
        }
      })
    }

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const closeHandle = () => {
      console.log('create close')
      state.form.proxies = []
      userForm.value?.resetFields()
      dialogVisible.value = false
    }

    return {
      userForm,
      state,
      formRules,
      loading,
      onSubmit,
      toggle,
      dialogVisible,
      closeHandle,
      SellerRoles,
      listProxy,
    }
  },
})
