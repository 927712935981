import axios from './axios'

export const useServiceAuth = () => {
  const login = <P>(params: P) => {
    return axios.post(`/auth/login`, params)
  }

  const getUserInfo = () => {
    return axios.get(`/users/info`)
  }

  return { login, getUserInfo }
}