
import { defineComponent, reactive } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import axios from '@/services/axios'
import { useNotification } from '@/composables'

export default defineComponent({
  components: {
    sectionLayoutContent,
  },
  name: 'Others Setting',
  setup() {
    const { error, success } = useNotification()
    const state = reactive({
      agency: {
        settings: {},
      },
      contactShippingTitleUrl: '',
    })

    const getAgencyInfo = async () => {
      const url = `/agencies/info`
      const res = await axios.get(url)
      if (res.status === 200 || res.status === 201) {
        state.agency = res.data as any
        state.contactShippingTitleUrl =
          res.data.settings?.contact?.shippingTitle
      }
    }
    getAgencyInfo()

    const updateSettingResource = '/agencies/update-settings'

    const onUpdateSettings = async () => {
      const formData = {
        settings: {
          ...state.agency.settings,
          contact: { shippingTitle: state.contactShippingTitleUrl },
        },
      }
      try {
        const res = await axios.patch(updateSettingResource, formData)
        if (res.status === 200 || res.status === 201) {
          success('Update success!')
        } else {
          error('Error!')
        }
      } catch (error) {
        console.log(error)
      }
    }

    return {
      getAgencyInfo,
      onUpdateSettings,
      state,
    }
  },
})
