import { reactive } from 'vue'
import { useFetchData } from '@/composables'
import { useResource } from '@/services'
import axios from '@/services/axios'
import { ICustomer } from '@/interfaces/order'
import { AxiosResponse } from 'axios'

export default function useOrderRepositories() {
  // const resource = `http://localhost:3002/api/orders`
  const resource = `orders`

  // const invoiceResource = `http://localhost:3002/api/invoices`
  const invoiceResource = `invoices`

  const { get } = useResource(resource)

  const state = reactive<{
    total: any
    orders: any[]
    order: any
    multipleSelection: []
  }>({
    total: 0,
    orders: [],
    order: {
      orderName: '',
      items: [],
      _id: '',
      orderType: '',
      sellerTeam: '',
      seller: '',
      agency: '',
      totalPrice: 0,
      totalRefund: 0,
      totalDiscount: 0,
      shippingFee: 0,
      referenceId2: '',
      referenceId1: '',
      subTotalPrice: '',
      address2: '',
      address1: '',
      countryCode: '',
      country: '',
      zipCode: 0,
      province: '',
      city: '',
      phone: '',
      email: '',
      fullName: '',
      syncAt: '',
      createdDate: '',
      updatedDate: '',
      metadata: {
        requester_id: '',
        designer: '',
        hire_design_status: '',
        notes: '',
        email: '',
      },
    },
    multipleSelection: [],
  })

  const getOrders = async (params: any) => {
    try {
      const { documents, fetchData, total } = useFetchData(resource, {
        ...params,
        proxy: params.proxy?.join(',') || '',
      })
      await fetchData()
      state.total = total
      state.orders = documents.value as any[]
      state.orders = state.orders.map(order => {
        order.orderName = order.orderName || '____'
        return order
      })
      return state.orders
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getOrder = async (id: string | string[]) => {
    try {
      const { data, status } = await get(id)
      const order = data as any
      if (status === 200) {
        // state.order = {
        //   _id: order._id,
        //   orderType: order.orderType,
        //   sellerTeam: order.sellerTeam,
        //   seller: order.seller,
        //   agency: order.agency,
        //   totalPrice: order.totalPrice,
        //   totalRefund: order.totalRefund,
        //   totalDiscount: order.totalDiscount,
        //   shippingFee: order.shippingFee,
        //   referenceId2: order.referenceId2,
        //   referenceId1: order.referenceId1,
        //   subTotalPrice: order.subTotalPrice,
        //   address2: order.address2,
        //   address1: order.address1,
        //   countryCode: order.countryCode,
        //   country: order.country,
        //   zipCode: order.zipCode,
        //   province: order.province,
        //   city: order.city,
        //   phone: order.phone,
        //   email: order.email,
        //   fullName: order.fullName,
        //   syncAt: order.syncAt,
        //   createdDate: order.createdDate,
        //   updatedDate: order.updatedDate,
        //   orderName: order.orderName,
        //   items: order.items,
        // }
        state.order = order
        return state.order
      }
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const onImportCsv = async (file: any) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const res = await axios.post(`${resource}/import/csv`, formData)
      return res
    } catch (error) {
      return null
    }
  }

  const importCsv = async (file: any, createProduct: any) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('createProduct', createProduct)
      const res = await axios.post(`${resource}/import/csv`, formData)
      return res
    } catch (error) {
      return null
    }
  }

  const submitOrder = async (ids: any) => {
    try {
      const res = await axios.post(`${resource}/submit`, { ids })
      return res
    } catch (error) {
      return null
    }
  }

  const exportOrder = async (params: any) => {
    try {
      const res = await axios.get(`${invoiceResource}/search/export-orders`, {
        params,
      })
      return res
    } catch (error) {
      return null
    }
  }

  const reportOrder = async (params: any) => {
    try {
      const res = await axios.get(`${invoiceResource}/search/report-orders`, {
        params,
      })
      return res
    } catch (error) {
      return null
    }
  }

  const approveOrder = async (params: any) => {
    try {
      const res = await axios.post(`${resource}/approve`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const ignoreCannotShipOrder = async (params: any) => {
    try {
      const res = await axios.post(`${resource}/ignore-cannot-ship`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const changeDesignStatusOrder = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.patch(
      `${resource}/change-design-status/${params.id}`,
      params
    )
  }

  const syncDefaultDesignCost = async (
    id: string
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.patch(`${resource}/sync-default-design-cost/${id}`)
  }

  const pickupOrder = async (params: any) => {
    try {
      const res = await axios.post(`${resource}/pickup`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const rejectOrder = async (params: any): Promise<AxiosResponse<any, any>> => {
    return await axios.post(`${resource}/reject`, params)
  }

  const shipmentCancelOrder = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.post(`${resource}/shipment-cancel`, params)
  }

  const holdOrders = async (params: any) => {
    try {
      const res = await axios.post(`${resource}/hold`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const unHoldOrders = async (params: any) => {
    try {
      const res = await axios.post(`${resource}/unhold`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const agencyUnHoldOrders = async (params: any) => {
    try {
      const res = await axios.post(`${resource}/agency-unhold`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const cancelOrders = async (params: any) => {
    try {
      const res = await axios.post(`${resource}/cancel`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const syncedTrackToStore = async (ids: string[]) => {
    try {
      const idsStr = ids.toString()
      const res = await axios.post(
        `${resource}/synced-track-to-store?ids=${idsStr}`
      )
      return res
    } catch (error) {
      return null
    }
  }

  const hireOrderDesigns = async (params: any) => {
    try {
      const res = await axios.post(`${resource}/hire-designs`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const verifyAddressOrders = async (params: any) => {
    try {
      const res = await axios.post(`/orders/confirm-address`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const updateFulfilled = async (params: any) => {
    try {
      const res = await axios.patch(`/order-items/update-fulfilled`, params)
      return res
    } catch (error) {
      return null
    }
  }

  const exportFulfillmentOrders = async () => {
    try {
      const resource = `${invoiceResource}/export-fulfillment`
      const res = await axios.get(resource)
      return res.data
    } catch (error) {
      return null
    }
  }

  // export sản phẩm POD
  const exportOrders = async () => {
    try {
      const resource = `${invoiceResource}/export-pod`
      const res = await axios.get(resource)
      return res.data
    } catch (error) {
      return null
    }
  }

  // export sản phẩm Drop
  const exportDropshipOrders = async () => {
    try {
      const res = await axios.get(`${invoiceResource}/export-dropship`)
      return res.data
    } catch (error) {
      return null
    }
  }

  // export sản phẩm Fake
  const exportFakeOrders = async () => {
    try {
      const res = await axios.get(`${invoiceResource}/export-fk`)
      return res.data
    } catch (error) {
      return null
    }
  }

  // export sản phẩm Fake, sync từ lattef
  const exportFkOrders = async () => {
    try {
      const res = await axios.get(`${invoiceResource}/export-fk-v2`)
      return res.data
    } catch (error) {
      return null
    }
  }

  const exportHistories = async (params: any) => {
    try {
      const { documents, fetchData, total, countTotal } = useFetchData(
        `export-history`,
        params
      )
      await fetchData()
      return { documents, total, countTotal }
    } catch (error) {
      return null
    }
  }

  const editCustomer = async (
    id: string,
    customer: ICustomer
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.post(`${resource}/${id}/customer/update`, customer)
  }

  const editOrder = async (
    id: string,
    order: any
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.put(`${resource}/${id}`, order)
  }

  const markAddressValid = async (
    id: string
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.post(`${resource}/${id}/address/confirm`)
  }

  const checkAddressAgain = async (
    id: string
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.post(`${resource}/${id}/address/checking`)
  }

  const getImportDetail = async (
    id: string
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.get(`${resource}/import/${id}/detail`)
  }

  const getNotificationOrder = async () => {
    return await axios.get(`${resource}/notification/all`)
  }

  // update lai profit
  const calcOrderProfit = async (orderId: string) => {
    // const resource = `http://localhost:3001/api/profits/calc-order-profit`
    const profitResource = `profits/calc-order-profit`
    return await axios.put(profitResource, { orderId })
  }

  // update lai basecost + shipping
  const calcOrderFulfillPrice = async (orderId: string) => {
    return await axios.put(`${resource}/calc-order-basecost`, { orderId })
  }

  const calcOrderFulfillmentPrice = async (body: any) => {
    return await axios.put(`${resource}/calc-order-basecost`, body)
  }

  const updateOrderItemCatalogCode = async (bodyUpdateCatalogCode: any) => {
    return await axios.put(
      `${resource}/update-item-code`,
      bodyUpdateCatalogCode
    )
  }

  const updateOrderChangeHistory = async (data: any) => {
    return await axios.put(`${resource}/update-change-history`, data)
  }

  const getOrderItems = async (params: any) => {
    try {
      const _params = {
        ...params,
        proxy: params.proxy?.join(',') || '',
      }
      const { documents, fetchData, total } = useFetchData(
        '/order-items',
        _params
      )
      await fetchData()
      state.total = total
      state.orders = documents.value as any[]
      return state.orders
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const updateOrderItemArtwork = async (body: any) => {
    return await axios.put(`${resource}/update-item-design`, body)
  }

  const getTotalRevSellerTeam = async (sellerTeamId: string) => {
    return await axios.get(
      `${resource}/seller-team/${sellerTeamId}/total-revenue`
    )
  }

  const changeOrderWithOld = async (orderId: string) => {
    return await axios.patch(`${resource}/${orderId}/order-with-old`)
  }

  return {
    getOrders,
    getOrder,
    onImportCsv,
    importCsv,
    pickupOrder,
    changeDesignStatusOrder,
    syncDefaultDesignCost,
    state,
    submitOrder,
    exportOrder,
    editOrder,
    approveOrder,
    rejectOrder,
    holdOrders,
    unHoldOrders,
    agencyUnHoldOrders,
    cancelOrders,
    exportOrders,
    exportHistories,
    editCustomer,
    markAddressValid,
    getImportDetail,
    getNotificationOrder,
    verifyAddressOrders,
    shipmentCancelOrder,
    syncedTrackToStore,
    hireOrderDesigns,
    updateFulfilled,
    checkAddressAgain,
    exportDropshipOrders,
    exportFakeOrders,
    exportFkOrders,
    exportFulfillmentOrders,
    ignoreCannotShipOrder,
    calcOrderProfit,
    calcOrderFulfillPrice,
    updateOrderItemCatalogCode,
    calcOrderFulfillmentPrice,
    updateOrderChangeHistory,
    getOrderItems,
    reportOrder,
    updateOrderItemArtwork,
    getTotalRevSellerTeam,
    changeOrderWithOld,
  }
}
