
import { defineComponent, computed, reactive } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import Editor from '@/pages/admin/catalog/components/Editor.vue'
import { useRoute } from 'vue-router'
import userMailNotificationsRepositories from '@/repositories/userMailNotificationsRepositories'
import { useNotification } from '@/composables'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Editor,
  },
  name: 'Email Setting',
  setup() {
    const route = useRoute()
    const { findOne, updateEmailTemplate } = userMailNotificationsRepositories()
    const breadcrumbs = [
      {
        text: 'Settings',
        link: 'ag.settings.board',
        params: null,
      },
      {
        text: 'Email',
        link: 'ag.settings.email',
        params: null,
      },
      {
        text: 'Customize',
        link: '',
        params: null,
      },
    ]
    const content = computed(() => {
      return { breadcrumbs }
    })

    const state = reactive({
      dialogVisible: false,
      isLoading: true,
      isLoadingAction: false,
      template: {
        _id: '',
        code: '',
        subject: '',
        html: '',
      },
    })

    const changeTemplateHtml = (html: string, dataSet: any) => {
      dataSet.html = html
    }

    const getData = async () => {
      state.isLoading = true
      let res = await findOne(route.params.id as string)
      // Object.assign(state.template, res.data as any)
      // console.log('data--->', state.template)
      state.template = res.data as any
      state.isLoading = false
    }
    getData()

    const { error, success } = useNotification()
    const onSave = async () => {
      try {
        if (!state.template._id || state.template._id === '') {
          error('Can not find id')
          return
        }
        const res = await updateEmailTemplate(state.template) as any
        if (res.status === 200 || res.status === 201) {
          success('Save success!')
          getData()
        } else {
          error('Save failure!')
        }
      } catch (error: any) {
        console.log(error)
        error('Error in server')
      }
    }

    return {
      content,
      state,
      onSave,
      getData,
      changeTemplateHtml,
    }
  },
})
