import { createRouter, createWebHistory } from 'vue-router'
import Default from '@/layout/Default.vue'
import Auth from '@/layout/Auth.vue'
import SignUp from '@/layout/SignUp.vue'
import SignInWelcome from '@/layout/SignInWelcome.vue'
import SignInRequire from '@/layout/SignInRequire.vue'
import Error from '@/pages/Error.vue'
import ActiveSuccess from '@/pages/ActiveSuccess.vue'
import SubscribeSuccess from '@/pages/SubscribeSuccess.vue'
import ForgotPassword from '@/pages/ForgotPassword.vue'
import ResetPassword from '@/pages/ResetPassword.vue'
import PublicProduct from '@/pages/public/product/index.vue'

import Permission from '@/pages/Permission.vue'

import agencyRoute from './agency/index'
import adminRoute from './admin/index'
import sellerRoute from './seller/index'
import { computed } from 'vue'
import store from '@/store'
import {
  customerSupportBamboozRoutes,
  accountingBamboozRoutes,
  designerBamboozRoutes,
} from './bambooz'

const routes = [
  {
    path: '/products/:id',
    component: PublicProduct,
  },
  {
    path: '/a',
    component: Default,
    children: [...adminRoute],
  },
  {
    path: '/ag',
    component: Default,
    children: [...agencyRoute],
  },
  {
    path: '/s',
    component: Default,
    children: [...agencyRoute, ...sellerRoute],
  },
  {
    path: '/',
    component: Default,
  },
  {
    name: 'login',
    path: '/auth/login',
    component: Auth,
  },
  {
    name: 'register',
    path: '/auth/register',
    component: SignUp,
  },
  {
    name: 'sign-in-welcome',
    path: '/auth/sign-in-welcome',
    component: SignInWelcome,
  },
  {
    name: 'sign-in-require',
    path: '/auth/sign-in-require',
    component: SignInRequire,
  },
  {
    name: 'error',
    path: '/error',
    component: Error,
  },
  {
    name: 'active-success',
    path: '/active-success',
    component: ActiveSuccess,
  },
  {
    name: 'subcribe-success',
    path: '/subcribe-success',
    component: SubscribeSuccess,
  },
  {
    name: 'forgot-password',
    path: '/auth/forgot-password',
    component: ForgotPassword,
  },
  {
    name: 'reset-password',
    path: '/auth/reset-password',
    component: ResetPassword,
  },
  {
    path: '/',
    component: Default,
    children: [
      {
        component: Permission,
        path: '/no-access',
        name: 'no-access',
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory('/cms/'),
  routes: routes,
})
const agencyBlackRoutes = [
  'sellers.email-history',
  'agency.email-history',
  'agency.referrals',
  'agency.payouts',
  'designer.orders',
]
router.beforeEach((to, from, next) => {
  const user = computed(() => store.getters['user/getUser'])
  const pathName: any = to.name || ''
  const userAuthority = user.value.authority
  // Determine if the user has access to the route
  const hasAccess =
    userAuthority === 'staff' ||
    (userAuthority === 'customer_support' &&
      customerSupportBamboozRoutes.includes(pathName)) ||
    (userAuthority === 'accounting' &&
      accountingBamboozRoutes.includes(pathName)) ||
    (userAuthority === 'designer' && designerBamboozRoutes.includes(pathName))
  if (hasAccess) {
    next()
  } else if (
    pathName !== 'no-access' &&
    user.value?.userType === 'agency' &&
    ['staff', 'customer_support', 'accounting', 'designer'].includes(
      userAuthority
    )
  ) {
    next({ path: '/no-access' })
  } else if (
    user.value?.userType === 'agency' &&
    agencyBlackRoutes.includes(pathName)
  ) {
    next({ path: '/no-access' })
  } else next()
  if (
    agencyBlackRoutes.includes(pathName) &&
    user.value?.userType === 'agency' &&
    user.value?.authority === 'staff'
  )
    next({ path: '/' })
  else next()
})

export default router
