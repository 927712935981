
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import Table from './components/Table.vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import Create from './components/Create.vue'
import { useFetchData } from '@/composables'
import Pagination from '@/components/common/Pagination.vue'
import useSellerTeamRepositories from '@/repositories/useSellerTeamRepositories'
import axios from '@/services/axios'
import SelectSellerTeam from '@/components/common/SelectSellerTeam.vue'

export default defineComponent({
  components: {
    Table,
    FilterTable,
    sectionLayoutContent,
    Pagination,
    Create,
    SelectSellerTeam,
  },
  setup() {
    const breadcrumbs = ['Sellers', '']
    const title = 'Sellers'
    // const baseResource = 'http://localhost:3001/api/seller-teams'
    const baseResource = 'seller-teams'
    const titleCreate = 'Create'
    const params = reactive({
      page: 1,
      limit: 10,
      search: null,
      supporter: null,
      basecost: null,
      sellerType: null,
      sellerTeam: null,
      autoApproveOrder: null,
    })

    const createSellerTeamDialog = ref<InstanceType<typeof Create>>()
    const create = () => {
      createSellerTeamDialog.value?.toggle()
    }

    let state = reactive({
      codes: '',
      supporters: '',
      basecosts: '',
    })

    const { documents, fetchData, isLoading, total } = useFetchData(baseResource, params)

    const data = reactive({
      sellerTeams: ref<any[]>([]),
    })

    const { getAllSellerTeam } = useSellerTeamRepositories()
    const loadSellerTeam = async () => {
      let res = await getAllSellerTeam()
      data.sellerTeams = res as any[]
    }

    const mapProfits = ref()

    const loadProfitSellerTeams = async () => {
      const sellerTeamList = [...documents.value] as any
      const sellerTeamIds: any[] = sellerTeamList.map((item: any) => item?.id).filter(Boolean) || []
      if (sellerTeamIds?.length === 0) {
        return
      }
      const params: any = {
        sellerTeamIds: sellerTeamIds.toString(),
      }
      const res = await axios.get(`profits/seller-team`, {
        params,
      })

      let profits: any[] = []
      if (res?.status === 200 && res.data) {
        profits = res.data
      }

      let mapProfit = new Map<string, any>()
      for (const profit of profits) {
        mapProfit.set(profit.sellerTeam, profit)
      }

      mapProfits.value = mapProfit
    }

    const fetchSuporters = async () => {
      // const { documents, fetchData } = useFetchData('seller-teams/filter', {
      //   type: 'supporter',
      // })

      const { documents, fetchData } = useFetchData(`agencies/staffs`, {limit:1000, page:1})
      state.supporters = documents as any
      fetchData()
    }

    fetchSuporters()

    const fetchBasecosts = async () => {
      const { documents, fetchData } = useFetchData('seller-teams/filter', {
        type: 'basecost',
      })
      await fetchData()
      state.basecosts = documents as any
    }

    onMounted(async () => {
      loadSellerTeam()
      fetchData()
      fetchBasecosts()
    })

    const getSellerTeam = async () => {
      await fetchData()
      loadProfitSellerTeams()
    }

    watch(params, getSellerTeam)

    const onFilterCallback = (data: any) => {
      params.search = data?.search
    }

    const content = computed(() => {
      return { breadcrumbs, title, titleCreate }
    })

    const forceUpdate = async () => {
      await fetchData()
    }
    return {
      createSellerTeamDialog,
      documents,
      content,
      create,
      state,
      forceUpdate,
      params,
      total,
      onFilterCallback,
      loadSellerTeam,
      data,
      loadProfitSellerTeams,
      getSellerTeam,
      mapProfits,
      isLoading,
    }
  },
})
