
import { defineComponent, ref, computed, reactive, onMounted, watch } from 'vue'
import { ArrowDown } from '@element-plus/icons'
import { imageUrl } from '@/utils/image'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import useAgencyRepositories from '@/repositories/useAgencyRepositories'
import { Delete } from '@element-plus/icons'
// import { ElMessageBox, ElMessage } from 'element-plus'
import { useFetchData, useNotification } from '@/composables'
import SlotError from '@/components/common/SlotError.vue'
import { useRoute } from 'vue-router'
// import { useResource } from '@/services'
import Pagination from '@/components/common/Pagination.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import { formatDateTime } from '@/utils/dateTime'
import {
  getPublishStatusName,
  getPublishStatusTagColor,
  PUBLISH_STATUS_ARR,
} from '@/utils/catalog'

export default defineComponent({
  components: {
    ArrowDown,
    Delete,
    SlotError,
    Pagination,
    FilterTable,
  },
  props: {
    baseCost: {
      type: Object,
    },
    group: {
      type: String,
    },
  },
  setup(props, { }) {
    const route = useRoute()
    const state = reactive({
      tableData: ref<any[]>([]),
      multipleSelection: [],
      showModal: false,
      action: '$',
      showModalAddSet: false,
      type: ref('add'),
      selectedCatalogs: ref<any[]>([]),
      addedCatalogs: ref<any[]>([]),
      deletedCatalogs: ref<any[]>([]),
      listBaseCost: ref<any[]>([]),
      radio1: 'Add',
      baseCost: ref<string>(''),
      options: ref<any[]>([]),
      selectionCatalogs: ref<any[]>([]),
      baseCostCatalogs: ref<any[]>([]),
      totalAgencyCatalogs: ref<number>(0),
      catalogs: ref<any[]>([]),
      totalAddCatalogs: ref<number>(0),
    })
    const { getCatalogs } = userCatalogRepositories()
    const { error, success } = useNotification()

    const { updateCatalogAgency } = useAgencyRepositories()
    const resource = `/catalogs`

    onMounted(() => {
      getData()
    })

    const getData = async () => {
      if (route.params.id) {
        loadAgencyCatalogs()
      }
      getListProductCatalog()
    }

    const paramAddCatalogs = reactive<any>({
      page: 1,
      limit: 10,
      dropship: null,
      isTrademark: null,
      search: null,
    })

    const getListProductCatalog = async () => {
      // const resource = `http://localhost:3003/api/catalogs/not-added/${route.params.id}/agency`
      const resource = `catalogs/not-added/${route.params.id}/agency`
      const { documents, fetchData, total } = useFetchData(
        resource,
        paramAddCatalogs
      )
      await fetchData()
      state.catalogs = documents.value as any[]
      state.totalAddCatalogs = total.value as number
    }
    watch(paramAddCatalogs, getListProductCatalog)

    const paramAgencyCatalogs = reactive<any>({
      limit: 10,
      page: 1,
      agency: route.params.id,
      type: null,
      search: '',
    })

    const loadAgencyCatalogs = async () => {
      const { documents, fetchData, total } = useFetchData(
        resource,
        paramAgencyCatalogs
      )
      await fetchData()
      state.baseCostCatalogs = documents.value as any[]
      state.totalAgencyCatalogs = total.value as number
    }
    watch(paramAgencyCatalogs, loadAgencyCatalogs)

    const handleSelectionChange = (val: any) => {
      state.multipleSelection = val
    }

    const onSelectionCatalogs = (val: any) => {
      state.selectionCatalogs = val
    }

    const showModalCatalog = () => {
      state.showModal = true
    }

    const onSearchCatalogs = async () => {
      let params = {
        page: 1,
        limit: 10,
        dropship: true,
        isTrademark: false,
        search: null,
      }
      let res = await getCatalogs(params)
      state.catalogs = res as any[]
    }

    const onAddCatalog = (catalog: any) => {
      let selectedCatalog = { ...catalog }
      state.selectedCatalogs = [...state.selectedCatalogs, selectedCatalog]
      state.addedCatalogs = [...state.addedCatalogs, selectedCatalog]
      state.baseCostCatalogs = [...state.baseCostCatalogs, selectedCatalog]
    }

    const onDeleteCatalog = async (catalog: any) => {
      // let findIndex = state.baseCostCatalogs.findIndex(s => s._id === catalog._id)
      // if (findIndex !== -1) {
      //   state.selectedCatalogs.splice(findIndex, 1)
      //   state.baseCostCatalogs.splice(findIndex, 1)
      // }
      // let findIndexAdded = state.addedCatalogs.findIndex(s => s._id === catalog._id)
      // if (findIndexAdded !== -1) {
      //   state.addedCatalogs.splice(findIndex, 1)
      // }

      let params = {
        catalogs: {
          add: [],
          delete: [catalog._id],
        },
      }
      let res = await updateCatalogAgency(route.params.id, params)
      if (res.status === 201 || res.status === 200) {
        success('Delete success!')
        loadAgencyCatalogs()
      } else {
        error(res.data.message)
      }
    }

    const onShowAddSet = () => {
      state.showModalAddSet = true
    }

    const onSaveSelectionCatalogs = async () => {
      try {
        console.log('onSaveSelectionCatalogs')
        let addedCatalogsIds = state.addedCatalogs?.map((s: any) => s._id)
        let params = {
          catalogs: {
            add: addedCatalogsIds,
            delete: [],
          },
        }
        if (route.params.id) {
          let res = await updateCatalogAgency(route.params.id, params)
          if (res.status === 200 || res.status === 201) {
            success('Save success!')
            loadAgencyCatalogs()
          } else {
            error(res.data.message)
          }
        } else {
          error('Not found agency')
        }
      } catch (error: any) {
        console.log('Error', error.response.data)
        error(error.response.data)
      }
    }

    const onAddSelectionCatalogs = async () => {
      state.selectedCatalogs = [...state.selectionCatalogs]
      state.addedCatalogs = [...state.selectionCatalogs]
      state.baseCostCatalogs = [
        ...state.baseCostCatalogs,
        ...state.selectionCatalogs,
      ]
    }

    const onFilterCallback = (data: any) => {
      paramAgencyCatalogs.search = data?.search
    }

    const onFilterCallbackSeach = (data: any) => {
      paramAddCatalogs.search = data?.search
    }

    const columns = [
      {
        title: 'Created',
        dataIndex: 'created',
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Code',
        dataIndex: 'catalogCode',
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
      },
    ]

    return {
      state,
      handleSelectionChange,
      showModalCatalog,
      onDeleteCatalog,
      onAddCatalog,
      onShowAddSet,
      onSelectionCatalogs,
      onAddSelectionCatalogs,
      onSearchCatalogs,
      onSaveSelectionCatalogs,
      imageUrl,
      paramAgencyCatalogs,
      columns,
      onFilterCallback,
      onFilterCallbackSeach,
      paramAddCatalogs,
      formatDateTime,
      getPublishStatusTagColor,
      getPublishStatusName,
      PUBLISH_STATUS_ARR,
    }
  },
})
