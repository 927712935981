
import { defineComponent, reactive, ref, watch } from 'vue'
// import { ElMessageBox } from 'element-plus'
import { createHandler } from '@/services/createHandler'
import { useResource } from '@/services/useResource'
import { useNotification, useFetchData } from '@/composables'
import { required } from '@/utils/formRules'
// import { Plus } from '@element-plus/icons'
// interface Agency {
//   active: number
//   name: string
//   code: string
//   priceList: string
//   createdAt: string
//   updatedAt: string
//   id: string
// }
export default defineComponent({
  setup(props: any, context: any) {
    let dialogVisible = ref(false)
    let loading = ref(false)
    let resource = 'seller-teams'
    const sellerId = ref('')
    const { error, success } = useNotification()
    let sellerteams = ref([])

    const state = reactive({
      form: {
        userId: '',
        name: '',
        basecost: '',
        email: '',
        code: '',
        autoApproveOrder: false,
        active: 0,
        supporter: '',
        sellerType: '',
        mappedUser: '',
        mappedUserId: '',
      },
    })
    const mapUserFormRules = ref({
      mappedUserId: required('mappedUserId'),
    })
    const fectchRecord = async (id: string) => {
      loading.value = true
      const { get } = useResource(resource)
      const { data, status } = await get(id)
      if (status === 200) {
        state.form = data as any
        let findUser = sellerteams.value.find(
          (s: any) => s.mappedUser === sellerId.value
        ) as any
        state.form.mappedUserId = findUser?.id
        loading.value = false
      } else {
        error('Opps...Unexpected error. Please try again latter!')
      }
    }
    const params = reactive({
      sellerType: 'default',
      active: true,
      page: 1,
      limit: 1000,
    })
    const getSellerTeam = async () => {
      const { documents, fetchData, isLoading, total } = useFetchData(
        'seller-teams',
        params
      )
      loading = isLoading
      sellerteams = documents as any

      console.log('seller team -->', sellerteams)

      fetchData()
    }

    getSellerTeam()

    const sellerTeamForm = ref()
    const onMappingUser = async () => {
      const resource = `seller-teams/mapped-user`
      let mapData = {
        userId: state.form.mappedUserId,
        mappedUser: sellerId.value,
      }
      sellerTeamForm.value.validate(async (valid: boolean) => {
        if (valid) {
          const { create, isLoading } = createHandler(resource, mapData)
          const { status } = await create()
          loading = isLoading
          //const status = 200

          if (status === 200 || status === 201) {
            dialogVisible.value = false
            context.emit('change')
            sellerTeamForm.value?.resetFields()
            success('Mapped')
          } else {
            error('Opps...Unexpected error. Please try again latter!')
          }
        }
      })
    }

    const watchModalOpen = watch(dialogVisible, () => {
      if (dialogVisible.value === true) {
        fectchRecord(sellerId.value)
      }
    })

    const handleClose = () => {
      dialogVisible.value = false
      sellerTeamForm.value?.resetFields()
    }
    const toggle = (id: string) => {
      sellerId.value = id
      dialogVisible.value = !dialogVisible.value
    }

    return {
      state,
      onMappingUser,
      sellerTeamForm,
      dialogVisible,
      loading,
      fectchRecord,
      watchModalOpen,
      toggle,
      mapUserFormRules,
      sellerteams,
      getSellerTeam,
      handleClose,
    }
  },
})
