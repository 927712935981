import moment from 'moment'

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DATE_FORMAT = 'YYYY-MM-DD'
const SHORT_MONTH_DATE_FORMAT = 'MMM D, h:m a'

export const formatTime = (date: any, time?: string): string => {
  if (!date) return ''

  if (!time) return moment(date).format(DATE_TIME_FORMAT)

  const datetime = `${date} ${time}`

  return moment(datetime).format(DATE_TIME_FORMAT)
}

export const formatDateTime = (date: any, time?: string): string => {
  if (!date) return ''

  if (!time) return moment(date).format(DATE_FORMAT)

  const datetime = `${date} ${time}`

  return moment(datetime).format(DATE_FORMAT)
}

export const formatShortDateMonthTime = (date: any, time?: string): string => {
  if (!date) return ''

  if (!time) return moment(date).format(SHORT_MONTH_DATE_FORMAT)

  const datetime = `${date} ${time}`

  return moment(datetime).format(SHORT_MONTH_DATE_FORMAT)
}

export const shortcutsDatetimePicker = [
  {
    text: 'Last week',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    },
  },
  {
    text: 'Last month',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: 'Last 3 months',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    },
  },
]
