<template>
  <el-row
    class="flex justify-center w-full h-full bg-fixed-bottom"
    :style="
      'background-image: url(' +
      require('@/assets/img/illustrations/sticky.svg') +
      ');'
    "
  >
    <el-col :span="6">
      <el-form
        ref="ruleForm"
        :model="state.loginForm"
        :rules="rules"
        label-width="120px"
        :label-position="`top`"
        class="font-bold login-form"
        @submit.prevent="handleSubmit"
        @keydown.enter.prevent="handleSubmit"
      >
        <el-form-item v-if="config && config.logo" style="text-align: center">
          <el-image
            style="width: 200px"
            :src="config.logo ? imageUrl(config.logo) : ``"
            :fit="`contain`"
          ></el-image>
        </el-form-item>
        <el-form-item v-if="state.resetPassword">
          <el-alert
            title="The password has been reset successfully"
            type="success"
          />
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input
            v-model="state.loginForm.email"
            type="text"
            autocomplete="off"
            autofocus
          ></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password" class="relative">
          <el-input
            v-model="state.loginForm.password"
            type="password"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <div>
        <div class="mb-4 text-right">
          <router-link
            :to="{ path: `/auth/forgot-password` }"
            v-slot="{ href }"
          >
            <el-link type="primary" :href="href">Forgot password?</el-link>
          </router-link>
        </div>
        <el-button
          :loading="isLoading"
          type="primary"
          class="w-full"
          size="large"
          @click="handleSubmit"
        >
          Sign in
        </el-button>
        <div class="mt-5">
          Don't have an account?
          <router-link :to="{ path: `/auth/register` }" v-slot="{ href }">
            <el-link type="primary" :href="href">Sign up.</el-link>
          </router-link>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { reactive, ref, computed } from 'vue'
// import { useServiceAuth } from '@/services'
import { useNotification } from '@/composables'
import { useRouter } from 'vue-router'
import { imageUrl } from '@/utils/image'
import { useStore } from 'vuex'
import { emailRule, stringRules } from '@/utils/formRules'
import { useServiceApp } from '@/services'
import { AuthorityEnum, UserTypeEnum } from '@/utils/userUtils'

export default {
  setup() {
    const { intergrateApp } = useServiceApp()

    const state = reactive({
      loginForm: {
        email: '',
        password: '',
      },
      resetPassword: false,
    })
    // const { getUserInfo } = useServiceAuth()
    const { error } = useNotification()
    const rules = {
      email: emailRule,
      password: stringRules('Password', 6, 128),
    }
    const isLoading = ref(false)
    const hidePassword = ref(true)

    const router = useRouter()
    const store = useStore()
    let config = computed(() => {
      return store.getters['app/appConfig']
    })

    const href = window.location.href
    if (href?.includes('/auth/login?reset-password=1')) {
      state.resetPassword = true
    }

    const handleSubmit = async e => {
      e.preventDefault()
      try {
        isLoading.value = true
        await store.dispatch('auth/login', state.loginForm)
        // const res = await login(form)
        const isAuthenticated = computed(
          () => store.getters['auth/isAuthenticated']
        )
        if (isAuthenticated.value) {
          await store.dispatch('user/me')
          const user = computed(() => store.getters['user/getUser'])
          if (user.value.authority === UserTypeEnum.OrderExporter) {
            router.push(`/ag/orders`)
          } else {
            switch (user.value.userType) {
              case UserTypeEnum.Administrator:
                router.push(`/a/catalogs`)
                break
              case UserTypeEnum.Supplier:
                router.push(`/a/catalogs`)
                break
              case UserTypeEnum.Agency:
                if (user.value?.authority === AuthorityEnum.Designer) {
                  router.push(`/ds/orders`)
                } else {
                  router.push(`/ag/catalogs`)
                }
                break
              case UserTypeEnum.Seller:
                router.push(`/s/orders`)
                break
              default:
                // Handle any other cases here
                break
            }
          }

          // else {
          //   //Kiểm tra nếu sellerType
          //   user.value.sellerType && user.value.sellerType === 'warehouse'
          //     ? router.push(`/s/orders-warehouse`)
          //     : router.push(`/s/estimated-profit`)
          // }

          // const info = await getUserInfo()
          // localStorage.setItem(`info`, JSON.stringify(info.data))
          // localStorage.setItem(`type`, info.data.userType)
          // store.commit('user/setUser', info.data)
          try {
            let app_token = localStorage.getItem('app-token')
            console.log('Auth.vue -> App Token', app_token)
            let access_token = localStorage.getItem('access-token')
            if (app_token) {
              console.log('Access Token', access_token)
              await intergrateApp({
                access_token: localStorage.getItem(`access-token`),
              })
              localStorage.removeItem('app-token')
            }
          } catch (e) {
            error('can not authorize')
          }
        }
      } catch (e) {
        error(e?.data?.message || null)
      }
      isLoading.value = false
    }

    return {
      rules,
      state,
      handleSubmit,
      config,
      imageUrl,
      isLoading,
      hidePassword,
    }
  },
}
</script>
<style scoped>
.bg-fixed-bottom {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
  background-attachment: fixed;
  padding-top: 10%;
}

::v-deep .login-form .el-form-item__error {
  margin-top: 14px;
}
</style>
