import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-capitalize" }
const _hoisted_2 = { class: "custom-capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_edit_row_button = _resolveComponent("edit-row-button")!
  const _component_delete_row_button = _resolveComponent("delete-row-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_Update = _resolveComponent("Update")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_el_table, {
      ref: "adminUserTable",
      data: _ctx.documents,
      style: {"width":"100%"},
      lazy: _ctx.isLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          property: "fullName",
          label: "Full Name",
          "min-width": 10
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_router_link, {
              to: { path: `/ag/users/${scope.row.id}` }
            }, {
              default: _withCtx(({ href }) => [
                _createVNode(_component_el_link, {
                  href: href,
                  type: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(scope.row.fullName), 1)
                  ]),
                  _: 2
                }, 1032, ["href"])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "email",
          label: "Email",
          "min-width": 10
        }),
        _createVNode(_component_el_table_column, {
          property: "userType",
          label: "Role",
          "min-width": 10
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(scope.row.userType?.replace('_', ' ')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "authority",
          label: "Authority",
          "min-width": 10
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(scope.row.authority?.replace('_', ' ')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "Actions",
          "min-width": 5
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_edit_row_button, {
              onClick: ($event: any) => (_ctx.openUpdateDialog(scope.row))
            }, null, 8, ["onClick"]),
            _createVNode(_component_delete_row_button, {
              onClick: ($event: any) => (_ctx.handleDelete(scope.row))
            }, null, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data", "lazy"]), [
      [_directive_loading, _ctx.isLoading]
    ]),
    _createVNode(_component_Update, {
      ref: "userUpdateDialog",
      onForceUpdate: _ctx.forceUpdate
    }, null, 8, ["onForceUpdate"])
  ], 64))
}