import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterTable = _resolveComponent("FilterTable")!
  const _component_Table = _resolveComponent("Table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_Create = _resolveComponent("Create")!
  const _component_section_layout_content = _resolveComponent("section-layout-content")!

  return (_openBlock(), _createBlock(_component_section_layout_content, _mergeProps(_ctx.content, { onActionCreate: _ctx.create }), {
    default: _withCtx(() => [
      _createVNode(_component_FilterTable, { onOnFilterCallback: _ctx.onFilterCallback }, null, 8, ["onOnFilterCallback"]),
      _createVNode(_component_Table, {
        documents: _ctx.documents,
        onUpdateFinish: _ctx.forceUpdate
      }, null, 8, ["documents", "onUpdateFinish"]),
      _createVNode(_component_pagination, {
        total: _ctx.total,
        pagination: _ctx.params.page,
        "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.page) = $event)),
        limit: _ctx.params.limit,
        "onUpdate:limit": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.limit) = $event))
      }, null, 8, ["total", "pagination", "limit"]),
      _createVNode(_component_Create, {
        onChange: _ctx.forceUpdate,
        ref: "createAgencyDialog"
      }, null, 8, ["onChange"])
    ]),
    _: 1
  }, 16, ["onActionCreate"]))
}