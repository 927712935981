
import { defineComponent, ref, reactive, computed } from 'vue'
import { useNotification } from '@/composables'
import UploadFiles from '@/components/common/UploadFiles.vue'
import axios from '@/services/axios'
import { downloadFile } from '@/utils/file'
import { useStore } from 'vuex'
import { IMPORT_TYPE } from '@/utils/catalog'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'

interface FileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  preview?: string
  originFileObj?: any
  file: string | Blob
  raw: string | Blob
}

export default defineComponent({
  components: {
    UploadFiles,
  },
  setup(props, context) {
    let dialogVisible = ref(false)
    const resource = 'import'
    const url = resource
    const isShowTemplate = ref(true)
    const { error, success, warning } = useNotification()

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const state = reactive({
      isLoading: false,
    })

    const fileList = ref<FileItem[]>([])

    const onChangeFiles = (files: any) => {
      fileList.value = files
    }

    const { importPriceCatalog } = userCatalogRepositories()

    const onSubmit = async () => {
      if (!fileList.value || fileList.value.length === 0) {
        warning('Please import your file!')
        return
      }
      let file = fileList.value[0].raw as any
      if (!file.name.endsWith('.xlsx')) {
        error('Wrong file! Please import csv file.')
        return
      }

      try {
        state.isLoading = true
        const formData = new FormData()
        formData.append('file', file)
        formData.append('importType', IMPORT_TYPE.IMPORT_SUPPLIER_PRICE)
        const res = (await importPriceCatalog(formData)) as any

        if (
          (res?.status === 200 || res?.status === 201) &&
          res?.data?.url?.length > 0
        ) {
          downloadFile(res.data.url)
          success('Import success!')
          close()
          context.emit('uploadFinish')
        } else {
          error(res.data.message)
        }
      } catch (ex) {
        error('Error when import.')
        console.log(ex)
      }
      state.isLoading = false
    }

    const onDownloadTemplate = () => {
      const url = ''
      // downloadFile(url)
    }

    const close = () => {
      fileList.value = []
      dialogVisible.value = false
    }

    return {
      url,
      isShowTemplate,
      toggle,
      dialogVisible,
      onSubmit,
      onChangeFiles,
      state,
      close,
      downloadFile,
      onDownloadTemplate,
      IMPORT_TYPE,
    }
  },
})
