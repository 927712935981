import axios from '@/services/axios'

export interface SupplierLog {
  _id?: string
  catalog: string
  variantChanged: any[]
  status: string
  note: string
  type: string
}

export default function userCatalogRepositories() {
  const resource = `products/supplier-log`
  const saveLog = async (supplierLog: SupplierLog) => {
    try {
      const res = await axios.post(`${resource}/save-log`, supplierLog)
      return res
    } catch (error) {
      return null
    }
  }

  const getHistoryLog = async (params: any) => {
    try {
      const res = await axios.get(`${resource}/histories`, { params })
      return res
    } catch (error) {
      return null
    }
  }

  return {
    saveLog,
    getHistoryLog,
  }
}
