
import { defineComponent, reactive, ref, watch } from 'vue'
import { ElMessageBox } from 'element-plus'
import { updateHandler } from '@/services/updateHandler'
import { useResource } from '@/services/useResource'
import { emailRule, stringRules } from '@/utils/formRules'

interface IAgency {
  id: string
  name: string
  basecost: string
  email: string
  code: string
  autoApproveOrders: boolean
  active: number
}

export default defineComponent({
  setup(props: any, context: any) {
    let agencyId = ref('')
    let dialogVisible = ref(false)
    let loading = ref(false)
    const agencyForm = ref()
    let resource = 'agencies'

    const formData = reactive<{ agency: IAgency }>({
      agency: {
        id: '',
        name: '',
        basecost: '',
        email: '',
        code: '',
        autoApproveOrders: false,
        active: 0,
      },
    })

    const toggle = (id: string) => {
      dialogVisible.value = !dialogVisible.value
      agencyId.value = id
    }

    const formRules = {
      name: stringRules('code', 3, 128),
      code: stringRules('code', 3, 128),
    }

    const fectchRecord = async (id: string) => {
      loading.value = true
      const { get } = useResource(resource)
      const { data, status } = await get(id)
      if (status === 200) {
        formData.agency = data as any
      }
      loading.value = false
    }

    const onSubmit = async () => {
      const resource = `agencies/${agencyId.value}`
      let patchData = {
        name: formData.agency.name,
        code: formData.agency.code,
        active: formData.agency.active,
      }
      agencyForm.value.validate(async (valid: any) => {
        if (valid) {
          const { update, isLoading } = updateHandler(resource, patchData)
          loading = isLoading
          const { status } = await update()
          if (status === 200) {
            dialogVisible.value = false
            context.emit('change')
          }
        }
      })
    }

    const watchModalOpen = watch(dialogVisible, () => {
      if (dialogVisible.value === true) {
        fectchRecord(agencyId.value)
      }
    })

    return {
      agencyForm,
      onSubmit,
      dialogVisible,
      loading,
      formData,
      toggle,
      fectchRecord,
      watchModalOpen,
      formRules,
    }
  },
})
