
import { defineComponent, ref } from 'vue'
import Update from './Update.vue'
import { Delete, Edit } from '@element-plus/icons'
import { deleteHandler } from '@/services/deleteHandler'
import { ElMessageBox } from 'element-plus'
import { useNotification } from '@/composables'
import { capitalizeFirstLetter } from '@/utils/string'

export default defineComponent({
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Update,
    Edit,
    Delete,
  },
  setup(props: any, context: any) {
    let selected = ref({})
    const baseResource = 'users'
    const userUpdateDialog = ref<InstanceType<typeof Update>>()
    let loading = ref(false)
    const { success, error } = useNotification()
    const handleDelete = async (user: any) => {
      ElMessageBox.confirm(`Are you sure to delete this user?`)
        .then(async () => {
          const { deleteById, isLoading } = deleteHandler(baseResource)
          loading = isLoading
          const { status, data } = await deleteById(user.id)
          if (status === 200) {
            success('Deleted')
            context.emit('updateFinish')
          } else {
            error(data.message)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    const openUpdateDialog = (row: any) => {
      selected.value = row
      userUpdateDialog.value?.toggle(row.id)
    }

    const forceUpdate = async () => {
      context.emit('updateFinish')
    }

    return {
      loading,
      userUpdateDialog,
      selected,
      handleDelete,
      forceUpdate,
      openUpdateDialog,
      capitalizeFirstLetter,
    }
  },
})
