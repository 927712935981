
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import userMailNotificationsRepositories from '@/repositories/userMailNotificationsRepositories'
import { useNotification } from '@/composables'
import axios from '@/services/axios'

export default defineComponent({
  components: {
    sectionLayoutContent,
  },
  name: 'Sign up Setting',
  setup() {
    let loading = ref(false)
    const baseResource = 'mail-notifications'
    const { addTemplate } = userMailNotificationsRepositories()
    const { error, success } = useNotification()
    const dialogVisible = ref(false)

    const breadcrumbs = [
      {
        text: 'Settings',
        link: 'ag.settings.board',
        params: null,
      },
      {
        text: 'Email',
        link: '',
        params: null,
      },
    ]
    const content = computed(() => {
      return { breadcrumbs }
    })

    const state = reactive({
      agency: {},
      settings: {
        customerEmail: '',
        contactEmail: '',
      },
    })

    const getAgencyInfo = async () => {
      const url = `/agencies/info`
      const res = await axios.get(url)
      if (res.status === 200 || res.status === 201) {
        state.agency = res.data as any
        if (res.data.settings) {
          Object.assign(state.settings, res.data.settings)
        }
      }
    }

    const updateSettingResource = '/agencies/update-settings'

    const onUpdateEmailSettings = async () => {
      console.log(state.settings)
      const formData = {
        settings: {
          ...state.settings,
        },
      }
      try {
        const res = await axios.patch(updateSettingResource, formData)
        if (res.status === 200 || res.status === 201) {
          success('Update customer email success!')
        } else {
          error('Error!')
        }
      } catch (error) {
        console.log(error)
      }
    }

    const data = reactive({
      defaultTemplates: ref<any[]>([]),
      currentTemplates: ref<any[]>([]),
      templates: ref<any[]>([]),
    })

    const getDefaultTemplate = async () => {
      try {
        const url = `${baseResource}/default-templates`
        const { documents, fetchData } = useFetchData(url, {})
        await fetchData()
        data.defaultTemplates = documents.value as any[]
      } catch (error) {
        console.log(error)
        return null
      }
    }

    const getCurrentTemplate = async () => {
      try {
        const { documents, fetchData } = useFetchData(baseResource, {})
        await fetchData()
        data.currentTemplates = documents.value as any[]
      } catch (error) {
        console.log(error)
        return null
      }
    }

    onMounted(async () => {
      getData()
      getAgencyInfo()
    })

    const getData = () => {
      getDefaultTemplate()
      getCurrentTemplate()
    }

    const onClickTemplate = (template: any) => {
      console.log(template)
    }

    const checkExistedTemplate = (template: any) => {
      let existed = false
      const findIndex = data.currentTemplates?.findIndex(
        item => item.code === template.code
      )
      if (findIndex && findIndex === -1) {
        existed = true
      }
      return existed
    }

    const onAddNewTemplate = async (template: any) => {
      try {
        getCurrentTemplate()
        const findIndex = data.currentTemplates?.findIndex(
          item => item.code === template.code
        )
        if (findIndex && findIndex !== -1) {
          dialogVisible.value = false
          getData()
          return
        }
        const res = addTemplate(template) as any
        if (res.status === 200 || res.status === 201) {
          success('Add template success!')
          getData()
        } else {
          error('Add failure!')
        }
      } catch (error: any) {
        console.log(error)
        error('Error!')
      }
    }

    return {
      content,
      onClickTemplate,
      getDefaultTemplate,
      getCurrentTemplate,
      getData,
      addTemplate,
      checkExistedTemplate,
      onAddNewTemplate,
      loading,
      data,
      dialogVisible,
      getAgencyInfo,
      onUpdateEmailSettings,
      updateSettingResource,
      state,
    }
  },
})
