
import { defineComponent, reactive, ref, watch } from 'vue'
import { formatTime } from '@/utils/dateTime'
import userSupplierLogRepositories from '@/repositories/userSupplierLogRepositories'
import Pagination from '@/components/common/Pagination.vue'
import {
  getPublishStatusName,
  getPublishStatusTagColor,
  PUBLISH_STATUS_ARR,
  CATALOG_PUBLISH_STATUS,
  SupplierLogType,
} from '@/utils/catalog'
import DetailHistoryLogModal from './DetailHistoryLogModal.vue'

export default defineComponent({
  components: { Pagination, DetailHistoryLogModal },
  props: {},
  setup(props, context) {
    const dialogVisible = ref(false)
    const isLoading = ref(true)
    const documents = ref([])

    const state = reactive({
      total: 0,
    })

    const params = reactive({
      page: 1,
      limit: 10,
      catalog: '',
      // _id: '',
      type: SupplierLogType.ChangeSupplierPrice,
    })

    const { getHistoryLog } = userSupplierLogRepositories()

    const getData = async () => {
      isLoading.value = true
      if (!params.catalog) {
        return
      }

      const res = await getHistoryLog(params)
      documents.value = res?.data?.data
      state.total = res?.data?.pagination?.total || 0
      isLoading.value = false
    }

    const toggle = async (_id: string, type: SupplierLogType) => {
      dialogVisible.value = !dialogVisible.value
      params.catalog = _id
      params.type = type
      getData()
    }

    // watch(params, getData)

    const close = () => {
      dialogVisible.value = false
    }

    const detailHistoryLogModal =
      ref<InstanceType<typeof DetailHistoryLogModal>>()
    const detailHistoryLogModalToggle = (supplierLog: any) => {
      detailHistoryLogModal.value?.toggle(supplierLog)
    }

    const columns = [
      {
        title: 'Catalog',
        dataIndex: 'catalog',
        minWidth: 15,
      },
      {
        title: 'Updated User',
        dataIndex: 'createdUser',
        minWidth: 10,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        minWidth: 8,
      },
      {
        title: 'Log Date',
        dataIndex: 'createdDate',
        minWidth: 10,
      },
      {
        title: 'Note',
        dataIndex: 'note',
        minWidth: 12,
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        minWidth: 8,
      },
    ]

    return {
      dialogVisible,
      toggle,
      close,
      getData,
      isLoading,
      documents,
      formatTime,
      getPublishStatusName,
      getPublishStatusTagColor,
      PUBLISH_STATUS_ARR,
      CATALOG_PUBLISH_STATUS,
      columns,
      params,
      detailHistoryLogModal,
      detailHistoryLogModalToggle,
      state,
    }
  },
})
