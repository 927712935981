import { reactive } from 'vue'
// import { useFetchData } from "@/composables";
import { useResource } from '@/services'
import { Product } from '@/interfaces/product'
import { useServiceProduct } from '@/services/useServiceProduct'
import axios from '@/services/axios'
import { AxiosResponse } from 'axios'
export default function userProductRepositories() {
  // const resource = `http://localhost:3003/api/products`
  const resource = `/products`
  const product = reactive<Product>({
    agency: '',
    handle: '',
    mockup: '',
    options: [],
    otherMockup: [],
    productType: '',
    sellerTeam: '',
    serverId: '',
    title: '',
    variants: [],
  })
  const getAllProduct = async () => {
    console.log('getAll')
  }
  const getProductById = async (_id: string | string[]) => {
    const { get } = useServiceProduct(resource, _id)
    try {
      const { status, data } = await get(_id)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const getAll = async (params: any) => {
    const { getAll } = useResource(resource)
    try {
      return (await getAll(params)) as AxiosResponse
    } catch (error) {
      return error as AxiosResponse
    }
  }
  const updateProduct = async (product: Product) => {
    const { edit } = useServiceProduct(`${resource}/${product._id}`, product)
    try {
      const { status, data } = await edit(product)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const deleteProductById = async (product: Product) => {
    const { deleteOne } = useServiceProduct(resource, product)
    try {
      const { status, data } = await deleteOne(product._id)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  const countProduct = async () => {
    const { count } = useServiceProduct(resource, product)
    try {
      const { status, data } = await count()
      console.log(status, data)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  // const baseResource = 'http://localhost:3003/api/products'
  const baseResource = 'products'
  const updatePersonalizeStatus = async (params: any) => {
    try {
      const res = await axios.put(`${baseResource}/update/personalized`, params)
      return res
    } catch (error) {
      return {}
    }
  }
  const syncVariantDataToOrderItem = async (productId: string) => {
    try {
      const res = await axios.put(`${baseResource}/${productId}/sync-to-item`)
      return res
    } catch (error) {
      return {}
    }
  }

  const exportVariants = async (id: any) => {
    try {
      const res = await axios.get(`${resource}/${id}/variants/export`)
      return res
    } catch (error) {
      return null
    }
  }

  const exportProducts = async (params: any) => {
    try {
      const res = await axios.get(`${resource}/list/export`, { params: params })
      return res
    } catch (error) {
      return null
    }
  }

  const exportCatalogs = async (type: string) => {
    try {
      const res = await axios.get(
        `catalogs/export/agency-catalogs?exportType=${type}`
      )
      return res
    } catch (error) {
      return null
    }
  }

  return {
    useServiceProduct,
    getAllProduct,
    getProductById,
    updateProduct,
    deleteProductById,
    product,
    getAll,
    countProduct,
    updatePersonalizeStatus,
    syncVariantDataToOrderItem,
    exportVariants,
    exportProducts,
    exportCatalogs,
  }
}
