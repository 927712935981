import { reactive } from 'vue'
import { useFetchData } from '@/composables'

export default function useProxyRepositories() {
  const state = reactive({})

  const getProxies = async () => {
    try {
      const { documents, fetchData } = useFetchData('orders/proxy/list')
      await fetchData()
      return documents.value
    } catch (error) {
      console.log(error)
      return null
    }
  }

  return {
    getProxies,
    state,
  }
}
