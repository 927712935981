import axios from 'axios'
import httpStatus from '../config/httpStatus'
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = "http://45.77.170.201:7999/api";
// axios.defaults.headers.common["Authorization"] = window.localStorage.getItem("jwt");
// axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
const CancelToken = axios.CancelToken
const source = CancelToken.source()
const config = {
  // baseURL: "/api",
  baseURL: getBaseURL(),
  validateStatus: (status: number) => {
    return (
      (status >= httpStatus.StatusOK &&
        status < httpStatus.StatusMultipleChoices) ||
      status === httpStatus.StatusBadRequest
    )
  },
  cancelToken: source.token,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true // Check cross-site Access-Control
}
const _axios = axios.create(config)

_axios.interceptors.request.use(
  (config: any) => {
    // Do something before request is sent
    if (window.localStorage.getItem('app-token'))
      /* eslint-disable @typescript-eslint/no-explicit-any */

      config.headers.Authorization = `Bearer ${window.localStorage.getItem(
        'app-token'
      )}`

    config.headers.app_token = window.localStorage.getItem('app-token')

    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  response => {
    // Do something with response data
    return response
  },
  error => {
    // Do something with response error
    return Promise.reject(error.response)
  }
)

function getBaseURL() {
  if (window.location.href.includes('localhost')) {
    // if (process.env.VUE_APP_DEV_ROLE === 'seller') {
    //   return process.env.VUE_APP_API_SELLER_URL.replace('/api', '/sapp')
    // }
    return process.env.VUE_APP_API_APP_URL
  }
  return '/sapp'
}

export default _axios
