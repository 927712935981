
import { reactive, ref, computed } from 'vue'
// import { useServiceAuth } from '@/services'
import { useNotification } from '@/composables'
import { useRouter } from 'vue-router'
import { imageUrl } from '@/utils/image'
import { useStore } from 'vuex'
import { useResource } from '@/services'
import { emailRule, stringRules } from '@/utils/formRules'

export default {
  setup() {
    const state = reactive({
      signUpForm: {
        email: '',
      },
      status1: ref<boolean>(false),
      status2: ref<boolean>(false),
    })
    const { error, warning } = useNotification()
    const rules = {
      email: emailRule,
    }
    const isLoading = ref(false)
    const signUpSuccessDialogVisible = ref(false)

    const router = useRouter()
    const store = useStore()
    let config = computed(() => {
      return store.getters['app/appConfig']
    })

    const handleSubmit = async (e: { preventDefault: () => void }) => {
      if (!state.signUpForm.email || state.signUpForm.email === '') {
        warning('Please input email')
        return
      }
      e.preventDefault()
      try {
        isLoading.value = true

        // sign up
        const { add } = useResource('auth/forgot-password')
        let res = await add(state.signUpForm)
        // Object.assign(entity, res.data)
        if (res.status === 201 || res.status === 200) {
          state.status2 = true
          state.status1 = false
          //router.push(`/auth/login?reset-password=1`)
        } else {
          state.status1 = true
          state.status2 = false
        }
      } catch (e) {
        state.status1 = true
        state.status2 = false
        error(e?.data?.message || null)
      }
      isLoading.value = false
    }

    return {
      rules,
      state,
      handleSubmit,
      config,
      imageUrl,
      isLoading,
      signUpSuccessDialogVisible,
    }
  },
}
