import Agencies from '@/pages/admin/agencies/index.vue'
import SupplierCatalog from '@/pages/admin/supplier-catalog/index.vue'
import Detail from '@/pages/admin/agencies/components/Detail.vue'
import Users from '@/pages/admin/users/index.vue'

export default [
  {
    path: '/a/users',
    name: 'a.users',
    component: Users,
    children: [
      {
        path: '/ag/users/:id',
        name: 'ag.users.profile',
        component: Users,
      },
    ],
  },
  {
    path: '/a/agencies',
    name: 'a.agencies',
    component: Agencies,
  },
  {
    path: '/a/supplier-catalog',
    name: 'a.supplier-catalog',
    component: SupplierCatalog,
  },
  {
    path: '/a/agencies/:id',
    name: 'a.agencies.profile',
    component: Detail,
  },
  {
    path: '/a/catalogs',
    name: 'a.catalogs',
    component: () => import('@/pages/admin/catalog/index.vue'),
  },
  {
    path: '/a/catalogs/create',
    name: 'a.catalog-create',
    component: () => import('@/pages/admin/catalog/Create.vue'),
  },
  {
    path: '/a/catalogs/create-dropship',
    name: 'a.catalog-create.dropship',
    component: () => import('@/pages/admin/catalog/DropCreate.vue'),
  },
  {
    path: '/a/catalogs/detail-drop/:id',
    name: 'a.catalog-detail.drop',
    component: () => import('@/pages/admin/catalog/DropUpdate.vue'),
  },
  {
    path: '/a/catalogs/update/:id',
    name: 'a.catalog.update',
    component: () => import('@/components/catalogs/UpdateCatalog.vue'),
  },
  {
    path: '/a/catalogs/detail/:id',
    name: 'a.catalog-detail',
    component: () => import('@/pages/admin/catalog/Update.vue'),
  },
  {
    path: '/a/category-catalog',
    name: 'a.category-catalog',
    component: () => import('@/pages/admin/category-catalog/index.vue'),
  },
  {
    path: '/a/base-cost',
    name: 'admin.baseCost',
    component: () => import('@/pages/admin/base-cost/index.vue'),
  },
  {
    path: '/a/base-cost/create',
    name: 'admin.baseCost.create',
    component: () => import('@/pages/admin/base-cost/Create.vue'),
  },
  {
    path: '/a/base-cost/update/:id',
    name: 'admin.baseCost.update',
    component: () => import('@/pages/admin/base-cost/Update.vue'),
  },
]
