
import { ArrowDown } from '@element-plus/icons'
import { defineComponent, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useFetchData } from '@/composables'
import { ElMessageBox } from 'element-plus'
import { useNotification } from '@/composables'
import { updateHandler } from '@/services/updateHandler'
import Pagination from '@/components/common/Pagination.vue'
import { formatterDollars } from '@/utils/formatter'
import { formatTime } from '@/utils/dateTime'

export default defineComponent({
  components: {
    Pagination,
    ArrowDown,
  },
  setup(props: any, context: any) {
    const { success, error, info } = useNotification()
    let loading = ref(false)
    const route = useRoute()
    const params = reactive({
      page: 1,
      limit: 5,
      code: '',
      payWith: '',
      status: '',
      transactionId: '',
    })
    const baseResource = 'deposits/seller-teams/'
    const resource = `${baseResource}/${route.params.id}`

    const { documents, fetchData, total } = useFetchData(resource, params)
    watch(params, fetchData)

    const loadDeposits = async () => {
      loading.value = true
      await fetchData()
      loading.value = false
    }
    loadDeposits()

    const forceUpdate = async () => {
      context.emit('updateFinish')
    }

    const view = (agency: any) => {
      console.log(agency)
    }

    const toggle = async () => {
      loadDeposits()
    }

    const updateStatus = async (newStatus: string, depositId: string) => {
      const updateResource = `deposits/seller-teams/${route.params.id}/${depositId}`
      const message = `Are you sure to ${newStatus} this record?`
      ElMessageBox.confirm(message)
        .then(async () => {
          const { update, isLoading } = updateHandler(updateResource, {
            status: newStatus,
          })
          loading = isLoading
          const { status, data } = await update()
          console.log('update data', status)
          if (status === 200 || status === 201) {
            success('Transaction confirmed')
            context.emit('changeBalance')
          } else {
            error(data.message)
          }
        })
        .catch(() => {
          info('Cancel action!')
        })
    }

    return {
      total,
      loading,
      documents,
      updateStatus,
      params,
      toggle,
      forceUpdate,
      view,
      formatTime,
      formatterDollars,
      loadDeposits,
    }
  },
})
