
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import { defineComponent, reactive, ref, computed } from 'vue'
import { formatTime } from '@/utils/dateTime'
import { ArrowDown } from '@element-plus/icons'
import {
  getPublishStatusName,
  getPublishStatusTagColor,
  PUBLISH_STATUS_ARR,
  CATALOG_PUBLISH_STATUS,
} from '@/utils/catalog'

export default defineComponent({
  components: {
    ArrowDown,
  },
  props: {},
  setup(props, context) {
    const dialogVisible = ref(false)
    const isLoading = ref(true)
    const catalog = reactive<any>({
      _id: '',
      variants: [],
      publishStatus: -1,
    })

    const isChangedVariantPrice = ref(false)
    const handleChange = () => {
      isChangedVariantPrice.value = true
    }

    const getData = async (_id: string) => {
      isLoading.value = true
      const { getCatalog } = userCatalogRepositories()
      const { data } = await getCatalog(_id)
      Object.assign(catalog, data)
      isLoading.value = false
    }

    const onSubmit = (status: string) => {
      context.emit(
        'callbackExpectedPrice',
        catalog,
        status,
        isChangedVariantPrice.value
      )
      close()
    }

    const columns = [
      // {
      //   title: 'Created',
      //   dataIndex: 'createdAt',
      //   minWidth: 8,
      // },
      {
        title: 'SKU',
        dataIndex: 'catalogVariantCode',
        minWidth: 10,
      },
      {
        title: 'Color',
        dataIndex: 'color',
        minWidth: 8,
      },
      {
        title: 'Size',
        dataIndex: 'size',
        minWidth: 12,
      },
      {
        title: 'Stock',
        dataIndex: 'inStock',
        minWidth: 8,
        sortable: true,
      },
      {
        title: 'Min price ($)',
        dataIndex: 'supplierMinPrice',
        minWidth: 8,
        sortable: true,
      },
      {
        title: 'Max price ($)',
        dataIndex: 'supplierMaxPrice',
        minWidth: 8,
        sortable: true,
      },
      {
        title: 'Quantity',
        dataIndex: 'supplierQuantity',
        minWidth: 8,
        sortable: true,
      },
    ]

    const state = reactive({
      multipleSelection: [],
      showModalSetMultiple: false,
      dataMultiple: {
        inStock: true,
        supplierMinPrice: 0,
        supplierMaxPrice: 0,
        supplierQuantity: 0,
      },
    })
    const selectedIds = computed(() => {
      return state.multipleSelection.map((variant: any) => variant._id)
    })

    const handleSelectionChange = (val: any) => {
      state.multipleSelection = val
    }

    const onShowSetMultiple = () => {
      // visible dialog
      state.showModalSetMultiple = true
    }

    const onCloseModalSetMultiple = () => {
      // disable dialog
      state.showModalSetMultiple = false
    }

    const onSaveSetMultiple = () => {
      catalog.variants.forEach(
        (element: {
          _id: string
          supplierMinPrice: number
          supplierMaxPrice: number
          supplierQuantity: number
          inStock: boolean
        }) => {
          const idVariant = element._id
          if (selectedIds.value?.indexOf(idVariant) > -1) {
            element.inStock = state.dataMultiple.inStock
            element.supplierMinPrice = state.dataMultiple.supplierMinPrice
            element.supplierMaxPrice = state.dataMultiple.supplierMaxPrice
            element.supplierQuantity = state.dataMultiple.supplierQuantity
          }
        }
      )
      onCloseModalSetMultiple()
    }

    const toggle = async (_id: string) => {
      dialogVisible.value = !dialogVisible.value
      isChangedVariantPrice.value = false
      catalog.note = ''
      await getData(_id)
    }

    const close = () => {
      state.multipleSelection = []
      // clear old data when open dialog
      state.dataMultiple.inStock = true
      state.dataMultiple.supplierMinPrice = 0
      state.dataMultiple.supplierMaxPrice = 0
      state.dataMultiple.supplierQuantity = 0

      // close dialog Expected Price
      dialogVisible.value = false
    }

    return {
      dialogVisible,
      onCloseModalSetMultiple,
      onSaveSetMultiple,
      onShowSetMultiple,
      state,
      selectedIds,
      onSubmit,
      toggle,
      close,
      columns,
      getData,
      isLoading,
      catalog,
      formatTime,
      handleSelectionChange,
      CATALOG_PUBLISH_STATUS,
      getPublishStatusName,
      getPublishStatusTagColor,
      PUBLISH_STATUS_ARR,
      isChangedVariantPrice,
      handleChange,
    }
  },
})
