import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 5 }
const _hoisted_6 = { key: 6 }
const _hoisted_7 = { key: 7 }
const _hoisted_8 = { key: 8 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_table = _resolveComponent("filter-table")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_filter_table, {
          class: "mr-1",
          onOnFilterCallback: _ctx.onFilterCallback,
          placeholder: "Bill Name"
        }, null, 8, ["onOnFilterCallback"]),
        _createVNode(_component_el_select, {
          placeholder: "Status",
          modelValue: _ctx.params.status,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.status) = $event)),
          clearable: "",
          filterable: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.status, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name,
                value: item.id,
                clearable: ""
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(_component_el_table, {
      data: _ctx.documents,
      stripe: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
          return (_openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            label: column.title,
            props: column.dataIndex,
            "min-width": column.minWidth,
            align: column.align
          }, {
            default: _withCtx((item) => [
              (column.dataIndex == 'code' && item.row?.sellerTeam)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.row.sellerTeam.code), 1))
                : _createCommentVNode("", true),
              (column.dataIndex == 'payoutMethod' && item.row?.payoutMethod)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.methodName(item.row.payoutMethod.paymentType)), 1))
                : _createCommentVNode("", true),
              (column.dataIndex == '_account' && item.row?.payoutMethod)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _createElementVNode("span", null, _toDisplayString(item.row.payoutMethod.paymentValue), 1)
                  ]))
                : _createCommentVNode("", true),
              (column.dataIndex == 'createdDate' && item.row?.createdDate)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formatTime(item.row.createdDate)), 1))
                : _createCommentVNode("", true),
              (column.dataIndex == 'status')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 4,
                    class: _normalizeClass({
              'text-green-600': item.row.status == 1,
              'text-red-600': item.row.status == 2,
            })
                  }, _toDisplayString(_ctx.statusName(item.row.status)), 3))
                : _createCommentVNode("", true),
              (column.dataIndex == 'billName')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.row.billName), 1))
                : _createCommentVNode("", true),
              (column.dataIndex == 'amount')
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, " $" + _toDisplayString(item.row.amount), 1))
                : _createCommentVNode("", true),
              (column.dataIndex == 'approvedDate')
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.formatTime(item.row.approvedDate)), 1))
                : _createCommentVNode("", true),
              (column.dataIndex == 'note')
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.row.note), 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["label", "props", "min-width", "align"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["data"]), [
      [_directive_loading, _ctx.isLoading]
    ]),
    _createVNode(_component_pagination, {
      total: _ctx.total,
      pagination: _ctx.params.page,
      "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.page) = $event)),
      limit: _ctx.params.limit,
      "onUpdate:limit": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.limit) = $event))
    }, null, 8, ["total", "pagination", "limit"])
  ], 64))
}