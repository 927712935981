
import { defineComponent } from 'vue'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { useNotification } from '@/composables'
import axios from '@/services/axios'
import { formatDateTime } from '@/utils/dateTime'
import { formatterDollars } from '@/utils/formatter'

export default defineComponent({
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: () => 'payment',
    },
  },
  setup() {

    const downloadFile = (fileName: string, urlData: any) => {
      var aLink = document.createElement('a')
      aLink.download = fileName
      aLink.href = urlData
      var event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    }
    const onExport = async (row: any) => {
      try {
        let res = await axios.get(`invoices/${row._id}/export`)
        downloadFile('export.csv', res.data.url)
      } catch (err) {
        console.log(err)
      }
    }

    const { error, success } = useNotification()
    const { exportOrder } = useOrderRepositories()

    const onExportInvoice = async (row: any) => {
      let res = null
      try {
        if (row.type === 'OUT') {
          res = (await exportOrder({
            invoice: row._id,
          })) as any
        } else {
          res = (await exportOrder({
            rejectedInvoiceId: row._id,
          })) as any
        }
        downloadFile(`Export-invoice.csv`, res?.data?.url)
        success(res?.data?.message || `Export success`)
      } catch (e) {
        error()
      }
    }

    return {
      onExport,
      formatDateTime,
      formatterDollars,
      onExportInvoice
    }
  },
})
