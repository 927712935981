import { useNotification } from '@/composables'
import { Attribute } from './variants'
export const stringLimit = (str: string, limit: number) => {
  if (!str) {
    return ''
  }
  if (str.length <= limit) {
    return str
  }
  return `${str.substring(0, limit)}...`
}

export const copyToClipboard = (text: string) => {
  const elem = document.createElement('textarea')
  elem.value = text
  document.body.appendChild(elem)
  elem.select()
  document.execCommand('copy')
  document.body.removeChild(elem)
}

const { success } = useNotification()
export const copyToClipboardV2 = (text: string) => {
  const elem = document.createElement('textarea')
  elem.value = text
  document.body.appendChild(elem)
  elem.select()
  document.execCommand('copy')
  document.body.removeChild(elem)
  success('Copy to clipboard')
}

export const capitalizeFirstLetter = (letter: string) => {
  return letter.charAt(0).toUpperCase() + letter.slice(1)
}

export const generateVariantCode = (
  productCode: string,
  color: Attribute,
  size: Attribute
) => {
  const colorCode = color.code || color.name || ''
  const sizeCode = size.code || size.name || ''

  return `${productCode}-${colorCode}-${sizeCode}`
}

export const replaceAllNonString = (input: string) => {
  return (input ?? '').trim().replace(/[^a-zA-Z0-9-]/g, '')
}
