<template>
  <el-row
    v-loading="isLoading"
    class="h-full w-full flex justify-center bg-fixed-bottom"
    :style="
      'background-image: url(' +
      require('@/assets/img/illustrations/sticky.svg') +
      ');'
    "
  >
    <el-col :span="6">
      <el-row>
        <h3 class="font-bold mb-10">Welcome to LattePrint!</h3>
        <h5>
          Having a
          <b>LattePrint</b>
          account, you will have better service.
        </h5>
        <span style="margin-top: 30px">
          <p>
            Great. You had a LattePrint account,
            <b>{{ userInfo?.email ? userInfo.email : '<your_account_email>' }}</b>
          </p>
          <p v-if="userInfo?.email">
            And you can
            <router-link :to="{ path: `/` }" v-slot="{ href }"   @click="authorizeNow">
              <el-link :href="href" :underline="false">
                <el-button type="text">authorize now.</el-button>
              </el-link>
            </router-link>
          </p>
        </span>
        <el-divider></el-divider>
      </el-row>
      <el-row>
        <el-col :span="24">
          <p style="text-align: center">
            <router-link :to="{ path: `/auth/login` }" v-slot="{ href }">
              <el-link :href="href" :underline="false">
                <el-button
                  style="
                    font-weight: bold;
                    color: white;
                    background-color: #2196f3;
                    border: none;
                  "
                >
                  Another account
                </el-button>
              </el-link>
            </router-link>
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="mt-5">
          <p class="text-right">
            No account?
            <router-link :to="{ path: `/auth/register` }" v-slot="{ href }">
              <el-link :href="href" :underline="false">
                <el-button type="text">Register here.</el-button>
              </el-link>
            </router-link>
          </p>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import { ref } from 'vue'
import { useNotification } from '@/composables'
import { useServiceApp } from '@/services'

export default {
  setup() {
    const { error } = useNotification()
    //const router = useRouter()
    let userInfo = undefined

    const isLoading = ref(true)

    const { intergrateApp } = useServiceApp()

    const loadUserInfo = () => {
      try {
        userInfo = JSON.parse(localStorage?.getItem('info') || '')
      } catch (e) {
        // error('Can not find your account!')
      }

      isLoading.value = false
    }
    loadUserInfo()

    const authorizeNow = async () => {
      try {
        let app_token = localStorage.getItem('app-token')
        let access_token  = localStorage.getItem('access-token')
        if (app_token && access_token) {
          console.log('SignInWelcome.vue -> App Token')
          const appIntegrate = await intergrateApp({
            access_token: localStorage.getItem(`access-token`),
          })

          localStorage.removeItem("app-token")
        }        
      }
      catch (e){
        error('can not authorize')
      }
    }

    return { userInfo, isLoading, loadUserInfo, authorizeNow }
  },
}
</script>
<style scoped>
.bg-fixed-bottom {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
  background-attachment: fixed;
  padding-top: 10%;
}
</style>
