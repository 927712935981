import { useResource } from '@/services'
import { AxiosResponse } from 'axios'
import { ref } from 'vue'

export const updateHandler = <T, P>(resource: string, params: P) => {
  const isLoading = ref(false)
  const { patch } = useResource<T>(resource)

  const update = async (): Promise<AxiosResponse<any, any>> => {
    try {
      isLoading.value = true

      const result = await patch(params)
      isLoading.value = false
      return result;
    } catch (e) {
      isLoading.value = false
      return e;
    }
  }

  return { isLoading, update }
}
