export const emailRule = [
  {
    required: true,
    message: 'Please input email address',
    trigger: 'blur',
  },
  {
    type: 'email',
    message: 'Please input correct email address',
    trigger: ['blur', 'change'],
  },
]

export const stringRules = (fieldName: string, min: number, max: number) => {
  return [
    {
      required: true,
      message: `Please input ${fieldName}`,
      trigger: 'blur',
    },
    {
      min: min,
      max: max,
      message: `${fieldName} should be ${min} to ${max} length`,
      trigger: 'blur',
    },
  ]
}

export const required = (fieldName: string) => {
  return [
    {
      required: true,
      message: `Please input ${fieldName}`,
      trigger: 'blur',
    },
  ]
}
