/* eslint-disable */

// @ts-ignore
import color from 'css-color-function'
const tintColor: any = {
  'light-1': 'color(primary tint(10%))',
  'light-2': 'color(primary tint(20%))',
  'light-3': 'color(primary tint(30%))',
  'light-4': 'color(primary tint(40%))',
  'light-5': 'color(primary tint(50%))',
  'light-6': 'color(primary tint(60%))',
  'light-7': 'color(primary tint(70%))',
  'light-8': 'color(primary tint(80%))',
  'light-9': 'color(primary tint(90%))',
}

export const generateColors = (primary: string): Record<string, string> => {
  const colors: any = {}
  Object.keys(tintColor).forEach(key => {
    const value = tintColor[key].replace(/primary/g, primary)
    colors[key] = color.convert(value)
  })
  return colors
}
