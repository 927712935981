import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "danger" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "success" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "base-cost" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_table, { data: _ctx.documents }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        property: "createdDate",
        label: "Created"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", null, _toDisplayString(_ctx.formatDateTime(scope.row.createdDate)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        property: "name",
        label: "Invoice"
      }, {
        default: _withCtx((scope) => [
          _createVNode(_component_router_link, {
            to: { path: `/ag/invoices/${scope.row._id}` }
          }, {
            default: _withCtx(({ href }) => [
              _createVNode(_component_el_link, {
                href: href,
                type: "primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(scope.row.name), 1)
                ]),
                _: 2
              }, 1032, ["href"])
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        property: "name",
        label: "Seller"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", null, _toDisplayString(scope.row.sellerTeam.name), 1)
        ]),
        _: 1
      }),
      (_ctx.tab == 'payment')
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            property: "sellerAmount",
            label: "Debit"
          }, {
            default: _withCtx((scope) => [
              (scope.row.type == 'OUT')
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.formatterDollars(scope.row.sellerAmount)), 1)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, "-"))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.tab == 'payment')
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 1,
            property: "sellerAmount",
            label: "Credit"
          }, {
            default: _withCtx((scope) => [
              (scope.row.type == 'IN')
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.formatterDollars(scope.row.sellerAmount)), 1)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, "-"))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.tab == 'debts')
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 2,
            property: "total",
            label: _ctx.tab == 'payment' ? 'Seller Amount' : 'Agency Amount'
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.tab == 'debts'
              ? _ctx.formatterDollars(scope.row.agencyAmount)
              : _ctx.formatterDollars(scope.row.sellerAmount)), 1)
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      (_ctx.tab == 'payment')
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 3,
            property: "remainAmount",
            label: "Blance"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.formatterDollars(scope.row.remainAmount)), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_table_column, {
        property: "status",
        label: "Export"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("a", {
            class: "text-primary",
            href: "#",
            onClick: ($event: any) => (_ctx.onExport(scope.row))
          }, " Download ", 8, _hoisted_8)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"])), [
    [_directive_loading, _ctx.isLoading]
  ])
}