import { ElMessage } from 'element-plus'
import { ElNotification } from 'element-plus'

export const useNotification = () => {
  const error = (message = 'Error') => {
    ElMessage.error(message)
  }

  const success = (message = 'Success') => {
    ElMessage.success(message)
  }

  const warning = (message = 'Warning') => {
    ElMessage({
      message,
      type: 'warning',
      showClose: true,
      dangerouslyUseHTMLString: true,
    })
  }

  const info = (message = 'Info') => {
    ElMessage.info(message)
  }

  const errorNoti = (message = 'Error') => {
    ElNotification({
      title: 'Error',
      message: message,
      type: 'error',
      offset: 100,
    })
  }

  const successNoti = (message = 'Success') => {
    ElNotification({
      title: 'Success',
      message: message,
      type: 'success',
      offset: 100,
    })
  }
  const warningNoti = (message = 'Warning') => {
    ElNotification({
      title: 'Warning',
      message: message,
      type: 'warning',
      offset: 100,
    })
  }

  const infoNoti = (message = 'Info') => {
    ElNotification({
      title: 'Info',
      message: message,
      type: 'info',
      offset: 100,
    })
  }
  return {
    error,
    success,
    warning,
    info,
    errorNoti,
    successNoti,
    warningNoti,
    infoNoti,
  }
}
