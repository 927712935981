
import { defineComponent, reactive, ref, watch } from 'vue'
import { formatTime } from '@/utils/dateTime'
import {
  getPublishStatusName,
  getPublishStatusTagColor,
  PUBLISH_STATUS_ARR,
  CATALOG_PUBLISH_STATUS,
} from '@/utils/catalog'
import { formatterDollars } from '@/utils/formatter'

export default defineComponent({
  components: {},
  props: {},
  setup(props, context) {
    const dialogVisible = ref(false)
    const isLoading = ref(true)
    const supplierLog = ref<any>()

    const toggle = async (log: any[]) => {
      dialogVisible.value = !dialogVisible.value
      supplierLog.value = log
    }

    const close = () => {
      dialogVisible.value = false
    }

    const columns = [
      // {
      //   title: 'Created',
      //   dataIndex: 'createdAt',
      //   minWidth: 8,
      // },
      {
        title: 'SKU',
        dataIndex: 'catalogVariantCode',
        minWidth: 10,
      },
      {
        title: 'Color',
        dataIndex: 'color',
        minWidth: 8,
      },
      {
        title: 'Size',
        dataIndex: 'size',
        minWidth: 12,
      },
      {
        title: 'Stock',
        dataIndex: 'inStock',
        minWidth: 8,
        sortable: true,
      },
      {
        title: 'Min price ($)',
        dataIndex: 'supplierMinPrice',
        minWidth: 8,
        sortable: true,
      },
      {
        title: 'Max price ($)',
        dataIndex: 'supplierMaxPrice',
        minWidth: 8,
        sortable: true,
      },
      {
        title: 'Quantity',
        dataIndex: 'supplierQuantity',
        minWidth: 8,
        sortable: true,
      },
    ]

    return {
      dialogVisible,
      toggle,
      close,
      isLoading,
      formatTime,
      getPublishStatusName,
      getPublishStatusTagColor,
      PUBLISH_STATUS_ARR,
      CATALOG_PUBLISH_STATUS,
      columns,
      formatterDollars,
      supplierLog,
    }
  },
})
