
import { defineComponent, computed, reactive } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import axios from '@/services/axios'
import { useNotification } from '@/composables'

export default defineComponent({
  components: {
    sectionLayoutContent,
  },
  name: 'Landing Page Setting',
  setup() {
    const { error, success } = useNotification()
    const breadcrumbs = [
      {
        text: 'Settings',
        link: 'ag.settings.board',
        params: null,
      },
      {
        text: 'Landing Page',
        link: '',
        params: null,
      },
    ]

    const content = computed(() => {
      return { breadcrumbs }
    })

    const state = reactive({
      agency: {
        settings: {},
      },
      editorDomain: '',
    })

    const getAgencyInfo = async () => {
      const url = `/agencies/info`
      const res = await axios.get(url)
      if (res.status === 200 || res.status === 201) {
        state.agency = res.data as any
        state.editorDomain = res.data.settings?.editorDomain
      }
    }
    getAgencyInfo()

    const updateSettingResource = '/agencies/update-settings'

    const onUpdateSettings = async () => {
      console.log(state.agency)
      const formData = {
        settings: {
          ...state.agency.settings,
          editorDomain: state.editorDomain,
        },
      }
      try {
        const res = await axios.patch(updateSettingResource, formData)
        if (res.status === 200 || res.status === 201) {
          success('Update success!')
        } else {
          error('Error!')
        }
      } catch (error) {
        console.log(error)
      }
    }

    const onClickLandingPage = () => {
      const url = `${state.editorDomain}?token=${localStorage.getItem('access-token')}`
      window.open(url, '_blank')?.focus()
    }

    return {
      content,
      onClickLandingPage,
      getAgencyInfo,
      onUpdateSettings,
      state,
    }
  },
})
