import { ActionTree } from "vuex";
import { AuthState } from "./state";
import { RootState } from '@/store/types'
import { useServiceAuth } from "@/services";
import { useNotification } from "@/composables";

export const actions: ActionTree<AuthState, RootState> = {
  async login({ commit }, { email, password }) {
    const { login } = useServiceAuth()
    const { error, success } = useNotification()

    const { data, status } = await login({ email, password })
    if (status === 200 || status === 201) {
      commit('setLogedIn', data)
      success('Login Success!')
    } else {
      error(data.message)
    }
  }
};