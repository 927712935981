
import { CloseBold } from '@element-plus/icons'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { CloseBold },
  props: {
    label: {
      type: String,
      default: 'Close',
    },
    size: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  setup() {
    return {}
  },
})
