
import { defineComponent, reactive, ref } from 'vue'
import { createHandler } from '@/services/createHandler'
import { emailRule, required, stringRules } from '@/utils/formRules'
import { useFetchData, useNotification } from '@/composables'

export default defineComponent({
  setup(props: any, context: any) {
    let dialogVisible = ref(false)
    let loading = ref(false)
    const formSellerTeam = ref()
    let basecosts = ref([])

    const { error, success } = useNotification()

    const state = reactive({
      form: {
        name: '',
        basecost: '',
        code: '',
        active: 1,
        manager: '',
        autoApproveOrder: false,
        supporter: '',
        rate: 0,
      },
    })
    const formRules = {
      name: stringRules('name', 2, 255),
      code: stringRules('code', 2, 255),
      manager: emailRule,
      basecost: required('basecost'),
    }

    const onSubmit = async () => {
      const resource = `seller-teams`
      formSellerTeam.value.validate(async (valid: boolean) => {
        if (valid) {
          const { create, isLoading } = createHandler(resource, state.form)
          loading = isLoading
          const { status, data } = await create()
          if (status === 201) {
            dialogVisible.value = !dialogVisible.value
            context.emit('change')
            formSellerTeam.value?.resetFields()
            success('Created')
          } else {
            error(data.message)
          }
        }
      })
    }

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }
    const params = reactive({})
    const getBasecosts = () => {
      const { documents, fetchData, isLoading, total } = useFetchData(
        'basecosts',
        params
      )
      loading = isLoading
      basecosts = documents as any
      fetchData()
    }

    getBasecosts()

    const supportParams = reactive({limit:1000, page:1})
    let supporters = ref([])
    const getSupporters = () => {
      const { documents, fetchData, isLoading, total } = useFetchData(
        `agencies/staffs`,
        supportParams
      )
      loading = isLoading
      supporters = documents as any
      fetchData()
    }

    getSupporters()

    const handleClose = () => {
      formSellerTeam.value?.resetFields()
      dialogVisible.value = false
    }

    return {
      state,
      onSubmit,
      toggle,
      dialogVisible,
      loading,
      formSellerTeam,
      formRules,
      basecosts,
      supporters,
      getBasecosts,
      handleClose,
    }
  },
})
