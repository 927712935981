
import { imageUrl } from '@/utils/image'
import { copyToClipboardV2 } from '@/utils/string'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    urlError: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const url = ref(
      `'https://resize.bambooz.io/img/300/resize/2023-10-16/1697430354546_logo-official.png'`
    )

    const detectErrorUrl = () => {
      if (props.urlError?.includes('drive.google')) {
        url.value =
          'https://static.latteprint.com/2023-06-20/1687246868385_google-drive-icon.png'
      }
    }
    detectErrorUrl()

    const onClickErrorImage = () => {
      copyToClipboardV2(url.value)
    }

    return {
      imageUrl,
      url,
      onClickErrorImage,
      copyToClipboardV2,
      detectErrorUrl,
    }
  },
})
