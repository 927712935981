import { useResource } from '@/services'
import { AxiosResponse } from 'axios'
import { ref } from 'vue'

export const deleteHandler = <T>(resource: string) => {
  const isLoading = ref(false)
  const { deleteOne } = useResource<T>(resource)

  const deleteById = async (id: string): Promise<AxiosResponse<any, any>> => {
    try {
      isLoading.value = true
      const result = await deleteOne(id)
      isLoading.value = false
      return result;
    } catch (e) {
      isLoading.value = false
      console.log({ e })
      return e;
    }
  }

  return { isLoading, deleteById }
}
