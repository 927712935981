import { ElMessage } from 'element-plus'

export const downloadFileWithFileName = (fileName: string, urlData: any) => {
  const aLink = document.createElement('a')
  aLink.download = fileName
  aLink.href = urlData
  const event = new MouseEvent('click')
  aLink.dispatchEvent(event)
}

export const downloadFile = (urlData: any) => {
  const aLink = document.createElement('a')
  aLink.href = urlData
  const event = new MouseEvent('click')
  aLink.dispatchEvent(event)
}

export const MAX_FILE_SIZE = 1024 * 1024 * 70 // max 70MB

export const validateImageFileUploaded = (file: any) => {
  if (!file) {
    return false
  }
  if (file.size > MAX_FILE_SIZE) {
    ElMessage.error('File size can not exceed 70MB!')
    return false
  }
  if (!isFileImage(file?.raw?.type)) {
    ElMessage.error('File must be image format!')
    return false
  }

  return true
}

// Kiểm tra có phải file
export const isFileImage = (type: string) => {
  return type && type.split('/')[0] === 'image'
}

export const getFileNameFromUrl = (url: string) => {
  if (url) {
    const filename = url.substring(url.lastIndexOf('/') + 1) as string
    return filename
  }
}

export const getBaseFileName = (fileName: string) => {
  if (fileName) {
    return fileName.split('.').slice(0, -1).join('.')
  }
}
