
import { computed, defineComponent, ref, reactive, watch, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import Catalog from './Catalog.vue'
// import DepositHistory from './DepositHistory.vue'
import Update from './Update.vue'
// import Bills from './Bills.vue'
import { Edit } from '@element-plus/icons'
import { useResource } from '@/services'
import { useRoute } from 'vue-router'
import { useFetchData } from '@/composables'
import { formatDateTime } from '@/utils/dateTime'
import Pagination from '@/components/common/Pagination.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    // DepositHistory,
    // Bills,
    Edit,
    Update,
    Catalog,
    // Pagination,
  },
  setup() {
    const breadcrumbs = ['Agencies', 'detail']
    const title = 'Agencies'
    let loading = ref(false)
    const baseResource = 'agencies'
    const updateDialog = ref()
    const catalogComponent = ref()
    const router = useRoute()
    let agency = ref({})
    const state = reactive({
      totalMembers: 0,
      members: ref([]),
    })

    const fectchRecord = async () => {
      loading.value = true
      const { get } = useResource(baseResource)
      const { data, status } = await get(router.params.id)
      if (status === 200) {
        agency.value = data as any
      }
      loading.value = false
    }
    const content = computed(() => {
      return { breadcrumbs, title }
    })

    fectchRecord()

    onMounted(() => {
      getMembers()
    })

    const paramMembers = reactive<any>({
      limit: 10000,
      page: 1,
    })
    const getMembers = async () => {
      const resource = `${baseResource}/${router.params.id}/members`
      const { documents, fetchData, isLoading, total } = useFetchData(
        resource,
        paramMembers
      )
      await fetchData()
      state.members = documents as any
      state.totalMembers = total as any
      loading = isLoading
    }
    // watch(paramMembers, getMembers)

    const openUpdateDialog = () => {
      updateDialog.value?.toggle(router.params.id)
    }

    const onUpdateFinish = () => {
      fectchRecord()
    }

    // const depositHistory = ref<InstanceType<typeof DepositHistory>>()
    // const getDepositHistoryLength = () => {
    //   return depositHistory.value?.total
    // }

    return {
      loading,
      content,
      updateDialog,
      agency,
      openUpdateDialog,
      onUpdateFinish,
      // depositHistory,
      // getDepositHistoryLength,
      formatDateTime,
      catalogComponent,
      paramMembers,
      state,
    }
  },
})
