import { ref, computed, reactive, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useFetchData } from '@/composables'
import { useResource } from '@/services'
import axios from '@/services/axios'
import { AxiosResponse } from 'axios'

export default function userMailNotificationsRepositories() {
  const resource = `mail-notifications`
  const { get } = useResource(resource)

  const state = reactive<{
    totalDefault: any
    totalCurrent: any
    defaultTemplate: any[]
    currentTemplate: any[]
  }>({
    totalDefault: 0,
    totalCurrent: 0,
    defaultTemplate: [],
    currentTemplate: [],
  })

  const findOne = async (id: any) => {
    const { get } = useResource(resource)
    return await get(id)
  }

  const updateEmailTemplate = async (template: any) => {
    const { patch } = useResource(`${resource}/${template._id}`)
    try {
      const res = await patch(template)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const getDefaultTemplate = async () => {
    try {
      const url = `${resource}/default-templates`
      const { documents, fetchData, total } = useFetchData(url, {})
      await fetchData()
      state.totalDefault = total
      state.defaultTemplate = documents.value as any[]
      return state.defaultTemplate
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getCurrentTemplate = async () => {
    try {
      const { documents, fetchData, total } = useFetchData(resource, {})
      await fetchData()
      state.totalCurrent = total
      state.currentTemplate = documents.value as any[]
      return state.defaultTemplate
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const addTemplate = async (template: any) => {
    const { add } = useResource(resource)
    try {
      const res = await add(template)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const getDataEmailTemplate = async () => {
    getCurrentTemplate()
    getDefaultTemplate()
  }

  return {
    getDefaultTemplate,
    getCurrentTemplate,
    getDataEmailTemplate,
    addTemplate,
    findOne,
    updateEmailTemplate,
    state,
  }
}
