
import { defineComponent, computed, reactive, onMounted, ref } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import axios from '@/services/axios'
import { Delete } from '@element-plus/icons'
import { useNotification } from '@/composables'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Delete,
  },
  name: 'Shipping Setting',
  setup() {
    const { error, success } = useNotification()
    const dialogVisible = ref(false)
    const updateSettingResource = '/agencies/update-settings'

    const breadcrumbs = [
      {
        text: 'Settings',
        link: 'ag.settings.board',
        params: null,
      },
      {
        text: 'Shipping',
        link: '',
        params: null,
      },
    ]
    const content = computed(() => {
      return { breadcrumbs }
    })

    onMounted(() => {
      getAgencyInfo()
    })

    const state = reactive({
      shippingExcludeCode: [{ countryCode: '', provinceCode: [] }],
      agency: {
        settings: {},
      },
      agencyShippingStatus: false,
    })

    const onChangeAgencyShippingStatus = async () => {
      const formData = {
        settings: {
          ...state.agency.settings,
          agencyShippingStatus: state.agencyShippingStatus,
        },
      }
      const res = await axios.patch(updateSettingResource, formData)
      if (res.status === 200 || res.status === 201) {
        success('Update success!')
      } else {
        error('Error')
      }
    }

    const getAgencyInfo = async () => {
      const url = `/agencies/info`
      const res = await axios.get(url)
      if (res.status === 200 || res.status === 201) {
        state.agency = res.data as any
        if (res.data.settings?.shippingExcludeCode) {
          state.shippingExcludeCode = res.data.settings.shippingExcludeCode
          state.agencyShippingStatus = res.data.settings?.agencyShippingStatus
            ? res.data.settings?.agencyShippingStatus
            : false
        }
      }
    }

    const onSaveExcludeShipping = async () => {
      // loai bo nhung ban ghi rong
      if (state.shippingExcludeCode && state.shippingExcludeCode.length > 0) {
        state.shippingExcludeCode = state.shippingExcludeCode.filter(item => {
          return item.countryCode && item.countryCode.trim() !== ''
        })
      }

      state.shippingExcludeCode.forEach(item => {
        if (typeof item.provinceCode === 'string') {
          const provinces = item.provinceCode as string
          let tmp = provinces.split(',')
          tmp.forEach(item => (item = item.trim()))
          item.provinceCode = tmp as []
        }
      })

      const formData = {
        settings: {
          ...state.agency.settings,
          shippingExcludeCode: state.shippingExcludeCode,
        },
      }
      const res = await axios.patch(updateSettingResource, formData)
      if (res.status === 200 || res.status === 201) {
        success('Update success!')
      } else {
        error('Error')
      }
    }

    const onDeleteExcludeShipping = (row: any) => {
      state.shippingExcludeCode?.forEach((element, index) => {
        if (element.countryCode === row.countryCode)
          state.shippingExcludeCode.splice(index, 1)
      })
    }

    const onAddExcludeShipping = () => {
      state.shippingExcludeCode.push({
        countryCode: '',
        provinceCode: [],
      })
    }

    return {
      content,
      state,
      onSaveExcludeShipping,
      getAgencyInfo,
      updateSettingResource,
      dialogVisible,
      onDeleteExcludeShipping,
      onAddExcludeShipping,
      onChangeAgencyShippingStatus,
    }
  },
})
