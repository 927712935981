import { Catalog } from './../interfaces/catalog'
export enum DEFAULT_CONTENT_CATALOG_DROPSHIP {
  REFUND_AND_RETURN = '<p>We always want to provide the best service for you. However, if you&rsquo;re not satisfied with your purchase, you may return or exchange your order for any reason - even if you just change your mind - within 30 days of receipt.</p><p>Please check more information about returns policies here.</p>',
  ABOUT_THIS_PRODUCT = '<p>We always want to provide the best service for you. However, if you&rsquo;re not satisfied with your purchase, you may return or exchange your order for any reason - even if you just change your mind - within 30 days of receipt.</p><p>Please check more information about returns policies here.</p>',
}

export enum IMPORT_TYPE {
  IMPORT_SUPPLIER_PRICE = 'IMPORT_SUPPLIER_PRICE',
}

export enum CATALOG_PUBLISH_STATUS {
  DRAFT = 0,
  PUBLISHED = 1,
  ASK_PRICE = 2,
  FILLED_PRICE = 3,
  CONFIRM_PRICE = 4,
  UPDATING_PRICE = 5,
  UPDATED_PRICE = 6,
}

export enum CATALOG_PUBLISH_STATUS_NAME {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
  ASK_PRICE = 'Ask price',
  FILLED_PRICE = 'Filled price',
  CONFIRM_PRICE = 'Confirm price',
  UPDATING_PRICE = 'Updating price',
  UPDATED_PRICE = 'Updated price',
}

export enum SupplierLogType {
  ChangeSupplierPrice = 'CHANGE_SUPPLIER_PRICE',
  ChangeSellerPrice = 'CHANGE_SELLER_PRICE',
}

export enum CatalogTypeEnum {
  pod = 'pod',
  fake = 'fake',
  drop = 'drop',
}

export const catalogTypes = [
  { value: 'pod', title: 'POD' },
  // { value: 'fake', title: 'FK' },
  { value: 'drop', title: 'DROP' },
]

export const PUBLISH_STATUS_ARR = [
  {
    value: CATALOG_PUBLISH_STATUS.DRAFT,
    label: CATALOG_PUBLISH_STATUS_NAME.DRAFT,
  },
  {
    value: CATALOG_PUBLISH_STATUS.PUBLISHED,
    label: CATALOG_PUBLISH_STATUS_NAME.PUBLISHED,
  },
  {
    value: CATALOG_PUBLISH_STATUS.ASK_PRICE,
    label: CATALOG_PUBLISH_STATUS_NAME.ASK_PRICE,
  },
  // {
  //   value: CATALOG_PUBLISH_STATUS.FILLED_PRICE,
  //   label: CATALOG_PUBLISH_STATUS_NAME.FILLED_PRICE,
  // },
  {
    value: CATALOG_PUBLISH_STATUS.CONFIRM_PRICE,
    label: CATALOG_PUBLISH_STATUS_NAME.CONFIRM_PRICE,
  },
  {
    value: CATALOG_PUBLISH_STATUS.UPDATING_PRICE,
    label: CATALOG_PUBLISH_STATUS_NAME.UPDATING_PRICE,
  },
  {
    value: CATALOG_PUBLISH_STATUS.UPDATED_PRICE,
    label: CATALOG_PUBLISH_STATUS_NAME.UPDATED_PRICE,
  },
]

export const getPublishStatusName = (publishStatus: number) => {
  let statusName = `${publishStatus}`
  switch (publishStatus) {
    case CATALOG_PUBLISH_STATUS.DRAFT: {
      statusName = CATALOG_PUBLISH_STATUS_NAME.DRAFT
      break
    }
    case CATALOG_PUBLISH_STATUS.PUBLISHED: {
      statusName = CATALOG_PUBLISH_STATUS_NAME.PUBLISHED
      break
    }
    case CATALOG_PUBLISH_STATUS.ASK_PRICE: {
      statusName = CATALOG_PUBLISH_STATUS_NAME.ASK_PRICE
      break
    }
    case CATALOG_PUBLISH_STATUS.FILLED_PRICE: {
      statusName = CATALOG_PUBLISH_STATUS_NAME.FILLED_PRICE
      break
    }
    case CATALOG_PUBLISH_STATUS.CONFIRM_PRICE: {
      statusName = CATALOG_PUBLISH_STATUS_NAME.CONFIRM_PRICE
      break
    }
    case CATALOG_PUBLISH_STATUS.UPDATING_PRICE: {
      statusName = CATALOG_PUBLISH_STATUS_NAME.UPDATING_PRICE
      break
    }
    case CATALOG_PUBLISH_STATUS.UPDATED_PRICE: {
      statusName = CATALOG_PUBLISH_STATUS_NAME.UPDATED_PRICE
      break
    }
    default: {
      break
    }
  }
  return statusName
}

export const getPublishStatusTagColor = (publishStatus: number) => {
  let color = 'info'
  switch (publishStatus) {
    case CATALOG_PUBLISH_STATUS.DRAFT:
    case CATALOG_PUBLISH_STATUS.UPDATING_PRICE: {
      color = 'warning'
      break
    }
    case CATALOG_PUBLISH_STATUS.ASK_PRICE: {
      color = 'danger'
      break
    }
    case CATALOG_PUBLISH_STATUS.FILLED_PRICE:
    case CATALOG_PUBLISH_STATUS.CONFIRM_PRICE: {
      color = 'info'
      break
    }
    case CATALOG_PUBLISH_STATUS.PUBLISHED:
    case CATALOG_PUBLISH_STATUS.UPDATED_PRICE: {
      color = 'success'
      break
    }
    default: {
      break
    }
  }
  return color
}

// Define the custom sort order for sizes
export const sizeOrder: { [size: string]: number } = {
  XS: 0,
  S: 1,
  M: 2,
  L: 3,
  XL: 4,
  '2XL': 5,
  '3XL': 6,
  '4XL': 7,
  '5XL': 8,
  '6XL': 9,
  '7XL': 10,
  '8XL': 11,
}

export const sortVariant = (vairants: any[]) => {
  return vairants.sort((a: any, b: any) =>
    a?.color?.name?.localeCompare(b?.color?.name)
  )
}

// export const sortVariant = () => {
//   variants.value = variants.value.sort(
//     (a: any, b: any) => sizeOrder[a.size.name] - sizeOrder[b.size.name]
//   )
// }

export type SaveCatalogFromUrlDto = {
  productStoreUrl: string
  handle?: string
  productId?: string
  productCode?: string
  appApiKey: string
  appPassword: string
  appShopUrl: string
  type?: string
}
