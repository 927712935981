<template>
  <el-col :sm="20" :lg="20">
    <el-result
      icon="info"
      title="Forbidden resource"
      sub-title="No permission"
    ></el-result>
  </el-col>
</template>

<script>
export default {
  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
