
import { defineComponent, reactive, ref, watch } from 'vue'
import { updateHandler } from '@/services/updateHandler'
import { useResource } from '@/services/useResource'
import { emailRule, stringRules } from '@/utils/formRules'
import { UserTypeEnum } from '@/utils/constants'
import { SellerRoles } from '@/utils/userUtils'
import { useNotification } from '@/composables'
import useProxyRepositories from '@/repositories/useProxyRepositories'

export default defineComponent({
  setup(props: any, context: any) {
    let userId = ref('')
    let dialogVisible = ref(false)
    let loading = ref(false)
    const userUpdateForm = ref()
    const resource = 'users'

    const formData = reactive({
      user: {
        fullName: '',
        email: '',
        authority: '',
        proxies: [],
      },
    })
    const formRules = {
      fullName: stringRules('username', 2, 128),
      email: emailRule,
    }

    const toggle = (id: string) => {
      dialogVisible.value = !dialogVisible.value
      userId.value = id
    }

    const { getProxies } = useProxyRepositories()

    const listProxy = ref<any[]>([])
    const fetchRecord = async (id: string) => {
      loading.value = true
      const { get } = useResource(resource)
      const { data, status } = await get(id)
      if (status === 200) {
        formData.user = data as any
      }
      let res = await getProxies()
      listProxy.value = res as any[]
      loading.value = false
    }

    const { error, success } = useNotification()

    const onSubmit = async () => {
      const resource = `users/${userId.value}`
      let patchData = {
        fullName: formData.user.fullName,
        authority: formData.user.authority,
        proxies: formData.user.proxies,
      }
      userUpdateForm.value.validate(async (valid: any) => {
        if (valid) {
          const { update, isLoading } = updateHandler(resource, patchData)
          loading = isLoading
          const { status } = await update()
          if (status === 200) {
            success('Updated')
            dialogVisible.value = false
            context.emit('forceUpdate')
          } else {
            error('Update failed')
          }
        }
      })
    }

    const watchModalOpen = watch(dialogVisible, () => {
      if (dialogVisible.value === true) {
        fetchRecord(userId.value)
      }
    })

    const closeHandle = () => {
      console.log('close')
      userUpdateForm.value?.resetFields()
      dialogVisible.value = false
    }

    return {
      userUpdateForm,
      formRules,
      onSubmit,
      dialogVisible,
      loading,
      formData,
      toggle,
      fetchRecord,
      watchModalOpen,
      closeHandle,
      UserTypeEnum,
      SellerRoles,
      listProxy,
    }
  },
})
