import axios from '@/services/axios'
import { IResponse } from '@/interfaces/resource'

export const useResource = <T>(resource: string) => {
  const all = () => {
    return axios.get<IResponse<T>>(`${resource}/list`)
  }

  const getAll = <P>(params: P) => {
    return axios.get<IResponse<T>>(`${resource}`, { params: params })
  }

  const add = <P>(params: P) => {
    return axios.post(`${resource}`, params)
  }

  const edit = <P>(params: P) => {
    return axios.put(`${resource}`, params)
  }

  const patch = async <P>(params: P) => {
    return await axios.patch(`${resource}`, params)
  }

  const get = (id: string | string[]) => {
    return axios.get<IResponse<T>>(`${resource}/${id}`)
  }

  const fetch = (params: any) => {
    return axios.get<IResponse<T>>(resource, { params: params })
  }

  const deleteOne = async (id: string | undefined) => {
    return await axios.delete(`${resource}/${id}`)
  }

  const deleteById = async (id: string | string[]) => {
    return await axios.delete<IResponse<T>>(`${resource}/${id}`)
  }
  const deleteByIds = async (ids: string[], catalog: string) => {
    return await axios.delete<IResponse<T>>(`${resource}`, {data: {ids, catalog}})
  }
  const count = async () => {
    return axios.get<any>(`${resource}/count`)
  }

  return {
    all,
    add,
    edit,
    get,
    fetch,
    getAll,
    patch,
    deleteOne,
    deleteById,
    deleteByIds,
    count,
  }
}
