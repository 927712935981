<template>
  <div>
    <el-pagination
      small
      @current-change="changePage"
      class="mt-2 flex justify-end"
      background
      @size-change="handleSizeChange"
      :page-sizes="[10, 20, 50, 100, 200]"
      :page-size="limit"
      :current-page="pagination"
      layout="total, prev, pager, next, sizes"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 20,
    },
    total: {
      type: Number,
      default: 20,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:limit', 'update:pagination'],
  setup(_, { emit }) {
    const changePage = a => {
      emit('update:pagination', a)
    }
    const handleSizeChange = val => {
      emit('update:limit', val)
    }
    return {
      changePage,
      handleSizeChange,
    }
  },
}
</script>

<style lang="scss" scoped></style>
