
import { computed, defineComponent, ref, reactive, watch } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import DepositHistory from './DepositHistory.vue'
import Update from './Update.vue'
import DepositDialog from './DepositDialog.vue'
import Bills from './Bills.vue'
import { Edit } from '@element-plus/icons'
import { useResource } from '@/services'
import { useRoute } from 'vue-router'
import { useFetchData } from '@/composables'
import { formatDateTime } from '@/utils/dateTime'
import { formatterDollars } from '@/utils/formatter'
import Pagination from '@/components/common/Pagination.vue'
import Payouts from './Payouts.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    DepositHistory,
    Bills,
    Edit,
    Update,
    Pagination,
    DepositDialog,
    Payouts,
  },
  setup() {
    const breadcrumbs = [
      {
        text: 'Sellers',
        link: 'ag.sellers',
        params: null,
      },
      {
        text: 'detail',
        link: '',
        params: null,
      },
    ]

    let loadAmount = ref(false)
    const title = 'Sellers Deposits'
    let loading = ref(false)
    const baseResource = 'seller-teams'
    const updateDialog = ref()
    const router = useRoute()
    let agency = ref({})
    let members = ref([])

    const activeName = ref(`payment`)

    const fectchRecord = async () => {
      loading.value = true
      const { get } = useResource(baseResource)
      const { data, status } = await get(router.params.id)
      if (status === 200) {
        agency.value = data as any
      }
      loading.value = false
    }
    const content = computed(() => {
      return { breadcrumbs, title }
    })

    fectchRecord()

    const params = reactive<any>({
      activeName: activeName?.value,
      page: 1,
      limit: 10,
      search: '',
      sellerTeam: router.params.id,
    })

    const onClickTab = () => {
      params.activeName = activeName.value
      params.page = 1
    }

    const {
      documents: invoices,
      fetchData,
      total,
    } = useFetchData('invoices', params)
    let loadingInvoice = ref(false)
    const loadData = async () => {
      loadingInvoice.value = true
      await fetchData()
      loadingInvoice.value = false
    }
    watch(loadData, params)

    const state = reactive<{ balance: any }>({ balance: { amout: 0 } })

    const getBalance = async () => {
      const resource = `balances/${baseResource}/${router.params.id}`
      const { documents, fetchData, isLoading } = useFetchData(resource, {})
      fetchData()
      state.balance = documents as any
      loading = isLoading
    }

    getBalance()

    const getMembers = () => {
      const resource = `${baseResource}/${router.params.id}/members`
      const { documents, fetchData, isLoading } = useFetchData(resource, {})
      fetchData()
      members = documents as any
      loading = isLoading
    }

    getMembers()

    const openUpdateDialog = () => {
      updateDialog.value?.toggle(router.params.id)
    }

    const depositDialog = ref<InstanceType<typeof DepositDialog>>()
    const openDepositDialog = () => {
      depositDialog.value?.toggle()
    }

    const onUpdateFinish = () => {
      fectchRecord()
    }

    const changeBalance = async () => {
      await getBalance()
      refeshDipositHistory()
      onUpdateFinish()
    }

    const depositHistory = ref<InstanceType<typeof DepositHistory>>()
    const refeshDipositHistory = () => {
      depositHistory.value?.toggle()
    }
    const getDepositHistoryLength = () => {
      return depositHistory.value?.total
    }
    return {
      params,
      invoices,
      loading,
      total,
      loadingInvoice,
      content,
      updateDialog,
      agency,
      members,
      onClickTab,
      openUpdateDialog,
      onUpdateFinish,
      depositDialog,
      openDepositDialog,
      state,
      changeBalance,
      depositHistory,
      getDepositHistoryLength,
      formatDateTime,
      formatterDollars,
      refeshDipositHistory,
      loadAmount,
      activeName,
    }
  },
})
