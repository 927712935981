
import { defineComponent, reactive, ref, watch } from 'vue'
import { updateHandler } from '@/services/updateHandler'
import { useResource } from '@/services/useResource'
import { emailRule, stringRules } from '@/utils/formRules'

export default defineComponent({
  setup(props: any, context: any) {
    let userId = ref('')
    let dialogVisible = ref(false)
    let loading = ref(false)
    const userUpdateForm = ref()
    let resource = 'users'

    const formData = reactive({
      user: {
        fullName: '',
        email: '',
        status: false,
      },
    })
    const formRules = {
      fullName: stringRules('username', 2, 128),
      email: emailRule,
    }

    const toggle = (id: string) => {
      dialogVisible.value = !dialogVisible.value
      userId.value = id
    }

    const fectchRecord = async (id: string) => {
      loading.value = true
      const { get } = useResource(resource)
      const { data, status } = await get(id)
      if (status === 200) {
        formData.user = data as any
      }
      loading.value = false
    }

    const onSubmit = async () => {
      const resource = `users/${userId.value}`
      let patchData = {
        fullName: formData.user.fullName,
        code: formData.user.email,
      }
      userUpdateForm.value.validate(async (valid: any) => {
        if (valid) {
          const { update, isLoading } = updateHandler(resource, patchData)
          loading = isLoading
          const { status } = await update()
          if (status === 200) {
            dialogVisible.value = false
            context.emit('change')
          }
        }
      })
    }

    const watchModalOpen = watch(dialogVisible, () => {
      if (dialogVisible.value === true) {
        fectchRecord(userId.value)
      }
    })

    const closeHandle = () => {
      console.log('close')
      userUpdateForm.value?.resetFields()
      dialogVisible.value = false
    }

    return {
      userUpdateForm,
      formRules,
      onSubmit,
      dialogVisible,
      loading,
      formData,
      toggle,
      fectchRecord,
      watchModalOpen,
      closeHandle,
    }
  },
})
