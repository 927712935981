import axios from './axiosApp'

export const useServiceApp = () => {
  const intergrateApp = <P>(params: P) => {
    return axios.post(`/integrate`, params)
  }

  //   const getUserInfo = () => {
  //     return axios.get(`/users/info`)
  //   }

  return { intergrateApp }
}
