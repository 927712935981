import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_edit = _resolveComponent("edit")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_Update = _resolveComponent("Update")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_el_table, {
      ref: "adminUserTable",
      data: _ctx.documents,
      style: {"width":"100%"},
      lazy: _ctx.isLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          property: "fullName",
          label: "Fullname",
          "min-width": "10"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_router_link, {
              to: { path: `/a/users/${scope.row.id}` }
            }, {
              default: _withCtx(({ href }) => [
                _createVNode(_component_el_link, {
                  href: href,
                  type: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(scope.row.fullName), 1)
                  ]),
                  _: 2
                }, 1032, ["href"])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          property: "email",
          label: "Email",
          "min-width": "10"
        }),
        _createVNode(_component_el_table_column, {
          property: "userType",
          label: "Role",
          "min-width": "10"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.capitalizeFirstLetter(scope.row.userType)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "Actions",
          "min-width": "5"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              size: "small",
              onClick: ($event: any) => (_ctx.openUpdateDialog(scope.row))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_edit)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_el_button, {
              size: "small",
              type: "danger",
              onClick: ($event: any) => (_ctx.handleDelete(scope.row))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_delete)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data", "lazy"]), [
      [_directive_loading, _ctx.isLoading]
    ]),
    _createVNode(_component_Update, {
      ref: "userUpdateDialog",
      onChange: _ctx.forceUpdate
    }, null, 8, ["onChange"])
  ], 64))
}