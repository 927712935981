<template>
  <el-row
    class="h-full w-full flex justify-center bg-fixed-bottom"
    :style="
      'background-image: url(' +
      require('@/assets/img/illustrations/sticky.svg') +
      ');'
    "
  >
    <el-col :span="6">
      <el-form
        ref="ruleForm"
        :model="state.signUpForm"
        status-icon
        :rules="rules"
        label-width="120px"
        :label-position="`top`"
        class="login-form font-bold"
        @submit.prevent="handleSubmit"
        @keydown.enter.prevent="handleSubmit"
      >
        <el-form-item style="text-align: center">
          <el-image
            v-if="config && config.logo"
            style="width: 200px"
            :src="config.logo ? imageUrl(config.logo, true, 200) : ``"
            :fit="`contain`"
          ></el-image>
        </el-form-item>
        <!-- <el-form-item>
          <p class="font-bold text-xl text-center">Sign up</p>
        </el-form-item> -->
        <el-form-item label="Name" prop="name">
          <el-input
            v-model="state.signUpForm.name"
            type="text"
            autocomplete="off"
            autofocus
          ></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input
            v-model="state.signUpForm.email"
            type="text"
            autocomplete="off"
            autofocus
          ></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input
            v-model="state.signUpForm.password"
            type="password"
            autocomplete="off"
            auto
          ></el-input>
        </el-form-item>
        <el-form-item label="Confirm Password" prop="confirmPassword">
          <el-input
            v-model="state.signUpForm.confirmPassword"
            type="password"
            autocomplete="off"
            auto
          ></el-input>
        </el-form-item>
      </el-form>
      <div>
        <div class="text-center mt-5">
          <el-button :loading="isLoading" type="primary" @click="handleSubmit">
            <span class="font-bold">Sign up</span>
          </el-button>
        </div>
        <div class="mt-5 text-right">
          Have an account?
          <router-link :to="{ path: `/auth/login` }" v-slot="{ href }">
            <el-link type="primary" :href="href">Sign in.</el-link>
          </router-link>
        </div>
      </div>
    </el-col>
  </el-row>
  <el-dialog
    v-model="signUpSuccessDialogVisible"
    title="Sign up"
    width="35%"
    :before-close="handleClose"
  >
    <p class="font-bold text-md">Thanks for signing up!</p>
    <p class="mt-5">We have sent you an email confirmation.</p>
    <p class="mt-3">
      Please confim your email and then we will activate your account.
    </p>
    <el-button type="text" class="mt-3 underline" @click="handleSubmit">
      Resend email confirmation
    </el-button>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="signUpSuccessDialogVisible = false">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref, computed } from 'vue'
// import { useServiceAuth } from '@/services'
import { useNotification } from '@/composables'
import { useRouter } from 'vue-router'
import { imageUrl } from '@/utils/image'
import { useStore } from 'vuex'
import { useResource } from '@/services'
import { emailRule, stringRules } from '@/utils/formRules'

export default {
  setup() {
    const state = reactive({
      signUpForm: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        referralCode: '',
        appToken: '',
      },
    })
    const { error, warning } = useNotification()
    const rules = {
      email: emailRule,
      password: stringRules('Password', 6, 128),
      confirmPassword: stringRules('Confirm Password', 6, 128),
      name: stringRules('Name', 2, 60),
    }
    const isLoading = ref(false)
    const signUpSuccessDialogVisible = ref(false)

    const router = useRouter()
    const store = useStore()
    let config = computed(() => {
      return store.getters['app/appConfig']
    })

    const handleSubmit = async e => {
      if (!state.signUpForm.name || state.signUpForm.name === '') {
        warning('Please input name')
        return
      }
      if (!state.signUpForm.email || state.signUpForm.email === '') {
        warning('Please input email')
        return
      }
      if (!state.signUpForm.password || state.signUpForm.password === '') {
        warning('Please input password')
        return
      }
      if (state.signUpForm.password !== state.signUpForm.confirmPassword) {
        warning('Your confirm password not match!')
        return
      }
      e.preventDefault()
      try {
        isLoading.value = true

        // detect referralCode
        let refCode = ''
        const href = window.location.href
        if (href?.includes('/auth/register?r=')) {
          const url = new URL(href)
          refCode = url.searchParams.get('r')
        }
        if (refCode !== '') {
          state.signUpForm.referralCode = refCode
        } else {
          delete state.signUpForm.referralCode
        }

        let app_token = localStorage.getItem('app-token')
        if (app_token && app_token !== '') {
          state.signUpForm.appToken = app_token
        } else {
          delete state.signUpForm.appToken
        }

        // sign up
        const { add } = useResource('auth/signup')
        let res = await add(state.signUpForm)
        // Object.assign(entity, res.data)
        if (res.status === 201 || res.status === 200) {
          signUpSuccessDialogVisible.value = true
        } else {
          error(res.data.message)
        }
      } catch (e) {
        error(e?.data?.message || null)
      }
      isLoading.value = false
    }

    return {
      rules,
      state,
      handleSubmit,
      config,
      imageUrl,
      isLoading,
      signUpSuccessDialogVisible,
    }
  },
}
</script>
<style scoped>
.bg-fixed-bottom {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
  background-attachment: fixed;
  padding-top: 10%;
}
</style>
