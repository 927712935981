<template>
  <el-row
    class="h-full w-full flex justify-center bg-fixed-bottom"
    :style="
      'background-image: url(' +
      require('@/assets/img/illustrations/sticky.svg') +
      ');'
    "
  >
    <el-col :span="6">
      <el-row>
        <el-col :span="24" class="mb-10">
          <h3 class="font-bold mb-10">Welcome to LattePrint!</h3>
          <h5>
            Having a
            <b>LattePrint</b>
            account, you will have better service.
          </h5>
          <p>Link your account now to complete store authorization.</p>
        </el-col>
        <el-divider></el-divider>
        <el-col :span="24" class="mt-10">
          <p style="text-align: center">
            <router-link
              :to="{ path: `/auth/login` }"
              v-slot="{ href }"
              class="mr-3"
            >
              <el-link :href="href" :underline="false">
                <el-button
                  style="
                    font-weight: bold;
                    color: white;
                    background-color: #2196f3;
                    border: none;
                  "
                >
                  Login with account
                </el-button>
              </el-link>
            </router-link>
            <router-link :to="{ path: `/auth/register` }" v-slot="{ href }">
              <el-link :href="href" :underline="false">
                <el-button
                  style="
                    font-weight: bold;
                    color: white;
                    background-color: #2196f3;
                    border: none;
                  "
                >
                  Create new account
                </el-button>
              </el-link>
            </router-link>
          </p>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style scoped>
.bg-fixed-bottom {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
  background-attachment: fixed;
  padding-top: 10%;
}
</style>
