
import { defineComponent, reactive, ref } from 'vue'
import { stringRules } from '@/utils/formRules'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import SaveButton from '@/components/common/buttons/SaveButton.vue'
import useUserRepositories from '@/repositories/useUserRepositories'
import { useNotification } from '@/composables'

interface RuleForm {
  password: string
  confirmPassword: string
}

export default defineComponent({
  components: { CloseButton, SaveButton },
  props: {},
  setup() {
    const dialogVisible = ref(false)
    const formRef = ref()
    const form = reactive<RuleForm>({
      password: '',
      confirmPassword: '',
    })

    const formRules = ref({
      password: stringRules('Password', 6, 60),
      confirmPassword: stringRules('Confirm Password', 6, 60),
    })

    const { error, success } = useNotification()

    const { resetPassword } = useUserRepositories()
    const onSubmitChangePwd = async () => {
      if (form.password !== form.confirmPassword) {
        error('The passwords not match.')
        return
      }
      const res = await resetPassword(userId.value?.toString(), form.password, form.confirmPassword)

      if (res?.status === 200) {
        success('Reset password success!')
        close()
      } else {
        error('Reset password error!')
      }
    }

    const resetField = () => {
      form.password = ''
      form.confirmPassword = ''
    }

    const userId = ref()
    const toggle = (id: any) => {
      userId.value = id
      resetField()
      dialogVisible.value = true
    }

    const close = () => {
      dialogVisible.value = false
    }

    return { dialogVisible, toggle, formRef, form, formRules, onSubmitChangePwd, close, resetField }
  },
})
