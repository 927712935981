export const MENU = {
  administrator: [
    // { icon: `fas fa-chart-line`, name: `Dashboard`, path: '/a/dashboard' },
    { icon: `fas fa-tshirt`, name: `Seller Catalog`, path: '/a/catalogs' },
    {
      icon: `fas fa-archway`,
      name: `Category Catalog`,
      path: '/a/category-catalog',
    },
    { icon: `fab fa-accusoft`, name: `Agencies`, path: '/a/agencies' },
    {
      icon: `fab fa-accusoft`,
      name: `Supplier Catalog`,
      path: '/a/supplier-catalog',
    },
    { icon: `fas fa-user`, name: `Users`, path: '/a/users' },
    // { icon: `fas fa-money-bill-wave-alt`, name: `Base cost`, path: '/a/base-cost' },
  ],
  supplier: [
    {
      icon: `fab fa-accusoft`,
      name: `Supplier Catalog`,
      path: '/a/supplier-catalog',
    },
  ],
  agency: [
    // { icon: `fas fa-chart-line`, name: `Dashboard`, path: '/ag/dashboard' },
    { icon: `fas fa-tshirt`, name: `Product Catalog`, path: '/ag/catalogs' },
    {
      icon: `fas fa-archway`,
      name: `Category Catalog`,
      path: '/ag/category-catalog',
    },
    { icon: `fas fa-user-tag`, name: `Sellers`, path: '/ag/sellers' },
    { icon: `fas fa-shopping-cart`, name: `Orders`, path: '/ag/orders' },
    { icon: `fas fa-list`, name: `Order Items`, path: '/ag/order-items' },
    { icon: `fas fa-truck-moving`, name: `Tracking`, path: '/ag/tracking' },
    { icon: `fas fa-coins`, name: `Deposits`, path: '/ag/deposits' },
    { icon: `fas fa-money-check-alt`, name: `Invoices`, path: '/ag/invoices' },
    {
      icon: `fas fa-money-bill-wave-alt`,
      name: `Base cost`,
      path: '/ag/base-cost',
    },
    { icon: `fas fa-user`, name: `Users`, path: '/ag/users' },
    { icon: `fas fa-cog`, name: `Settings`, path: '/ag/settings' },
    { icon: `far fa-envelope`, name: `Ticket`, path: '/ag/tickets' },
    // { icon: `fas fa-images`, name: `Image Library`, path: '/ag/image-library' },
    // { icon: `fas fa-link`, name: `Referrals`, path: '/ag/referrals' },
    { icon: `far fa-address-card`, name: `Customers`, path: '/ag/customers' },
    { icon: `fas fa-shopping-bag`, name: `Stores`, path: '/ag/stores' },
    // { icon: `fas fa-images`, name: `Image Library`, path: '/s/image-library' },
    // { icon: `fas fa-money-bill`, name: `Payouts`, path: '/ag/payouts' },
    // {
    //   icon: `fas fa-cloud-upload-alt`,
    //   name: `Requested designs`,
    //   path: '/ds/orders',
    // },
    // {
    //   icon: `fas fa-envelope`,
    //   name: `Email History`,
    //   path: '/ag/email-history',
    // },
  ],
  seller: [
    { icon: `fas fa-shopping-cart`, name: `Orders`, path: '/s/orders' },
    { icon: `fas fa-list`, name: `Order Items`, path: '/s/order-items' },
    {
      icon: `fas fa-money-check`,
      name: `Profit Detail`,
      path: '/s/profit-detail',
    },
    { icon: `fas fa-money-bill`, name: `Payouts`, path: '/s/payouts' },
    { icon: `far fa-address-card`, name: `Customers`, path: '/s/customers' },
    { icon: `fas fa-user`, name: `Users`, path: '/s/users' },
    { icon: `fas fa-coins`, name: `Balance`, path: '/s/balance' },
    { icon: `fas fa-money-check-alt`, name: `Invoices`, path: '/s/invoices' },
    { icon: `fas fa-link`, name: `Referrals`, path: '/s/referrals' },
    { icon: `fas fa-plug`, name: `Stores`, path: '/s/integrations' },
    { icon: `fas fa-images`, name: `Image Library`, path: '/s/image-library' },
    { icon: `fas fa-tshirt`, name: `Product Catalog`, path: '/s/catalogs' },
    { icon: `fab fa-product-hunt`, name: `Products`, path: '/s/products' },

    // {
    //   icon: `fas fa-tshirt`,
    //   name: `Dropship Catalog`,
    //   path: '/s/dropship-catalogs',
    // },
    // {
    //   icon: `fas fa-chart-line`,
    //   name: `Estimated profit`,
    //   path: '/s/estimated-profit',
    // },
  ],
  orderExporter: [
    { icon: `fas fa-shopping-cart`, name: `Orders`, path: '/ag/orders' },
    // { icon: `fas fa-list`, name: `Order Items`, path: '/ag/order-items' },
  ],
  staffBambooz: [
    { icon: `fas fa-tshirt`, name: `Product Catalog`, path: '/s/catalogs' },
    {
      icon: `fas fa-archway`,
      name: `Category Catalog`,
      path: '/ag/category-catalog',
    },
    {
      icon: `fas fa-money-bill-wave-alt`,
      name: `Base cost`,
      path: '/ag/base-cost',
    },
    { icon: `fas fa-truck-moving`, name: `Tracking`, path: '/ag/tracking' },
    { icon: `fas fa-user-tag`, name: `Sellers`, path: '/ag/sellers' },
  ],
  customerSupportBambooz: [
    { icon: `fas fa-tshirt`, name: `Product Catalog`, path: '/s/catalogs' },
    { icon: `fas fa-shopping-cart`, name: `Orders`, path: '/ag/orders' },
    { icon: `fas fa-list`, name: `Order Items`, path: '/ag/order-items' },
    { icon: `fas fa-truck-moving`, name: `Tracking`, path: '/ag/tracking' },
    { icon: `far fa-address-card`, name: `Customers`, path: '/ag/customers' },
    { icon: `fas fa-shopping-bag`, name: `Stores`, path: '/ag/stores' },
  ],
  accountingBambooz: [
    { icon: `fas fa-shopping-cart`, name: `Orders`, path: '/ag/orders' },
    { icon: `fas fa-list`, name: `Order Items`, path: '/ag/order-items' },
    { icon: `far fa-address-card`, name: `Customers`, path: '/ag/customers' },
    { icon: `fas fa-shopping-bag`, name: `Stores`, path: '/ag/stores' },
    { icon: `fas fa-money-bill`, name: `Payouts`, path: '/ag/payouts' },
    { icon: `fas fa-money-check-alt`, name: `Invoices`, path: '/ag/invoices' },
    { icon: `fas fa-coins`, name: `Deposits`, path: '/ag/deposits' },
    { icon: `fas fa-user-tag`, name: `Sellers`, path: '/ag/sellers' },
  ],
  designerBambooz: [
    { icon: `fas fa-images`, name: `Image Library`, path: '/s/image-library' },
  ],
}
