
import { defineComponent, ref } from "vue";
import { UploadFilled } from "@element-plus/icons";

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  url?: string;
  percentage?: any;
  raw: string | Blob;
  size: number;
}

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Select File",
    },
    accept: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadFilled,
  },
  setup(props, context) {
    const fileList = ref<FileItem[]>([]);
    const uploading = ref<boolean>(false);

    const onUploadFiles = (file: FileItem, fileList: FileItem[]) => {
      uploading.value = true;
      const formData = new FormData();

      fileList.forEach((file: FileItem) => {
        formData.append("files[]", file as any);
      });
      if (!props.autoUpload) {
        context.emit("onChangeFiles", fileList);
      }
      // console.log("formData", formData);

      // You can use any AJAX library you like
      uploading.value = false;
    };

    return {
      fileList,
      uploading,
      onUploadFiles,
    };
  },
});
