
import { defineComponent, reactive, watch } from '@vue/runtime-core'
import { Search as IconSearch } from '@element-plus/icons'

export default defineComponent({
  name: `FilterTable`,
  components: { IconSearch },
  props: {
    placeholder: {
      type: String,
      default: `Search`,
    },
    isShowButtonSearch: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const form = reactive({
      filter: {
        search: '',
      },
    })
    let emitTimeout: any = false
    const onChange = watch(form, () => {
      if (emitTimeout) {
        clearTimeout(emitTimeout)
      }
      emitTimeout = setTimeout(() => broadcast(), 500)
    })

    const broadcast = () => {
      form.filter.search = form.filter.search.trim()
      emit('onFilterCallback', form.filter)
    }

    return {
      form,
      onChange,
    }
  },
})
