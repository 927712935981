
import { defineComponent, reactive, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useFetchData } from '@/composables'
import { formatTime } from '@/utils/dateTime'
import FilterTable from '@/components/common/FilterTable.vue'
import Pagination from '@/components/common/Pagination.vue'

export default defineComponent({
  components: {
    FilterTable,
    Pagination,
  },
  setup() {
    const route = useRoute()

    const baseResource = 'payouts'
    const columns = [
      {
        key: '_id',
        title: 'Code',
        dataIndex: 'code',
        minWidth: '8',
      },
      {
        title: 'Method',
        dataIndex: 'payoutMethod',
        minWidth: '10',
      },
      {
        title: 'Account',
        dataIndex: '_account',
        minWidth: '10',
      },
      {
        title: 'Created At',
        dataIndex: 'createdDate',
        minWidth: '11',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        minWidth: '8',
      },
      {
        title: 'BillName',
        dataIndex: 'billName',
        minWidth: '10',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        minWidth: '8',
      },
      {
        title: 'Approved At',
        dataIndex: 'approvedDate',
        minWidth: '11',
      },
      {
        title: 'Note',
        dataIndex: 'note',
        minWidth: '10',
      },
    ]
    const data = reactive({
      status: [
        {
          id: 0,
          name: 'Pending',
        },
        {
          id: 1,
          name: 'Approved',
        },
        {
          id: 2,
          name: 'Rejected',
        },
      ],
    })
    const params = reactive({
      sellerTeamId: route.params.id,
      billName: null,
      status: null,
      page: 1,
      limit: 10,
    })

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )
    watch(params, fetchData)
    onMounted(async () => {
      await fetchData()
    })

    const methodName = (val: string) => {
      console.log('🚀 ~ file: index.vue:249 ~ methodName ~ val:', val)
      if (val === 'paypal') return 'PayPal'
      if (val === 'pingpong') return 'PingPong'
      if (val === 'creditcard') return 'Credit card'
      if (val === 'payoneer') return 'Payoneer'
      return val
    }
    const statusName = (val: any) => {
      if (val === 0) return 'Pending'
      if (val === 1) return 'Approved'
      if (val === 2) return 'Rejected'
      return 'n/a'
    }
    const onFilterCallback = (filter: any) => {
      params.billName = filter.search
    }
    return {
      columns,
      documents,
      total,
      params,
      isLoading,
      data,
      formatTime,
      methodName,
      statusName,
      onFilterCallback,
    }
  },
})
