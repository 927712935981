<template>
  <div class="media mb-[16px] w-full">
    <div class="border border-gray-300 rounded-lg overflow-hidden">
      <Carousel v-model="currentSlide" :items-to-show="1" :wrap-around="false">
        <Slide
          v-for="(slide, index) in resizeMockupGalleryMain(props.mockupGallery)"
          :key="slide"
        >
          <div class="h-full w-full" @click="slideTo(index)">
            <img
              class="object-cover h-full w-auto rounded-[8px]"
              :src="slide"
              :alt="slide"
            />
          </div>
        </Slide>
        <template #addons>
          <!--        <Pagination />-->
          <Navigation />
        </template>
      </Carousel>
    </div>
    <div class="mt-2">
      <Carousel
        v-model="currentSlide"
        :items-to-show="4"
        :wrap-around="true"
        ref="carousel"
      >
        <Slide
          v-for="(slide, index) in resizeMockupGalleryMain(props.mockupGallery)"
          :key="slide"
        >
          <div
            class="
              h-full
              w-full
              bg-red-200
              m-1
              border border-gray-300
              rounded-lg
              overflow-hidden
              cursor-pointer
            "
            @click="slideTo(index)"
          >
            <img class="object-cover h-full w-full" :src="slide" :alt="slide" />
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>
<script>
import { Slide, Carousel, Navigation } from 'vue3-carousel'
import { ref } from 'vue'
import 'vue3-carousel/dist/carousel.css'
export default {
  components: { Slide, Carousel, Navigation },
  props: {
    mockupGallery: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const settings = {
      itemsToShow: 1,
      snapAlign: 'center',
    }
    const breakpoints = {
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    }

    const isLattePrintUrl = url => {
      const bucketAppUrl = `https://static.latteprint.com/`
      if (!url?.trim()) {
        return false
      }
      url = url.trim()
      if (url.startsWith(bucketAppUrl)) {
        return true
      }
    }
    const imageUrl = (url, isResize, width, height) => {
      const bucketAppUrl = `https://static.latteprint.com/`
      if (!url?.trim()) {
        return url
      }
      url = url.trim()

      // neu ko phai link latteprint
      if (!isLattePrintUrl(url)) {
        return url
      }
      url = url.replace(bucketAppUrl, '')

      let baseURL = bucketAppUrl
      const resizeBaseUrl = bucketAppUrl.replace(
        'https://static.latteprint.com/',
        'https://resize.bambooz.io/'
      )
      // la link latteprint
      if (isResize) {
        url = url.replace(bucketAppUrl, '')
        if (width > 0) {
          if (height > 0) {
            baseURL = `${resizeBaseUrl}img/${width}/${height}/resize/`
          } else {
            baseURL = `${resizeBaseUrl}img/${width}/resize/`
          }
        } else {
          // ảnh resize với dung lượng nhỏ hơn
          baseURL = `${resizeBaseUrl}img/0/0/resize/`
        }
      }
      return baseURL + url
    }

    const resizeMockupGalleryMain = mockups => {
      const w = 992
      mockups = mockups.map((galleryItem, index) => {
        if (
          galleryItem.endsWith('.png') ||
          galleryItem.endsWith('.jpg') ||
          galleryItem.endsWith('.jpeg') ||
          galleryItem.endsWith('.webp')
        ) {
          if (galleryItem.startsWith('http')) {
            return imageUrl(galleryItem, true, w, w)
          }
          return (galleryItem = `https://resize.bambooz.io/img/${w}/${w}/resize/${galleryItem}`)
        }
        return galleryItem
      })
      return mockups
    }

    const currentSlide = ref(0)
    const carousel = ref(null)

    const slideTo = val => {
      currentSlide.value = val
    }
    return {
      props,
      settings,
      breakpoints,
      currentSlide,
      resizeMockupGalleryMain,
      carousel,
      slideTo,
    }
  },
}
</script>
