import { ActionTree } from "vuex";
import { UserState } from "./state";
import { RootState } from '@/store/types'
import { useNotification } from "@/composables";
import { useServiceAuth } from "@/services";

export const actions: ActionTree<UserState, RootState> = {
  async me({ commit }) {
    const { error } = useNotification()
    const { getUserInfo } = useServiceAuth()

    const { data, status } = await getUserInfo()
    if (status === 200 || status === 201) {
      commit('setUser', data)
    } else {
      error(data.message)
    }
  }
};