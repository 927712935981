
import { defineComponent, reactive, ref, computed } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useResource } from '@/services'
import { useRoute } from 'vue-router'
import { updateHandler } from '@/services/updateHandler'
import { stringRules } from '@/utils/formRules'
import { useNotification } from '@/composables'

export default defineComponent({
  setup() {
    const hidePwdForm = ref(true)
    const resource = 'users'
    const { params } = useRoute() as any
    const breadcrumbs = ['Profile', '']
    const title = 'Profile'
    const content = computed(() => {
      return { breadcrumbs, title }
    })

    const state = reactive({
      form: {
        fullName: '',
        code: '',
        active: 1,
        manager: '',
      },
      changePwdForm: {
        password: '',
        newPassword: '',
        confirmNewPassword: '',
      },
    })

    const url =
      'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'

    const fectchRecord = async () => {
      const { get } = useResource(resource)
      const { data, status } = await get(params.id)
      if (status === 200) {
        state.form = data as any
      }
    }

    const { error, success } = useNotification()

    const userFormRules = ref({
      fullName: stringRules('fullname', 2, 128),
    })

    const userForm = ref()
    const onSubmit = async () => {
      userForm.value?.validate(async (isValid: boolean) => {
        if (isValid) {
          const url = `${resource}/${params.id}`
          let patchData = {
            fullName: state.form.fullName,
          }
          const { update } = updateHandler(url, patchData)
          const { status, data } = await update()
          if (status === 200) {
            success('Update success')
          } else {
            error(data.message)
          }
        } else {
          console.log('not valid')
        }
      })
    }

    const validateOldPass = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('Please input the current password'))
      } else {
        if (state.changePwdForm.password !== '') {
          if (!changePwdForm.value) return
          changePwdForm.value.validateField(
            'confirmNewPasswordkPass',
            () => null
          )
        }
        callback()
      }
    }

    const validatePass = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('Please input the new password'))
      } else if (value.length < 6 || value.length > 128) {
        callback(new Error('new password should be 6 to 128'))
      } else {
        if (state.changePwdForm.newPassword !== '') {
          if (!changePwdForm.value) return
          changePwdForm.value.validateField(
            'confirmNewPasswordkPass',
            () => null
          )
        }
        callback()
      }
    }
    const validatePass2 = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('Please input the new password again'))
      } else if (value !== state.changePwdForm.newPassword) {
        callback(new Error("Two inputs don't match!"))
      } else {
        callback()
      }
    }

    // const rules = reactive({
    //   newPassword: [{ validator: validatePass, trigger: 'blur' }],
    //   confirmNewPasswordkPass: [{ validator: validatePass2, trigger: 'blur' }]
    // })

    const changePasswordRules = reactive({
      password: [{ validator: validateOldPass, trigger: 'blur' }],
      newPassword: [{ validator: validatePass, trigger: 'blur' }],
      confirmNewPassword: [{ validator: validatePass2, trigger: 'blur' }],

      // password: required('password'),
      // newPassword: stringRules('new password', 6, 128),
      // confirmNewPassword: stringRules('confirm password', 6, 128),
    })

    const changePwdForm = ref()
    const onSubmitChangePwd = async () => {
      const url = `${resource}/info`
      let patchData = {
        action: 'reset_password',
        data: {
          oldPassword: state.changePwdForm.password,
          newPassword: state.changePwdForm.newPassword,
          confirmNewPassword: state.changePwdForm.confirmNewPassword,
        },
      }
      const { update } = updateHandler(url, patchData)
      const { status, data } = await update()
      if (status === 200) {
        success('Update password success')
        hidePwdForm.value = true
      } else {
        error(data.message)
      }
    }

    return {
      content,
      url,
      state,
      hidePwdForm,
      fectchRecord,
      onSubmitChangePwd,
      onSubmit,
      userForm,
      userFormRules,
      changePwdForm,
      changePasswordRules,
    }
  },
  created() {
    this.fectchRecord()
  },
  components: {
    sectionLayoutContent,
  },
})
