import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "" }
const _hoisted_2 = { class: "grid-content text-small pb-1 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.heading), 1),
    _createVNode(_component_editor, {
      "api-key": "cp1okc1v5mgid311kk2qxto3hn01wllmxcduuffi34b50rwr",
      modelValue: _ctx.html,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.html) = $event)),
      init: {
        toolbar_mode: 'wrap',
        toolbar_sticky: true,
        selector: 'textarea',
        menubar: false,
        plugins:
          'autoresize print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable code',

        toolbar: _ctx.toolbar,
        max_height: _ctx.height,
        min_height: _ctx.minHeight,
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        /* without images_upload_url set, Upload tab won't show up*/
        /* we override default upload handler to simulate successful upload*/
        browser_spellcheck: true,
        images_upload_handler: _ctx.example_image_upload_handler,
      },
      onChange: _ctx.change
    }, null, 8, ["modelValue", "init", "onChange"])
  ]))
}