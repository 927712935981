
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import { useFetchData } from '@/composables'
import Pagination from '@/components/common/Pagination.vue'
import { useRouter } from 'vue-router'
import { formatTime } from '@/utils/dateTime'
import { imageUrl } from '@/utils/image'
import PreviewImage from '@/components/common/PreviewImage.vue'
import {
  getPublishStatusName,
  getPublishStatusTagColor,
  PUBLISH_STATUS_ARR,
  CATALOG_PUBLISH_STATUS,
  SupplierLogType,
} from '@/utils/catalog'
import ExpectedPrice from './components/ExpectedPrice.vue'
import HistoryChangeDialog from './components/HistoryChangeDialog.vue'
import ImportPriceDialog from './components/ImportPriceDialog.vue'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import userSupplierLogRepositories, {
  SupplierLog,
} from '@/repositories/userSupplierLogRepositories'
import { useNotification } from '@/composables'
import { ArrowDown } from '@element-plus/icons'
import axios from '@/services/axios'
import { downloadFile } from '@/utils/file'

export default defineComponent({
  components: {
    sectionLayoutContent,
    FilterTable,
    Pagination,
    PreviewImage,
    ExpectedPrice,
    ArrowDown,
    ImportPriceDialog,
    HistoryChangeDialog,
  },
  setup() {
    const breadcrumbs = ['Supplier Catalog', '']
    const title = 'Supplier Catalog'
    const baseResource = '/catalogs'

    const titleExport = 'Export'
    const titleImport = 'Import'

    const { error, success } = useNotification()

    const params = reactive({
      page: 1,
      limit: 10,
      dropship: true,
      active: true,
      isTrademark: true,
      publishStatus: null,
      search: '',
    })

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )
    fetchData()
    const router = useRouter()

    watch(params, fetchData)

    const content = computed(() => {
      return { breadcrumbs, title, titleExport, titleImport }
    })

    const onFilterCallback = (data: any) => {
      params.search = data?.search
    }

    const edit = (item: any) => {
      try {
        if (item?.dropship) {
          router.push({
            name: 'a.catalog-detail.drop',
            params: { id: item._id },
          })
        } else {
          router.push({ name: 'a.catalog-detail', params: { id: item._id } })
        }
      } catch (error) {
        console.log(error)
      }
    }

    const getFirstImageMockupGallery = (mockupGallery: string[]) => {
      let imageResult = ''
      if (mockupGallery != null && mockupGallery.length > 0) {
        imageResult = mockupGallery[0]
      }
      return imageResult
    }

    const { updateCatalogVariants, updatePublishStatus, exportPriceCatalog } =
      userCatalogRepositories()

    const { saveLog } = userSupplierLogRepositories()

    const testAPI = async () => {
      //
    }

    const saveChangePriceLog = async (catalog: any) => {
      const supplierLog: SupplierLog = {
        catalog: catalog._id,
        variantChanged: catalog.variants,
        status: catalog.publishStatus,
        note: catalog.note,
        type: SupplierLogType.ChangeSupplierPrice,
      }
      await saveLog(supplierLog)
    }

    const callbackExpectedPrice = async (
      catalog: any,
      publishStatus: number,
      isChanged: boolean
    ) => {
      // check neu ko co thay doi gi thi return
      if (!isChanged && catalog.publishStatus === publishStatus) {
        success('No changed!')
        return
      }
      if (publishStatus) {
        catalog.publishStatus = publishStatus
      }

      const { status, data } = await updateCatalogVariants(catalog)
      if (status === 200) {
        saveChangePriceLog(catalog)
        success('Updated')
        await fetchData()
      } else {
        error(data.message)
      }
    }

    const updatePublishStatusCatalog = async (id: string, status: string) => {
      const res = (await updatePublishStatus({
        ids: id,
        status: status,
      })) as any
      if (res.status === 201 || res.status === 200) {
        fetchData()
        success(`Success`)
      } else {
        error(res.data.message)
      }
    }

    const importPriceDialog = ref<InstanceType<typeof ImportPriceDialog>>()
    const importPrice = () => {
      importPriceDialog.value?.toggle()
    }

    const historyChangeDialog = ref<InstanceType<typeof HistoryChangeDialog>>()
    const historyDialogToggle = (_id: string) => {
      historyChangeDialog.value?.toggle(
        _id,
        SupplierLogType.ChangeSupplierPrice
      )
    }

    const onAfterImportPrice = async () => {
      fetchData()
    }

    const exportPrice = async () => {
      const exportParams = {
        dropship: true,
        isTrademark: true,
        publishStatus: params.publishStatus,
        search: params.search,
      }
      // console.log(2222222, exportParams)

      const res = await exportPriceCatalog(exportParams)
      if (res) {
        if (res.status === 201 || res.status === 200) {
          downloadFile(res.data.url)
          success(`Export success!`)
        } else {
          error(res.data.message)
        }
      } else {
        error('Error when export')
      }
    }

    const columns = [
      {
        title: 'Created',
        dataIndex: 'createdAt',
        minWidth: 7,
      },
      {
        title: 'Image',
        dataIndex: 'mockupGallery',
        minWidth: 10,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        minWidth: 10,
      },
      {
        title: 'Code',
        dataIndex: 'productCode',
        minWidth: 8,
      },
      {
        title: 'Status',
        dataIndex: 'publishStatus',
        minWidth: 4,
      },
      {
        title: 'Actions',
        minWidth: 8,
      },
    ]

    const previewImageDialog = ref<InstanceType<typeof PreviewImage>>()
    const onClickPreviewImage = (path: string) => {
      previewImageDialog.value?.toggle(imageUrl(path))
    }

    const expectedPriceDialog = ref<InstanceType<typeof ExpectedPrice>>()
    const openExpectedPriceDialog = (catalogId: string) => {
      expectedPriceDialog.value?.toggle(catalogId)
    }

    return {
      content,
      documents,
      isLoading,
      fetchData,
      total,
      columns,
      params,
      edit,
      formatTime,
      imageUrl,
      getFirstImageMockupGallery,
      onClickPreviewImage,
      previewImageDialog,
      getPublishStatusName,
      getPublishStatusTagColor,
      PUBLISH_STATUS_ARR,
      expectedPriceDialog,
      openExpectedPriceDialog,
      callbackExpectedPrice,
      CATALOG_PUBLISH_STATUS,
      updatePublishStatusCatalog,
      importPrice,
      exportPrice,
      onAfterImportPrice,
      importPriceDialog,
      testAPI,
      saveChangePriceLog,
      historyChangeDialog,
      historyDialogToggle,
      onFilterCallback,
    }
  },
})
