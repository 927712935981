import { useResource } from '@/services'
import { ref, Ref } from 'vue'
import userProductRepositories from '@/repositories/userProductRepositories'
import { useRoute, useRouter } from 'vue-router'
import { useNotification } from '.'

type Example = any

export const useFetchData = <T, P>(resource: string, params?: P) => {
  const router = useRouter()

  const documents = ref<T[]>([]) as Ref<T[]>
  const total = ref<number>(0)
  const count = ref<number>(0)
  const isLoading = ref(false)

  const { all, getAll } = useResource<T>(resource)
  const { error } = useNotification()

  const debouncedFetch = async () => {
    await fetchData()
  }

  const fetchAll = async () => {
    try {
      isLoading.value = true

      const { data } = await all()
      documents.value = data?.data || data || []
    } catch (e: any) {
      console.log({ e })
      if (e.data?.statusCode === 403) {
        // error(e.data.message)
        router.push({ name: `no-access` })
      }
    }

    isLoading.value = false
  }

  const fetchData = async () => {
    try {
      isLoading.value = true

      const { data } = (await getAll(params)) as any
      // endpoint api
      documents.value = data?.data || data
      total.value = data?.pagination?.total || 0
    } catch (e: any) {
      console.log({ e })
      if (e.data?.statusCode === 403) {
        // error(e.data.message)
        router.push({ name: `no-access` })
      }
    }

    isLoading.value = false
  }
  const countTotal = async () => {
    try {
      const { countProduct } = userProductRepositories()
      const { status, data } = await countProduct()
      count.value = data
    } catch (e) {
      console.log(e)
    }
  }

  return {
    documents,
    isLoading,
    fetchData,
    total,
    debouncedFetch,
    countTotal,
    count,
    fetchAll,
  }
}
