export enum AuthorityEnum {
  Manager = 'seller',
  Staff = 'staff',
  Designer = 'designer',
  Accounting = 'accounting',
  CustomerSupport = 'customer_support',
  OrderExporter = 'order_exporter',
}

export enum AgencyRoleEnum {
  Manager = 'agency',
}

export enum UserTypeEnum {
  Agency = 'agency',
  Seller = 'seller',
  Administrator = 'administrator',
  Supplier = 'supplier',
  OrderExporter = 'order_exporter',
}

export const SellerRoles = [
  { label: 'Staff', value: AuthorityEnum.Staff },
  { label: 'Designer', value: AuthorityEnum.Designer },
  { label: 'Accounting', value: AuthorityEnum.Accounting },
  { label: 'Customer Support', value: AuthorityEnum.CustomerSupport },
  { label: 'Order Exporter', value: AuthorityEnum.OrderExporter },
]

export const SellerRoleMenu = {
  [AuthorityEnum.Accounting]: [
    '/s/catalogs',
    '/s/products',
    '/s/orders',
    '/s/order-items',
    '/s/invoices',
    '/s/balance',
    '/s/customers',
    '/s/image-library',
  ],
  [AuthorityEnum.Designer]: ['/s/orders', '/s/order-items', '/s/image-library'],
  [AuthorityEnum.CustomerSupport]: [
    '/s/orders',
    '/s/order-items',
    '/s/customers',
    '/s/image-library',
  ],
}

export const AgencyRoleMenu = {
  [AuthorityEnum.Accounting]: [
    '/ag/catalogs',
    '/ag/orders',
    '/ag/order-items',
    '/ag/tracking',
    '/ag/sellers',
    '/ag/tracking',
    '/ag/deposits',
    '/ag/invoices',
    '/ag/base-cost',
    '/ag/stores',
    '/ag/tickets',
    '/s/image-library',
    '/ag/payouts',
    '/ag/customers',
    '/ds/orders',
  ],
  [AuthorityEnum.CustomerSupport]: [
    '/ag/catalogs',
    '/ag/orders',
    '/ag/order-items',
    '/ag/customers',
    '/s/image-library',
  ],
  [AuthorityEnum.Designer]: ['/ds/orders', '/s/image-library'],
  [AuthorityEnum.OrderExporter]: [
    '/ag/orders',
    // '/ag/order-items'
  ],
}
