
import { defineComponent, reactive, ref } from 'vue'
import { createHandler } from '@/services/createHandler'
import { emailRule, stringRules } from '@/utils/formRules'
import { useNotification } from '@/composables'
// import { Plus } from '@element-plus/icons'

export default defineComponent({
  setup(props: any, context: any) {
    const dialogVisible = ref(false)
    let loading = ref(false)
    const userForm = ref()

    const state = reactive({
      form: {
        fullName: '',
        email: '',
        status: false,
        password: ''
      },
    })

    const formRules = {
      fullName: stringRules('username', 2, 128),
      email: emailRule,
      password: stringRules('password', 6, 128),
    }
    const { error, success } = useNotification()
    const onSubmit = () => {
      const resource = `users`
      userForm.value.validate(async (valid: any) => {
        if (valid) {
          const { create, isLoading } = createHandler(resource, state.form)
          loading = isLoading
          const { status, data } = await create()
          if (status === 201) {
            dialogVisible.value = !dialogVisible.value
            context.emit('change')
            success('Created')
          } else {
            error(data.message)
          }
        }
      })
    }

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const closeHandle = () => {
      console.log('create close');
      userForm.value?.resetFields()
      dialogVisible.value = false
    }

    return {
      userForm,
      state,
      formRules,
      loading,
      onSubmit,
      toggle,
      dialogVisible,
      closeHandle,
    }
  },
})
