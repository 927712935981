export enum ORDER_STATUS {
  UNFULFILLED = 'Unfulfilled',
  IN_REVIEW = 'InReview',
  APPROVED = 'Approved',
  HOLD = 'Hold',
  REJECTED = 'Rejected',
  CANCEL = 'Cancel',
  IN_PRODUCTION = 'InProduction',
  IN_TRANSIT = 'InTransit',
  FULFILLED = 'Fulfilled',
  PARTIALLY_FULFILLED = 'PartiallyFulfilled',
  CANCEL_SHIPMENT = 'CancelShipment',
  // PICKUP = 'Pickup',
}

export enum HIRE_DESIGN_STATUS {
  ASSIGN = 'assign',
  INREVIEW = 'in_review',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export const MapHireDesignStatus = new Map<string, any>([
  [
    HIRE_DESIGN_STATUS.ASSIGN,
    { type: 'default', title: 'Assign', value: HIRE_DESIGN_STATUS.ASSIGN },
  ],
  [
    HIRE_DESIGN_STATUS.INREVIEW,
    { type: 'warning', title: 'In Review', value: HIRE_DESIGN_STATUS.INREVIEW },
  ],
  [
    HIRE_DESIGN_STATUS.APPROVED,
    { type: 'success', title: 'Approved', value: HIRE_DESIGN_STATUS.APPROVED },
  ],
  [
    HIRE_DESIGN_STATUS.REJECTED,
    { type: 'danger', title: 'Rejected', value: HIRE_DESIGN_STATUS.REJECTED },
  ],
])

export enum ADDRESS_STATUS {
  US_INVALID = 0, // US Invalid
  US_VALID = 1, // US Valid
  VERIFIED = 2, // Verified
  CHECKING = 3, // Checking
}

export enum DESIGN_STATUS {
  NONE = 0, // None
  MAPPED_UN_UPLOADED = 1, // MappedUnUploaded
  MAPPED_UPLOADED = 2, // MappedUploaded
}

export const addressTitleMap = new Map<number, string>([
  [ADDRESS_STATUS.US_INVALID, 'Invalid'],
  [ADDRESS_STATUS.US_VALID, 'Valid'],
  [ADDRESS_STATUS.VERIFIED, 'Verified'],
  [ADDRESS_STATUS.CHECKING, 'Checking'],
])

export const statusTitleMap = new Map<string, string>([
  [ORDER_STATUS.UNFULFILLED, 'Unfulfilled'],
  [ORDER_STATUS.IN_REVIEW, 'In Review'],
  [ORDER_STATUS.APPROVED, 'Approved'],
  [ORDER_STATUS.HOLD, 'Hold'],
  [ORDER_STATUS.REJECTED, 'Rejected'],
  [ORDER_STATUS.CANCEL, 'Cancel'],
  [ORDER_STATUS.IN_PRODUCTION, 'In Production'],
  [ORDER_STATUS.IN_TRANSIT, 'In Transit'],
  [ORDER_STATUS.FULFILLED, 'Fulfilled'],
  [ORDER_STATUS.PARTIALLY_FULFILLED, 'Partially Fulfilled'],
  [ORDER_STATUS.CANCEL_SHIPMENT, 'Cancel Shipment'],
  // [ORDER_STATUS.PICKUP, 'Pickup'],
])

export const statusListSeller = [
  { label: 'Unfulfilled', value: ORDER_STATUS.UNFULFILLED },
  { label: 'Hold', value: ORDER_STATUS.HOLD },
  { label: 'In Review', value: ORDER_STATUS.IN_REVIEW },
  { label: 'Approved', value: ORDER_STATUS.APPROVED },
  { label: 'Rejected', value: ORDER_STATUS.REJECTED },
  { label: 'Cancel', value: ORDER_STATUS.CANCEL },
  { label: 'In Production', value: ORDER_STATUS.IN_PRODUCTION },
  { label: 'In Transit', value: ORDER_STATUS.IN_TRANSIT },
  { label: 'Fulfilled', value: ORDER_STATUS.FULFILLED },
  { label: 'Partially Fulfilled', value: ORDER_STATUS.PARTIALLY_FULFILLED },
  { label: 'Cancel Shipment', value: ORDER_STATUS.CANCEL_SHIPMENT },
  // { label: 'Pickup', value: ORDER_STATUS.PICKUP },
]

export const statusListAgency = [
  { label: 'Unfulfilled', value: ORDER_STATUS.UNFULFILLED },
  { label: 'Hold', value: ORDER_STATUS.HOLD },
  { label: 'In Review', value: ORDER_STATUS.IN_REVIEW },
  { label: 'Approved', value: ORDER_STATUS.APPROVED },
  { label: 'Cancel', value: ORDER_STATUS.CANCEL },
  { label: 'In Production', value: ORDER_STATUS.IN_PRODUCTION },
  { label: 'Rejected', value: ORDER_STATUS.REJECTED },
  { label: 'In Transit', value: ORDER_STATUS.IN_TRANSIT },
  { label: 'Fulfilled', value: ORDER_STATUS.FULFILLED },
  { label: 'Partially Fulfilled', value: ORDER_STATUS.PARTIALLY_FULFILLED },
  { label: 'Cancel Shipment', value: ORDER_STATUS.CANCEL_SHIPMENT },
  // { label: 'Pickup', value: ORDER_STATUS.PICKUP },
]

export const hireDesignStatus = [
  { label: 'Assign', value: HIRE_DESIGN_STATUS.ASSIGN },
  { label: 'In Review', value: HIRE_DESIGN_STATUS.INREVIEW },
  { label: 'Approved', value: HIRE_DESIGN_STATUS.APPROVED },
  { label: 'Rejected', value: HIRE_DESIGN_STATUS.REJECTED },
]

export const designServiceTitleMap = new Map<string, string>([
  [HIRE_DESIGN_STATUS.ASSIGN, 'Assign'],
  [HIRE_DESIGN_STATUS.INREVIEW, 'In Review'],
  [HIRE_DESIGN_STATUS.APPROVED, 'Approved'],
  [HIRE_DESIGN_STATUS.REJECTED, 'Rejected'],
])

export const requestDesignServiceTitleMap = new Map<string, string>([
  [HIRE_DESIGN_STATUS.ASSIGN, 'Requested'],
  [HIRE_DESIGN_STATUS.INREVIEW, 'Requested'],
  [HIRE_DESIGN_STATUS.APPROVED, 'Requested'],
  [HIRE_DESIGN_STATUS.REJECTED, 'Rejected'],
])
// standard, express, overnight
export const SHIPPING_METHOD_DROPSHIP = [
  { label: 'Standard', value: 'standard' },
  { label: 'Express', value: 'express' },
  { label: 'Overnight', value: 'overnight' },
]

export const PAYOUT_REQUEST = {
  ARRAY_SEARCH: [
    { label: 'Pending', value: 'pending' },
    { label: 'Approved', value: 'approved' },
    { label: 'Canceled', value: 'canceled' },
  ],
  STATUS: {
    PENDING: 'pending',
    APPROVED: 'approved',
    CANCEL: 'canceled',
  },
  MAP_STATUS_TITLE: new Map<string, string>([
    ['pending', 'Pending'],
    ['approved', 'Approved'],
    ['canceled', 'Canceled'],
  ]),
}

export enum UserTypeEnum {
  Agency = 'agency',
  Seller = 'seller',
  Administrator = 'administrator',
  Supplier = 'supplier',
}

export const RequestSeller = {
  REASONS: [
    { value: 'reupload_design', label: 'Reupload Design' },
    { value: 'change_catalog_code', label: 'Change catalog code' },
    { value: 'others', label: 'Others' },
  ],
}

export enum RequestSellerStatusEnum {
  requested = 'requested',
  updated = 'updated',
}

export const WEIGHT_UNIT = ['lb', 'oz', 'kg', 'g']

export const ARTWORK_SPACES = [
  'artwork1',
  'artwork2',
  'artwork3',
  'artwork4',
  'artwork5',
  'artwork6',
]

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
