
import { defineComponent, ref } from 'vue'
import { deleteHandler } from '@/services/deleteHandler'
import Update from './Update.vue'
import MapUser from './MapUser.vue'
import { Edit, SetUp, Delete, Refresh } from '@element-plus/icons'
import { ElMessageBox } from 'element-plus'
import { formatterDollars } from '@/utils/formatter'
import { formatDateTime } from '@/utils/dateTime'
import ResetPasswordDialog from './ResetPasswordDialog.vue'
import { useNotification } from '@/composables'

export default defineComponent({
  components: {
    Update,
    MapUser,
    Edit,
    SetUp,
    Delete,
    Refresh,
    ResetPasswordDialog,
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    mapProfits: {
      type: Map,
    },
  },
  setup(props: any, context: any) {
    let loading = ref(props.isLoading)
    const baseResource = 'seller-teams'

    const handleDelete = async (id: string) => {
      ElMessageBox.confirm('Are you sure to delete this account?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'error',
      })
        .then(async () => {
          const { deleteById, isLoading } = deleteHandler(baseResource)
          loading = isLoading

          await deleteById(id)
        })
        .catch(() => {
          // catch error
        })
    }
    const forceUpdate = async () => {
      context.emit('updateFinish')
    }

    const forceMapUser = async () => {
      context.emit('mapFinish')
    }

    const sellerUpdateDialog = ref<InstanceType<typeof Update>>()
    const mapUserDialog = ref<InstanceType<typeof MapUser>>()
    let selected = ref({})
    let selectedRow = ref({})

    const openUpdateDialog = (row: any) => {
      selected.value = row
      sellerUpdateDialog.value?.toggle(row.id)
    }

    const openMapUserDialog = (row: any) => {
      selectedRow.value = row
      mapUserDialog.value?.toggle(row.id)
    }

    const { error } = useNotification()
    const resetPasswordDialog = ref<InstanceType<typeof ResetPasswordDialog>>()
    const openResetPasswordDialog = (row: any) => {
      const userId = row?.manager?.id
      if (!userId) {
        error()
      }
      resetPasswordDialog.value?.toggle(userId)
    }

    return {
      loading,
      handleDelete,
      forceUpdate,
      forceMapUser,
      sellerUpdateDialog,
      mapUserDialog,
      openUpdateDialog,
      openMapUserDialog,
      formatterDollars,
      formatDateTime,
      openResetPasswordDialog,
      resetPasswordDialog,
    }
  },
})
