<template>
  <div class="w-full">
    <h3 class="text-primary">
      {{ props.product.title }}
    </h3>
    <p class="mt-4 text-red-600 text-3xl">
      ${{ displayPrice }}
      <span
        v-if="
          props.product.variants.length &&
          checkIncludingShipping(product.variants[0].shipUsFirst)
        "
        class="text-black text-lg"
      >
        / including shipping
      </span>
    </p>
    <el-divider />
    <div class="flex items-center mb-6 flex-wrap gap-y-2">
      <div
        v-for="(item, index) in props.options"
        :key="index"
        class="mb-4 flex items-center flex-wrap gap-y-2"
      >
        <p class="text-black font-heading font-bold uppercase mr-[8px] mb-2">
          {{ item.name }} : &nbsp;
        </p>
        <span
          v-for="(value, i) in item.values"
          :key="i"
          class="
            cursor-pointer
            font-medium
            text-sm text-heading
            leading-6
            py-1
            px-2.5
            rounded
            border
            mr-2
            capitalize
          "
          :class="{
            'border-primary bg-primary text-white':
              value == chosenItem[index][0],
          }"
          @click="setChosenItemDetail(index, value)"
        >
          {{ value }}
          <!--          specical case: if delete, css of active item will not work-->
          <p class="hidden">{{ forActive }}</p>
        </span>
      </div>
    </div>
    <el-divider />
    <p class="text-black font-heading font-bold uppercase mr-[8px]">
      Description: &nbsp;
    </p>
    <p v-html="props.product.description"></p>
  </div>
</template>
<script>
import { ref, watch } from 'vue'

export default {
  props: {
    product: {
      type: Object,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const chosenItem = [[''], [''], ['']]
    const forActive = ref('')

    const displayPrice = ref(0)
    const setDisplayPrice = () => {
      props.product?.variants.map(item => {
        if (
          item.option1 === chosenItem[0][0] &&
          (!item?.option2 || item.option2 === chosenItem[1][0]) &&
          (!item?.option3 || item.option3 === chosenItem[2][0])
        ) {
          displayPrice.value = item.price || 0
        }
        return item
      })
    }
    const setChosenItemDetail = (index, value) => {
      chosenItem[index][0] = value
      forActive.value = `[${index}]:${value}`
    }
    watch(
      () => forActive.value,
      () => {
        setDisplayPrice()
      }
    )

    const checkIncludingShipping = value => {
      if (value) return false
      return true
    }

    return {
      props,
      chosenItem,
      forActive,
      setChosenItemDetail,
      displayPrice,
      checkIncludingShipping,
    }
  },
}
</script>
