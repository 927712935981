export const APP_IMAGE_URL = process.env.VUE_APP_IMAGE_URL
export const APP_RESIZE_IMAGE_URL = process.env.VUE_APP_IMAGE_URL?.replace(
  'static',
  'resize'
)

const APP_IMAGE_URL_BAMBOOZ = process.env.VUE_APP_IMAGE_URL_BAMBOOZ
const APP_RESIZE_IMAGE_URL_BAMBOOZ = process.env.VUE_APP_IMAGE_URL_BAMBOOZ?.replace(
  'static',
  'resize'
)

export const imageUrl = (
  url: string,
  resize?: boolean,
  width?: number,
  height?: number
) => {
  // kiểm tra url hợp lệ hay không
  if (url && url.trim() !== '') {
    url = url.trim()
    // nếu truyền vào full link thì remove URL, chỉ lấy path
    const appUrl = process.env.VUE_APP_IMAGE_URL ? process.env.VUE_APP_IMAGE_URL : ''
    if (appUrl !== '' && url.includes(appUrl)) {
      url = url.replace(appUrl, '')
    }
    // check có phải là link của latte print hay không
    if (checkImageLinkLattePrint(url)) {
      let baseURL = APP_IMAGE_URL
      // check bambooz host => switch image base url
      if(window.location.host === process.env.VUE_APP_BAMBOOZ_FULFILL_SITE)
        baseURL = APP_IMAGE_URL_BAMBOOZ
      // check resize hay không?
      if (resize === true) {
        baseURL = APP_RESIZE_IMAGE_URL
        if(window.location.host === process.env.VUE_APP_BAMBOOZ_FULFILL_SITE)
          baseURL = APP_RESIZE_IMAGE_URL_BAMBOOZ
        // nếu truyển vào width và height
        if (width && width > 0) {
          if (!height || height <= 0) {
            baseURL = `${baseURL}img/${width}/resize/`
          } else {
            baseURL = `${baseURL}img/${width}/${height}/resize/`
          }
        } else {
          // ảnh resize với dung lượng nhỏ hơn
          baseURL = `${baseURL}img/0/0/resize/`
        }
      }
      return baseURL + url
    }
  }
  return url
}

export const checkImageLinkLattePrint = (url: string) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/
  if (url && url.trim() !== '' && !url.startsWith('http')) {
    url = url.trim()
    if (url.length > 10) {
      const checkStr = url.slice(0, 10)
      if (checkStr.match(regex) !== null) {
        return true
      }
    }
  }
  return false
}

export const imageFullUrl = (url: string) => {
  if (url && url.trim() !== '') {
    if (!url.startsWith('http')) {
      return process.env.VUE_APP_IMAGE_URL + url
    }
  }
  return url
}

export const getFullUrlLattePrint = (url: string) => {
  if (url && !url.startsWith('http')) {
    return process.env.VUE_APP_IMAGE_URL + url
  }
}
