
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import UserTable from './components/UserTable.vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import FilterTable from '@/components/common/FilterTable.vue'
import Pagination from '@/components/common/Pagination.vue'
import Create from '@/pages/seller/users/components/Create.vue'

export default defineComponent({
  components: {
    UserTable,
    FilterTable,
    sectionLayoutContent,
    Create,
    Pagination,
  },
  setup() {
    const breadcrumbs = ['Users', '']
    const title = 'Users'
    let loading = ref(false)
    // const baseResource = 'http://localhost:3001/api/users'
    const baseResource = 'users'
    const titleCreate = 'Create User'
    const params = reactive({ page: 1, limit: 10, search: null })

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )
    loading = isLoading
    fetchData()

    const onFilterCallback = (data: any) => {
      params.search = data?.search
    }

    watch(params, fetchData)

    const content = computed(() => {
      return { breadcrumbs, title, titleCreate }
    })

    const createUserDialog = ref<InstanceType<typeof Create>>()
    const create = () => {
      console.log(createUserDialog)
      createUserDialog.value?.toggle()
    }

    const forceUpdate = async () => {
      loading.value = true
      await fetchData()
      loading.value = false
    }

    return {
      params,
      content,
      loading,
      documents,
      createUserDialog,
      forceUpdate,
      create,
      total,
      onFilterCallback,
    }
  },
})
