
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import Editor from '@/pages/admin/catalog/components/Editor.vue'
import axios from '@/services/axios'
import { useNotification } from '@/composables'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Editor,
  },
  name: 'Payment Setting',
  setup() {
    const { error, success } = useNotification()
    const breadcrumbs = [
      {
        text: 'Settings',
        link: 'ag.settings.board',
        params: null,
      },
      {
        text: 'Payment Setting',
        link: '',
        params: null,
      },
    ]
    const content = computed(() => {
      return { breadcrumbs }
    })
    const updateSettingResource = '/agencies/update-settings'
    const route = useRoute()
    const isLoading = ref(true)

    const state = reactive({
      isLoading: true,
      isLoadingAction: false,
      agency: {
        settings: {
          payment: {
            exchangeRate: 1,
            topupContent: '',
          },
        },
      },
      topupContent: '',
      exchangeRate: 1,
    })

    const getAgencyInfo = async () => {
      const url = `/agencies/info`
      const res = await axios.get(url)
      if (res.status === 200 || res.status === 201) {
        state.agency = res.data as any
        if (res.data.settings?.payment) {
          state.exchangeRate = res.data.settings?.payment?.exchangeRate
          state.topupContent = res.data.settings?.payment?.topupContent
        }
      }
      isLoading.value = false
    }

    onMounted(() => {
      getAgencyInfo()
    })

    const changePayment = (html: string, dataSet: any) => {
      state.topupContent = html
    }

    const onSave = async () => {
      state.isLoadingAction = true

      const formData = {
        settings: {
          ...state.agency.settings,
          payment: {
            exchangeRate: state.exchangeRate,
            topupContent: state.topupContent,
          },
        },
      }

      const res = await axios.patch(updateSettingResource, formData)
      if (res.status === 200 || res.status === 201) {
        success('Update success!')
      } else {
        error('Error')
      }

      state.isLoadingAction = false
    }

    return {
      content,
      state,
      isLoading,
      changePayment,
      route,
      onSave,
      getAgencyInfo,
    }
  },
})
