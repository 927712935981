
import { defineComponent, computed } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useRouter } from 'vue-router'
import {
  MostlyCloudy,
  Ticket,
  Message,
  BrushFilled,
  Van,
  HomeFilled,
  Tickets,
  Menu,
} from '@element-plus/icons'

export default defineComponent({
  components: {
    sectionLayoutContent,
    MostlyCloudy,
    Ticket,
    Message,
    BrushFilled,
    Van,
    HomeFilled,
    Tickets,
    Menu,
  },
  setup() {
    const breadcrumbs = [
      {
        text: 'Settings',
        link: '',
        params: null,
      },
      {
        text: '',
        link: '',
        params: null,
      },
    ]
    const title = 'Settings'
    const content = computed(() => {
      return { breadcrumbs, title }
    })
    const router = useRouter()
    const toRoute = (route: string) => {
      router.push({
        path: route,
      })
    }

    return {
      toRoute,
      content,
    }
  },
})
