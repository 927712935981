<template>
  <el-row
    class="h-full w-full flex justify-center bg-fixed-bottom"
    :style="
      'background-image: url(' +
      require('@/assets/img/illustrations/sticky.svg') +
      ');'
    "
  >
    <el-col :span="24">
      <el-row class="text-center">
        <el-col :span="24">
          <el-image
            style="width: 100px; height: 100px"
            :fit="fit"
            :src="imageUrl(url, true, 200)"
          ></el-image>
        </el-col>
        <el-col :span="24">
          <h1 class="font-bold mt-10">Thank you for subcribing!</h1>
        </el-col>
        <el-col :span="24">
          <h4 class="mt-5">
            We will send you text message promotions and update.
          </h4>
        </el-col>
        <el-col :span="24" class="mt-10">
          <router-link :to="{ path: `/auth/login` }" v-slot="{ href }">
            <el-link :href="href">
              <el-button
                style="
                  font-weight: bold;
                  color: white;
                  background-color: #2196f3;
                  border: none;
                "
              >
                Sign in
              </el-button>
            </el-link>
          </router-link>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import { imageUrl } from '@/utils/image'

export default {
  setup() {
    const url = `${process.env.VUE_APP_IMAGE_URL}2022-05-12/1652322249864_lattehub-print-image-627c6fc73333333333333333.png`
    return { imageUrl, url }
  },
}
</script>
<style scoped>
.bg-fixed-bottom {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
  background-attachment: fixed;
  padding-top: 10%;
}
</style>
