<template>
  <section-layout-content v-bind="content">
    <div v-if="!state.isLoading" class="font-bold">
      <div style="display: flex; justify-content: space-between">
        Config : Theme
        <div>
          <el-button type="primary" @click="submit">
            <i class="fas fa-save"></i>
            &nbsp;Save
          </el-button>
          <el-button @click="reset">
            <i class="fas fa-redo"></i>
            &nbsp;Reset
          </el-button>
        </div>
      </div>
      <div v-for="(i, k) in configTheme" :key="k" class="center mt-2">
        <span
          v-if="
            k !== 'logo' &&
            k !== 'faviconImg' &&
            k !== 'title' &&
            k !== 'templateFile'
          "
          class="inline-block w-1/5"
        >
          {{ k }} :
        </span>
        <el-color-picker
          v-if="
            k !== 'faviconImg' &&
            k !== 'logo' &&
            k !== 'title' &&
            k !== 'templateFile'
          "
          v-model="configTheme[k]"
        />
        <div v-if="k === 'logo'" class="flex">
          <el-col :span="12" style="max-width: 300px">
            <upload-image
              :multiple="false"
              :title="`Upload Logo`"
              :setData="configTheme.logo"
              :data="configTheme.logo"
              @callback="callbackImage"
            />
          </el-col>
        </div>
        <div v-if="k === 'faviconImg'" class="flex">
          <el-col :span="12" style="max-width: 300px">
            <upload-image
              :multiple="false"
              :title="`Upload favicon`"
              :setData="configTheme.faviconImg"
              :data="configTheme.faviconImg"
              @callback="callbackFaviconImg"
            />
          </el-col>
        </div>
        <div class="mt-3 center" v-if="k === 'title'">
          <span class="inline-block w-1/5">Title :</span>
          <el-input v-model="configTheme.title" />
        </div>
        <!-- <img v-else width="250" :src="configTheme[k]" /> -->
      </div>
    </div>
  </section-layout-content>
</template>

<script>
import { computed, reactive } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useServiceTheme } from '@/services'
import UploadImage from '@/components/common/UploadImage.vue'
import { useNotification } from '@/composables'
import useThemeRepositories from '@/repositories/useThemeRepositories'

export default {
  components: { sectionLayoutContent, UploadImage },
  setup() {
    const breadcrumbs = [
      {
        text: 'Settings',
        link: 'ag.settings.board',
        params: null,
      },
      {
        text: 'Theme',
        link: '',
        params: null,
      },
    ]
    const title = 'Title'

    const { error, success } = useNotification()

    const { add } = useServiceTheme()
    const { getTheme, configTheme, state, setVarConfig, reset } =
      useThemeRepositories()

    getTheme()
    // onMounted(async () => {
    //   await getTheme()
    // })

    const submit = async () => {
      try {
        let res = await add({ config: configTheme })
        // setVarConfig()
        success()
      } catch (error) {
        //
      }
      //
    }
    const callbackImage = (setData, files) => {
      configTheme.logo = files[0]
      console.log(`files`, files)
      // console.log(`configTheme`, configTheme, setData, files)
    }
    const callbackFaviconImg = (setData, files) => {
      configTheme.faviconImg = files[0]
      console.log(`files`, files)
      // console.log(`configTheme`, configTheme, setData, files)
    }
    const content = computed(() => {
      return { breadcrumbs, title }
    })

    return {
      content,
      configTheme,
      callbackImage,
      callbackFaviconImg,
      submit,
      state,
      reset,
    }
  },
}
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  // justify-content: center;
  align-items: center;
}
</style>
