
import { defineComponent, reactive, ref } from 'vue'
// import { ElMessageBox } from 'element-plus'
import { createHandler } from '@/services/createHandler'
import { useNotification } from '@/composables'
import { required } from '@/utils/formRules'

// import { Plus } from '@element-plus/icons'

export default defineComponent({
  setup() {
    let dialogVisible = ref(false)
    let loading = ref(false)

    const state = reactive({
      form: {
        host: '',
      },
    })
    const form = ref()
    const formRules = {
      host: required('Domain'),
    }
    const { error, success } = useNotification()

    const onSubmit = async () => {
      const resource = `domains`
      const { create, isLoading } = createHandler(resource, state.form)
      loading = isLoading
      const { data, status } = await create()
      if (status === 200 || status === 201) {
        dialogVisible.value = false
        success('Connected')
      } else {
        error(data.message)
      }
    }

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const close = () => {
      dialogVisible.value = false
      state.form.host = ''
    }

    return {
      state,
      formRules,
      onSubmit,
      form,
      dialogVisible,
      loading,
      toggle,
      close,
    }
  },
})
