const baseRoutes = ['login', 'ag.users.profile']
export const staffBamboozRoutes = [
  ...baseRoutes,
  's.catalogs',
  's.catalog-detail',
  'agency.category-catalog',
  'agency.baseCost',
  'agency.baseCost.create',
  'agency.baseCost.update',
  'ag.tracking',
  'ag.tracking.detail',
  'ag.sellers',
  'ag.sellers.detail',
  'ag.base-cost.catalog-update',
  'ag.catalog-create',
  'ag.catalog-create.dropship',
  'ag.base-cost.catalog-update-cost',
]
export const customerSupportBamboozRoutes = [
  ...baseRoutes,
  's.catalogs',
  's.catalog-detail',
  'agency.orders',
  'agency.orders.detail',
  'agency.orders.refund',
  'agency.order-items',
  'ag.tracking',
  'ag.tracking.detail',
  'agency.customers',
  'agency.stores',
]
export const accountingBamboozRoutes = [
  ...baseRoutes,
  'agency.payouts',
  'agency.invoices',
  'agency.deposits',
  'agency.proxy-groups',
  'agency.stores',
  'agency.customers',
  'agency.orders',
  'agency.orders.detail',
  'agency.orders.refund',
  'agency.order-items',
  'ag.sellers',
  'ag.sellers.detail',
]
export const designerBamboozRoutes = [...baseRoutes, 'seller.image-library']
