<template>
  <el-main class="h-full">
    <el-button type="text" :icon="ArrowLeft" @click="backPage" size="small" v-if="backPageTitle" title="Back">
      {{ backPageTitle }}
    </el-button>
    <div class="flex items-center justify-between">
      <el-breadcrumb style="margin: 16px 0" separator="/">
        <el-breadcrumb-item v-for="(breadcrumb, index) in convertBreadcrumbs" :key="index">
          <router-link v-if="breadcrumb.link" :to="{
            name: breadcrumb.link,
            params: breadcrumb.params ? breadcrumb.params : null,
          }">
            {{ breadcrumb.text }}
          </router-link>
          <span v-else>
            {{ breadcrumb.text }}
          </span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="mb-2">
        <el-button :loading="loadingReport" type="primary" v-if="titleReport" @click="emit(`actionReport`)">
          <i v-if="iconReport" style="font-size: 12px; margin-right: 5px" :class="iconReport"></i>
          {{ titleReport }}
        </el-button>
        <el-button :loading="loadingExport" type="primary" v-if="titleExport" @click="emit(`actionExport`)">
          <i v-if="iconExport" style="font-size: 12px; margin-right: 5px" :class="iconExport"></i>
          {{ titleExport }}
        </el-button>
        <el-button :loading="loadingImport" type="primary" v-if="titleImport" @click="emit(`actionImport`)">
          <i v-if="iconImport" style="font-size: 12px; margin-right: 5px" :class="iconImport"></i>
          {{ titleImport }}
        </el-button>
        <el-button :loading="loadingCreate" type="primary" v-if="titleCreate" @click="emit(`actionCreate`)">
          <i v-if="iconCreate" style="font-size: 12px; margin-right: 5px" :class="iconCreate"></i>
          {{ titleCreate }}
        </el-button>
        <el-button :loading="loadingOtherButton1" type="primary" v-if="titleOtherButton1"
          @click="emit(`actionOtherButton1`)">
          <i v-if="iconOtherButton1" style="font-size: 12px; margin-right: 5px" :class="iconOtherButton1"></i>
          {{ titleOtherButton1 }}
        </el-button>
      </div>
    </div>
    <!-- {{ title }} -->
    <el-card class="store-page-wrapper" style="height: calc(100% - 50px)">
      <slot></slot>
    </el-card>
  </el-main>
</template>

<script>
import { useRouter } from 'vue-router'
import { ArrowLeft } from '@element-plus/icons'
import { } from 'vue-demi'

export default {
  name: 'SectionLayoutContent',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    titleCreate: {
      type: String,
      default: '',
    },
    titleExport: {
      type: String,
      default: '',
    },
    titleImport: {
      type: String,
      default: '',
    },
    titleOtherButton1: {
      type: String,
      default: '',
    },
    titleReport: {
      type: String,
      default: '',
    },
    loadingCreate: {
      type: Boolean,
      default: false,
    },
    loadingExport: {
      type: Boolean,
      default: false,
    },
    loadingImport: {
      type: Boolean,
      default: false,
    },
    loadingOtherButton1: {
      type: Boolean,
      default: false,
    },
    loadingReport: {
      type: Boolean,
      default: false,
    },
    backPageTitle: {
      type: String,
      default: '',
    },
    backPageRouter: {
      type: String,
      default: '',
    },
    iconImport: {
      type: String,
      // default: 'fas fa-cloud-download-alt',
      default: 'fas fa-cloud-upload-alt',
    },
    iconExport: {
      type: String,
      default: 'fas fa-file-excel',
    },
    iconCreate: {
      type: String,
      default: 'fas fa-plus',
    },
    iconOtherButton1: {
      type: String,
      default: 'fas fa-file-excel',
    },
    iconReport: {
      type: String,
      default: 'fas fa-file-excel',
    },
  },
  setup(props, { emit }) {
    const { go, push } = useRouter()
    const backPage = () => {
      if (props.backPageRouter) {
        push({ name: props.backPageRouter })
      } else {
        go(-1)
      }
    }
    // console.log(
    //   props.breadcrumbs,
    //   props.breadcrumbs.length > 0 && typeof props.breadcrumbs[0] === 'object'
    // )
    let convertBreadcrumbs = []
    if (
      props.breadcrumbs.length > 0 &&
      typeof props.breadcrumbs[0] === 'string'
    ) {
      convertBreadcrumbs = props.breadcrumbs.map(item => {
        return {
          text: item,
          link: '',
          params: null,
        }
      })
    }
    if (
      props.breadcrumbs.length > 0 &&
      typeof props.breadcrumbs[0] === 'object'
    ) {
      convertBreadcrumbs = props.breadcrumbs.map(item => {
        return {
          ...item,
        }
      })
    }
    return { props, emit, backPage, ArrowLeft, convertBreadcrumbs }
  },
}
</script>

<style lang="scss" scoped></style>
