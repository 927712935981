import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["accept", "multiple"]
const _hoisted_3 = {
  key: 0,
  class: "absolute flex justify-center items-center inset-0 bg-green-300 bg-opacity-20 m-2"
}
const _hoisted_4 = { class: "flex justify-center items-center text-primary" }
const _hoisted_5 = {
  key: 0,
  class: ""
}
const _hoisted_6 = {
  key: 1,
  class: ""
}
const _hoisted_7 = {
  key: 0,
  class: ""
}
const _hoisted_8 = {
  key: 1,
  class: ""
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_View = _resolveComponent("View")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_PreviewImage = _resolveComponent("PreviewImage")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createBlock(_component_el_scrollbar, { "max-height": "400px" }, {
    default: _withCtx(() => [
      (_ctx.permission === 'all')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, [
              (_ctx.title)
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    type: "text",
                    onClick: _ctx.upload
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, {
                        class: "el-icon--left",
                        size: 16
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Plus)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createElementVNode("input", {
                type: "file",
                accept: _ctx.accept,
                class: "hidden",
                ref: "inputFile",
                multiple: _ctx.multiple,
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChangeFiles && _ctx.onChangeFiles(...args)))
              }, null, 40, _hoisted_2)
            ]),
            (_ctx.files && _ctx.files.length > 0)
              ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (img, index) => {
                      return (_openBlock(), _createBlock(_component_el_col, {
                        md: _ctx.multiple ? 8 : 24,
                        xs: 24,
                        class: "p-2 cursor-pointer relative",
                        onMouseenter: ($event: any) => (_ctx.onMouse(index)),
                        onMouseleave: _ctx.onMouseMove,
                        key: index
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_image, {
                            style: {"border-radius":"3%"},
                            src: _ctx.imageUrl(img, true, 300),
                            lazy: ""
                          }, null, 8, ["src"]),
                          (index === _ctx.hoverIndex)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createElementVNode("div", _hoisted_4, [
                                  _createVNode(_component_el_button, {
                                    onClick: ($event: any) => (_ctx.onClickPreviewImage(img)),
                                    size: "small",
                                    circle: "",
                                    title: "Preview"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_icon, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_View)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]),
                                  _createVNode(_component_el_button, {
                                    onClick: ($event: any) => (_ctx.delImg(index)),
                                    type: "danger",
                                    size: "small",
                                    circle: "",
                                    title: "Delete"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_icon, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Delete)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["md", "onMouseenter", "onMouseleave"]))
                    }), 128)),
                    (_ctx.multiple)
                      ? (_openBlock(), _createBlock(_component_el_col, {
                          key: 0,
                          md: _ctx.multiple ? 8 : 24,
                          xs: 24,
                          class: "cursor-pointer p-2",
                          onClick: _ctx.upload
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_card, {
                              shadow: "never",
                              class: "flex justify-center items-center h-full p-2 border-dashed border"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_button, {
                                  class: "flex justify-center items-center",
                                  type: "text"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_icon, {
                                      class: "el-icon--left",
                                      size: 16
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_Plus)
                                      ]),
                                      _: 1
                                    }),
                                    (!_ctx.content)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.content), 1))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["md", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_el_row, {
                  key: 1,
                  class: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 24,
                      class: "border-dashed border cursor-pointer",
                      onClick: _ctx.upload
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_card, {
                          shadow: "never",
                          class: "flex justify-center items-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              class: "flex justify-center items-center",
                              type: "text"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_icon, {
                                  class: "el-icon--left",
                                  size: 16
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_Plus)
                                  ]),
                                  _: 1
                                }),
                                (!_ctx.content)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.content), 1))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
          ]))
        : _createCommentVNode("", true),
      (_ctx.permission === 'read')
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_ctx.files && _ctx.files.length > 0)
              ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (img, index) => {
                      return (_openBlock(), _createBlock(_component_el_col, {
                        md: _ctx.multiple ? 8 : 24,
                        xs: 24,
                        class: "p-2 cursor-pointer relative",
                        onMouseenter: ($event: any) => (_ctx.onMouse(index)),
                        onMouseleave: _ctx.onMouseMove,
                        key: index
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_image, {
                            style: {"border-radius":"3%"},
                            class: "border",
                            title: "Click to preview",
                            "preview-src-list": [_ctx.imageUrl(img)],
                            src: _ctx.imageUrl(img, true, 300)
                          }, null, 8, ["preview-src-list", "src"])
                        ]),
                        _: 2
                      }, 1032, ["md", "onMouseenter", "onMouseleave"]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_PreviewImage, { ref: "previewImageDialog" }, null, 512)
    ]),
    _: 1
  }))
}