<template>
  <el-aside width="250px">
    <el-menu
      class="h-full el-menu-vertical-demo menu-sider"
      default-active="1"
      :collapse="isCollapse"
      @open="handleOpen"
      @close="handleClose"
    >
      <template v-for="(i, k) in menus" :key="k">
        <el-menu-item
          :index="`${k}`"
          :key="k"
          v-if="!i.childrens"
          class="gap-3 text-foreground"
          style="
            height: 40px;
            line-height: 40px;
            color: var(--el-text-color-regular);
          "
          :class="menuActive === i.path ? 'bg-gray-100 text-primary' : ''"
          @click=";(menuActive = i.path), $router.push(i.path)"
        >
          <el-icon>
            <i :class="i.icon"></i>
          </el-icon>
          <span>{{ i.name }}</span>
        </el-menu-item>
        <el-sub-menu v-else>
          <template #title>
            <el-icon>
              <i :class="`${i.icon}`"></i>
            </el-icon>
            <span>{{ i.name }}</span>
          </template>
          <el-menu-item
            :index="`${k}`"
            v-for="(child, c) in i.childrens"
            :key="c + k"
            :class="menuActive === child.path ? 'bg-blue-100 text-primary' : ''"
            class="text-foreground"
            style="
              height: 40px;
              line-height: 40px;
              color: var(--el-text-color-regular);
            "
            @click=";(menuActive = child.path), $router.push(child.path)"
          >
            {{ child.name }}
          </el-menu-item>
        </el-sub-menu>
      </template>
    </el-menu>
  </el-aside>
</template>

<script>
import { MENU } from '@/config/menu'
import { computed, ref } from 'vue'
import store from '@/store'
import { SellerRoleMenu, UserTypeEnum, AgencyRoleMenu } from '@/utils/userUtils'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup() {
    const isCollapse = ref(false)
    const menuActive = ref(``)
    let loadMenu = MENU[localStorage.getItem(`type`)]

    const user = computed(() => store.getters['user/getUser'])
    localStorage.setItem(
      'isOrderExporter',
      user.value?.authority === 'order_exporter'
    )

    if (loadMenu) {
      if (
        user.value?.userType === 'agency' &&
        user.value?.authority === 'staff'
      ) {
        loadMenu = loadMenu.filter(
          item =>
            item.name !== 'Deposits' &&
            item.name !== 'Users' &&
            item.name !== 'Customers' &&
            item.name !== 'Email History'
        )
      }
      loadMenu = loadMenu?.map(m => {
        if (m.name === 'Orders') {
          if (user.value?.userType === 'agency') {
            m.path = '/ag/orders'
          } else if (user.value?.userType === 'seller') {
            m.path = '/s/orders'
          }
        }
        return m
      })

      let { isSubUser, userType, authority } = user.value

      if (isSubUser === true) {
        let userRoleMenus = {}
        if (userType === UserTypeEnum.Agency) {
          userRoleMenus = AgencyRoleMenu
        }
        if (userType === UserTypeEnum.Seller) {
          userRoleMenus = SellerRoleMenu
        }
        if (authority && userRoleMenus[authority]) {
          const menus = userRoleMenus[authority] ?? []
          loadMenu = loadMenu?.filter(m => menus.includes(m?.path))
        }

        if (user.value?.authority === 'order_exporter') {
          loadMenu = MENU.orderExporter
        }
      }
      if (user.value.authority === 'customer_support') {
        loadMenu = MENU.customerSupportBambooz
      }
      if (user.value.authority === 'accounting') {
        loadMenu = MENU.accountingBambooz
      }
      if (user.value.authority === 'designer') {
        loadMenu = MENU.designerBambooz
      }
    }

    const handleOpen = (key, keyPath) => {
      // console.log(key, keyPath)
      key
      keyPath
    }
    const handleClose = (key, keyPath) => {
      // console.log(key, keyPath)
      key
      keyPath
    }
    return {
      menus: loadMenu,
      handleOpen,
      handleClose,
      isCollapse,
      menuActive,
    }
  },
}
</script>
