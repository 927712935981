import { Attribute } from './variants'

const REGEX_FORMATTER = /\B(?=(\d{3})+(?!\d))/g
const FORMATTER_USD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const formatter = ({ thousandsSeparator = ',', prefix = '' }) => {
  return (value: number) => {
    const _prefix = prefix ? `${prefix} ` : ''

    return `${_prefix}${value}`.replace(REGEX_FORMATTER, thousandsSeparator)
  }
}
export const formatCurrency = (value: number) => {
  const val = Number.isInteger(value) ? value : Number(Number(value).toFixed(2))

  return formatter({ thousandsSeparator: ',' })(val)
}

// These options are needed to round to whole numbers if that's what you want.
//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
export const formatterDollars = (value: number) => {
  return value ? FORMATTER_USD.format(value) : '$0'
}

export const formatterMoney = (value: number, currency: string) => {
  return value
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: `${currency}`,
      }).format(value)
    : `0`
}

export const displayAttribute = (attribute: Attribute) => {
  return attribute.name ?? ''
}

export const displayInStockStatus = (inStockStatus: boolean) => {
  return inStockStatus ? 'InStock' : 'OutOfStock'
}
