
import { defineComponent, reactive, ref, toRaw } from 'vue'
import { createHandler } from '@/services/createHandler'
import { stringRules } from '@/utils/formRules'
// import { useFetchData } from '@/composables'

// import { Plus } from '@element-plus/icons'

export default defineComponent({
  setup(props: any, context: any) {
    let dialogVisible = ref(false)
    let loading = ref(false)
    const agencyForm = ref()

    const state = reactive({
      form: {
        name: '',
        basecost: '',
        code: '',
        active: 1,
        manager: '',
      },
    })

    const formRules = {
      name: stringRules('name', 2, 128),
      code: stringRules('name', 2, 128),
    }

    const toggle = () => {
      console.log('toggle')
      dialogVisible.value = !dialogVisible.value
    }

    const onSubmit = async () => {
      const resource = `agencies`
      agencyForm.value.validate(async (valid: any) => {
        if (valid) {
          console.log(valid)
          const { create, isLoading } = createHandler(resource, state.form)
          loading = isLoading
          const { status } = await create()
          if (status === 201) {
            dialogVisible.value = false
            context.emit('change')
          }
        }
      })
    }
    // let basecosts = ref([])
    // const params = reactive({})
    // const getBasecosts = () => {
    //   const { documents, fetchData, isLoading, total } = useFetchData(
    //     'basecosts',
    //     params
    //   )
    //   loading = isLoading
    //   basecosts = documents as any
    //   fetchData()
    // }

    // getBasecosts()

    return {
      state,
      agencyForm,
      formRules,
      onSubmit,
      // basecosts,
      dialogVisible,
      loading,
      toggle,
    }
  },
})
