<template>
  <router-view></router-view>
</template>

<script>
import { onErrorCaptured, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useServiceAuth, useServiceApp } from './services'
import useThemeRepositories from '@/repositories/useThemeRepositories'
import { useStore } from 'vuex'

export default {
  setup() {
    const router = useRouter()
    const { getUserInfo } = useServiceAuth()
    const { getTheme, configTheme, setFaviIcon } = useThemeRepositories()
    const { intergrateApp } = useServiceApp()
    const store = useStore()
    const checkAuth = async () => {
      let params = new URL(document.location).searchParams
      // For public link
      if (new URL(document.location).pathname.startsWith('/cms/products/'))
        return
      // For others
      if (params.has('access_token')) {
        localStorage.setItem('access-token', params.get('access_token'))
      }

      if (params.has('app_token')) {
        localStorage.setItem('app-token', params.get('app_token'))
      }

      if (localStorage.getItem(`access-token`)) {
        try {
          const info = await getUserInfo()
          console.log('🚀 ~ file: App.vue:32 ~ checkAuth ~ info:', info)
          localStorage.setItem(`info`, JSON.stringify(info.data))
          localStorage.setItem(`type`, info.data.userType)
          store.commit('user/setUser', info.data)
          if (info.data.userType !== 'admin') {
            //await getTheme()
          } else {
            localStorage.setItem(`config`, JSON.stringify(configTheme))
          }

          await getTheme()
          router.push({ name: router.currentRoute.value.name })
        } catch (error) {
          console.log(error)
          localStorage.removeItem(`access-token`)
          localStorage.removeItem(`client_id`)
          localStorage.removeItem(`config`)
          localStorage.removeItem(`info`)
          localStorage.removeItem(`type`)

          // localStorage.clear()
          router.push(`/auth/login`)
        }
        // router.push({ name: router.currentRoute.value.name })
      } else {
        await getTheme()

        // chuyen trang referral
        const href = window.location.href
        if (href?.includes('/auth/register?r=')) {
          const url = new URL(href)
          const refCode = url.searchParams.get('r')
          router.push({
            name: 'register',
            query: { r: refCode },
          })
          return
        }
        if (href?.includes('/auth/reset-password')) {
          const url = new URL(href)
          const refCode = url.searchParams.get('token')
          if (refCode && refCode !== '') {
            router.push({
              name: 'reset-password',
              query: { token: refCode },
            })
          }
          return
        }

        // chuyen trang active success, register
        if (
          router.currentRoute.value.name === 'active-success' ||
          router.currentRoute.value.name === 'subcribe-success' ||
          router.currentRoute.value.name === 'reset-password' ||
          router.currentRoute.value.name === 'forgot-password' ||
          router.currentRoute.value.name === 'register' ||
          router.currentRoute.value.name === 'sign-in-welcome' ||
          router.currentRoute.value.name === 'sign-in-require'
        ) {
          if (router.currentRoute.value.name === 'sign-in-welcome') {
            router.push(`/auth/sign-in-require`)
            return true
            // console.log('Here')
          }

          router.push({ name: router.currentRoute.value.name })
        } else {
          router.push(`/auth/login`)
        }
      }
    }

    onMounted(() => {
      setFaviIcon()
    })
    checkAuth()

    onErrorCaptured((err, vm, info) => {
      console.log(err)
      router.push(`/error`)
    })

    return {
      checkAuth,
    }
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: Arial;
  src: url(./assets/fonts/ArialCE.ttf);
}

#app {
  font-family: Arial !important;
  height: 100vh;
}
.el-color-picker__trigger,
.el-pagination {
  i {
    display: flex !important;
    justify-content: center !important;
  }
}
.text-primary {
  color: var(--el-color-primary) !important;
}
.text-success {
  color: var(--el-color-success) !important;
}
.text-info {
  color: var(--el-color-info) !important;
}
.text-warning {
  color: var(--el-color-warning) !important;
}
.text-error {
  color: var(--el-color-error) !important;
}
.hide-croll {
  width: 100%;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.image-100 {
  width: 100px;
  height: 100px;
  border-radius: 5%;
}

.image-preview {
  .w-100 {
    width: 100px;
    border-radius: 2%;
  }
  .w-200 {
    width: 200px;
    border-radius: 2%;
  }
  .w-300 {
    width: 300px;
    border-radius: 2%;
  }
  .w-400 {
    width: 400px;
    border-radius: 2%;
  }
}

.image-200 {
  width: 200px;
  height: 200px;
  border-radius: 5%;
}
.order-item-artwork {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-auto-rows: 50%;
  grid-gap: 10px;
  .thumbnail-wrapper {
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    height: 100%;
    align-items: center;
    .feature-image {
      width: 100%;
    }
  }
  .order-artwork {
    display: flex;
    .order-artwork-upload {
      width: 100%;
    }
  }
}

.image-wrapper {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.avatar-uploader {
  width: 100%;
  height: 100%;
  .el-upload {
    border: 1px dashed #d9d9d9;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 3%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    .avatar-uploader-icon {
      font-size: 16px;
      color: #8c939d;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }
  .el-upload:hover {
    border-color: #409eff;
  }
}
.item-center {
  // display: grid;
  grid-auto-flow: row;
  gap: 10px;
  align-items: flex-start;
  justify-items: center;
}
.base-cost {
  color: var(--el-color-primary) !important;
  font-weight: 700;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}
.unread-message {
  background: #eeeeee;
}
.custom-block.warning {
  padding: 8px 16px;
  background-color: var(--el-color-warning-lighter);
  border-radius: 4px;
  border-left: 5px solid var(--el-color-warning);
  margin-bottom: 15px;
  width: 100%;
}
.custom-block .custom-block-title {
  font-weight: 700;
}
.custom-capitalize {
  // In hoa chu cai dau
  text-transform: capitalize;
}
// .header-between {}
.custom-text-mini {
  font-size: 0.75rem;
  word-break: normal;
}
.custom-white-space-pre-wrap {
  white-space: pre-wrap;
}
.custom-scrollDiv {
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
}
.bg-color-variant {
  background-color: var(--color);
  border-radius: 3px;
  border: 1px solid #ccc;
}
</style>
