import { createApp } from 'vue'
import App from './App.vue'

import './assets/styles/index.scss'
import '@/assets/styles/tailwind.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store'
import router from './router'
import { formatCurrency } from '@/utils/formatter'

const app = createApp(App)

app.use(router)
app.use(ElementPlus)
app.use(store)

//
app.directive('hoist', el => {
  if (el.tagName === 'TEMPLATE') {
    el.replaceWith(el.content)
  } else {
    el.replaceWith(...el.children)
  }
})

const DirectiveCurrency: any = {
  bind(el: any, binding: any) {
    el.innerText = formatCurrency(binding.value)
  },
  update(el: any, binding: any) {
    el.innerText = formatCurrency(binding.value)
  },
}
app.directive('currency', DirectiveCurrency)
app.mount('#app')
