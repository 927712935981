
import { Plus, View, Delete } from '@element-plus/icons'
import useUploadRepositories, {
  FileItem,
} from '@/repositories/useUploadRepositories'
import { imageUrl } from '@/utils/image'
import { ref, defineComponent } from 'vue'
import PreviewImage from '@/components/common/PreviewImage.vue'

export default defineComponent({
  components: { Plus, View, Delete, PreviewImage },
  props: {
    setData: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
      default: '',
    },
    permission: {
      type: String,
      default: 'all',
    },
    data: {
      type: String,
      default: 'src',
    },
    accept: {
      type: String,
      default: '*',
    },
  },
  setup(props, { emit }) {
    const { onUploadImage } = useUploadRepositories()
    const files = ref<any>([])
    if (props.multiple) {
      files.value = [...props.data]
    } else if (props.data && props.data !== '') {
      files.value.push(props.data)
    }
    const inputFile = ref<HTMLInputElement | null>(null)
    const upload = () => {
      inputFile.value?.click()
    }
    const views = ref<any>(props.setData[props.data])
    const onChangeFiles = async (e: any) => {
      if (props.multiple) {
        for (let index = 0; index < e.target.files.length; index++) {
          const file = e.target.files[index]
          const res = (await onUploadImage(file)) as FileItem & {
            status: 500
            error: any
          }
          files.value.push(res.path)
        }
        emit('callback', props.setData, files.value, props.multiple, props.data)
      } else {
        const file = e.target.files[0]
        const res = (await onUploadImage(file)) as FileItem & {
          status: 500
          error: any
        }
        files.value = []
        files.value.push(res.path)
      }
      emit('callback', props.setData, files.value, props.multiple, props.data)
    }
    const delImg = (index: number) => {
      files.value.splice(index, 1)
      emit('callback', props.setData, files.value, props.multiple, props.data)
    }
    const hoverIndex = ref(-1)
    const onMouse = (index: number) => {
      hoverIndex.value = index
    }
    const onMouseMove = () => {
      hoverIndex.value = -1
    }

    const previewImageDialog = ref<InstanceType<typeof PreviewImage>>()
    const onClickPreviewImage = (path: string) => {
      previewImageDialog.value?.toggle(imageUrl(path))
    }

    return {
      onUploadImage,
      onChangeFiles,
      files,
      upload,
      inputFile,
      delImg,
      onMouse,
      hoverIndex,
      views,
      onMouseMove,
      imageUrl,
      previewImageDialog,
      onClickPreviewImage,
    }
  },
})
