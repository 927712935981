
import Sider from '@/components/layouts/Sider.vue'
import { ref, computed, reactive, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { imageUrl } from '@/utils/image'
import {
  ArrowDown,
  BellFilled,
  UserFilled,
  SwitchButton,
  User,
} from '@element-plus/icons'
import { useStore } from 'vuex'
import axios from '@/services/axios'
import { UserTypes } from '@/utils/types'
import { formatterDollars } from '@/utils/formatter'
import { formatTime } from '@/utils/dateTime'
import useProfitsRepositories from '@/repositories/useProfitsRepositories'

export default {
  components: { Sider, ArrowDown, BellFilled, UserFilled, SwitchButton, User },
  setup() {
    const collapsed = ref(false)
    const router = useRouter()
    const { getters } = useStore()
    const user = computed(() => getters['user/getUser'])
    const balances = ref(0)
    const profit = ref(0)

    const notiParams = reactive({ page: 1, limit: 6 })
    const state = reactive({ notifications: [], notiCount: 0 })
    const { getAvailableProfits } = useProfitsRepositories()

    const loadNotifications = async () => {
      try {
        if (
          user.value?.userType === UserTypes.AGENCY ||
          user.value?.userType === UserTypes.SELLER_TEAM
        ) {
          const resource = 'push-notifications'
          const params = {
            page: notiParams.page,
            limit: notiParams.limit,
          } as any
          const res = (await axios.get(resource, { params })) as any
          if (res.status === 200 || res.status === 201) {
            const notifications = res.data
            state.notiCount = notifications.unviewCount
            Object.assign(state.notifications, notifications.data)
          }
        }
      } catch (err) {
        //
      }
    }
    loadNotifications()

    const loadMoreNotifications = () => {
      notiParams.limit += 3
    }

    watch(notiParams, loadNotifications)

    const loadBalance = async () => {
      try {
        if (user.value?.userType !== UserTypes.SELLER_TEAM) {
          return
        }
        const resource = `/balances/seller-teams`
        const { data, status } = await axios.get(resource)
        if (status === 200) {
          balances.value = data.amount
        }
        // if (user.value?.userType !== UserTypes.ADMINISTRATOR) {
        //   let resource = `/balances/`
        //   if (user.value?.userType === UserTypes.AGENCY) {
        //     resource += 'agencies'
        //   }
        //   if (user.value?.userType === UserTypes.SELLER_TEAM) {
        //     resource += 'seller-teams'
        //   }
        //   let { data, status } = await axios.get(resource)
        //   if (status === 200) {
        //     balances.value = data.amount
        //   }
        // }
      } catch (err) {
        console.log(err)
      }
    }
    // Load profits
    const loadProfit = async () => {
      if (user.value?.userType !== UserTypes.SELLER_TEAM) {
        return
      }
      try {
        const randomId = 'a127sd9xX1812'
        const { data } = (await getAvailableProfits(randomId)) as any
        profit.value = data?.remainProfit || 0
      } catch (error) {
        console.log('🚀 ~ file: Default.vue:261 ~ loadProfit ~ error:', error)
      }
    }
    onMounted(() => {
      if (user.value?.userType === UserTypes.SELLER_TEAM) {
        loadBalance()
        loadProfit()
        setInterval(() => {
          loadBalance()
          loadProfit()
        }, 60000)
      }
    })

    const logout = () => {
      localStorage.removeItem(`access-token`)
      localStorage.removeItem(`client_id`)
      localStorage.removeItem(`config`)
      localStorage.removeItem(`info`)
      localStorage.removeItem(`type`)
      // localStorage.clear()
      router.push(`/auth/login`)
    }
    let config = computed(() => {
      return getters['app/appConfig']
    })

    const markAsRead = async (row: { _id: any }) => {
      if (row._id) {
        updateNotification(row._id, true)
      }
    }

    const markAsUnread = async (row: { _id: any }) => {
      if (row._id) {
        updateNotification(row._id, false)
      }
    }

    const onClickNoti = (row: any) => {
      if (row?.viewed === false) {
        markAsRead(row)
      }
    }

    const updateNotification = async (id: any, viewed: boolean) => {
      console.log('updateNotification -- ', viewed, id)
      if (id) {
        const data = {
          viewed: viewed,
        }
        await axios.patch(`push-notifications/${id}`, data)
        loadNotifications()
      }
    }

    const tableRowClassName = (row: any) => {
      if (!row?.row) {
        return ''
      }
      if (row.row.viewed === true) {
        return 'viewed-row'
      }
      if (row.row.viewed === false) {
        return 'un-viewed-row'
      }
    }

    return {
      collapsed,
      logout,
      user,
      config,
      imageUrl,
      balances,
      profit,
      formatterDollars,
      state,
      markAsRead,
      markAsUnread,
      updateNotification,
      onClickNoti,
      tableRowClassName,
      loadMoreNotifications,
      formatTime,
    }
  },
}
