import { ref, computed, reactive, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useFetchData } from '@/composables'
import { useResource } from '@/services'
import axios from '@/services/axios'
import { AxiosResponse } from 'axios'

export default function useUserRepositories() {
  // const resource = `http://localhost:3001/api/users`
  const resource = `users`

  // const sellerTeamResource = `http://localhost:3001/api/seller-teams`
  const sellerTeamResource = `seller-teams`

  const state = reactive({})

  const getSellerTeamById = async (id: string) => {
    try {
      const { get } = useResource(sellerTeamResource)
      return await get(id)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getSellerTeams = async (params: any) => {
    try {
      const { documents, fetchData } = useFetchData(sellerTeamResource, params)
      await fetchData()
      return documents.value
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getDesigners = async (params: any) => {
    try {
      const { documents, fetchData } = useFetchData(
        `${resource}/designers`,
        params
      )
      await fetchData()
      return documents.value
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const mapUser = async (mappedUser: any): Promise<AxiosResponse<any, any>> => {
    return await axios.put(`mapped-user`, mappedUser)
  }

  const resetPassword = async (
    userId: string,
    password: string,
    confirmPassword: string
  ) => {
    const resetPasswordResource = `${resource}/${userId}/reset-password`
    const { patch } = useResource(resetPasswordResource)
    return patch({ password, confirmPassword })
  }

  return {
    resetPassword,
    getSellerTeams,
    getDesigners,
    mapUser,
    state,
    getSellerTeamById,
  }
}
