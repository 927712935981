
import { defineComponent, computed, reactive } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import axios from '@/services/axios'
import { useNotification } from '@/composables'

export default defineComponent({
  components: {
    sectionLayoutContent,
  },
  name: 'Template File Setting',
  setup() {
    const { error, success } = useNotification()
    const breadcrumbs = [
      {
        text: 'Settings',
        link: 'ag.settings.board',
        params: null,
      },
      {
        text: 'Template file',
        link: '',
        params: null,
      },
    ]

    const content = computed(() => {
      return { breadcrumbs }
    })

    const state = reactive({
      agency: {
        config: {
          templateFile: {},
        },
      },
      importOrderFilePath: '',
      importTrackingFilePath: '',
      dataCatalogFile: '',
    })

    const getAgencyInfo = async () => {
      const url = `/agencies/info`
      const res = await axios.get(url)
      if (res.status === 200 || res.status === 201) {
        state.agency = res.data as any
        state.importOrderFilePath =
          res.data?.config?.templateFile?.importOrderFilePath
        state.importTrackingFilePath =
          res.data?.config?.templateFile?.importTrackingFilePath
        state.dataCatalogFile = res.data?.config?.templateFile?.dataCatalogFile
      }
    }
    getAgencyInfo()

    const updateSettingResource = '/agencies/config'

    const onUpdateSettings = async () => {
      const formData = {
        config: {
          ...state.agency.config,
          templateFile: {
            importOrderFilePath: state.importOrderFilePath,
            importTrackingFilePath: state.importTrackingFilePath,
            dataCatalogFile: state.dataCatalogFile,
          },
        },
      }
      try {
        const res = await axios.patch(updateSettingResource, formData)
        if (res.status === 200 || res.status === 201) {
          success('Update success!')
        } else {
          error('Error!')
        }
      } catch (error) {
        console.log(error)
      }
    }

    return {
      content,
      getAgencyInfo,
      onUpdateSettings,
      state,
    }
  },
})
