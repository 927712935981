import api from '@/services/axios'
import axios from 'axios'

export interface FileItem {
  name?: string
  path: string | ''
  preview?: string
  size?: number
  presigned_url?: string
}

export default function useUploadRepositories() {
  // const objectId = () => {
  //   const hex = Math.floor(Date.now() / 1000).toString(16)
  //   const hex2 = Math.floor(Math.random() * 16).toString(16)
  //   return hex + ' '.repeat(16).replace(/./g, () => hex2)
  // }

  const cleanFileName = (fileName: string) => {
    const fileExt = fileName.split('.').pop()

    let fileNameUrl = fileName.split('.').slice(0, -1).join('.')

    fileNameUrl = fileNameUrl
      .replace(/\s+/gi, '-') // Replace white space with dash
      .replace(/[^a-zA-Z0-9]/gi, '-') // Strip any special charactere

    // const hex = Math.floor(Date.now() / 1000).toString(16)

    const cleanFileName = `${fileNameUrl}.${fileExt}`

    return cleanFileName
  }

  const getPresignedUrl = async (fileName: string) => {
    const urlData = await api.get(`/images/presigned_url?file_name=${fileName}`)
    return urlData
  }

  const onUploadImage = async (file: any) => {
    try {
      const fileName = cleanFileName(file.name)
      const urlData = (await getPresignedUrl(fileName)) as any
      if (urlData && urlData.status === 200) {
        const res = await axios.put(urlData.data.presigned_url, file, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (res && res.status === 200) {
          const fileUploaded: FileItem = {
            path: urlData.data.file_path as any,
            name: fileName,
            preview: '',
            size: file.size,
          }
          return fileUploaded
        }
      }
    } catch (error) {
      console.log('error', error)
      return null
    }
  }

  const onUploadTemplate = async (file: any) => {
    try {
      const fileName = cleanFileName(file.name)
      const urlData = (await getPresignedUrl(fileName)) as any
      if (urlData && urlData.status === 200) {
        const res = await axios.put(urlData.data.presigned_url, file, {
          headers: {
            'Content-Type': 'text/html',
          },
        })
        if (res && res.status === 200) {
          const fileUploaded: FileItem = {
            path: urlData.data.file_path as any,
            presigned_url: urlData.data.presigned_url as any,
          }
          return fileUploaded
        }
      }
    } catch (error) {
      console.log('error', error)
      return null
    }
  }

  return {
    onUploadImage,
    getPresignedUrl,
    cleanFileName,
    onUploadTemplate,
  }
}
