
import { computed, defineComponent, ref } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useRouter } from 'vue-router'
import Add from './Add.vue'
import { useFetchData } from '@/composables'
import moment from 'moment'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Add,
  },
  setup() {
    const breadcrumbs = [
      {
        text: 'Settings',
        link: 'ag.settings.board',
        params: null,
      },
      {
        text: 'Domain',
        link: '',
        params: null,
      },
    ]
    const title = 'Shops'
    const content = computed(() => {
      return { breadcrumbs, title, titleCreate }
    })
    const titleCreate = 'Add new domain'
    const router = useRouter()
    let loading = ref(false)
    const resource = 'domains'
    const { documents, fetchData, isLoading } = useFetchData(resource, null)
    loading = isLoading

    fetchData()

    const toRoute = (route: string) => {
      router.push({
        path: route,
      })
    }

    const fomartDate = (strTime: string) => {
      return moment(strTime).format('MM/DD/YYYY')
    }

    const addDomain = ref()
    const openDialogAddDomain = () => {
      addDomain.value?.toggle()
    }

    return {
      content,
      documents,
      toRoute,
      loading,
      fomartDate,
      addDomain,
      openDialogAddDomain,
    }
  },
})
