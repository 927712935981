
import { defineComponent, ref } from 'vue'
import moment from 'moment'
import Update from './Update.vue'
import { Delete, Edit } from '@element-plus/icons'
import { deleteHandler } from '@/services/deleteHandler'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Update,
    Delete,
    Edit,
  },
  setup(props: any, context: any) {
    let selected = ref({})
    const baseResource = 'agencies'
    const updateDialog = ref<InstanceType<typeof Update>>()
    let loading = ref(false)
    const route = useRoute()
    const fomartDate = (strTime: string) => {
      return moment(strTime).format('MM/DD/YYYY')
    }

    const handleDelete = async (agency: any) => {
      const { deleteById, isLoading } = deleteHandler(baseResource)
      loading = isLoading
      await deleteById(agency._id)
      context.emit('updateFinish')
    }

    const openUpdateDialog = (row: any) => {
      selected.value = row
      updateDialog.value?.toggle(row.id)
    }

    const forceUpdate = async () => {
      context.emit('updateFinish')
    }

    const view = (agency: any) => {
      console.log(agency)
    }

    return {
      loading,
      updateDialog,
      selected,
      fomartDate,
      handleDelete,
      forceUpdate,
      openUpdateDialog,
      view,
    }
  },
})
