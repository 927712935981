
import useSellerTeamRepositories from '@/repositories/useSellerTeamRepositories'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const { getAllSellerTeam } = useSellerTeamRepositories()
    const sellerTeams = ref()
    const isGettingSellerTeam = ref(true)
    const getSellerTeamList = async () => {
      sellerTeams.value = await getAllSellerTeam()
      isGettingSellerTeam.value = false
    }
    getSellerTeamList()

    return { getSellerTeamList, sellerTeams, isGettingSellerTeam }
  },
})
