import { ref, computed, reactive, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useFetchData } from "@/composables";
import { useResource } from "@/services";
import axios from "@/services/axios";
import { AxiosResponse } from 'axios'

export default function useAgencyRepositories() {
  
  const state = reactive({
  });
  
  const getAgencies = async (params: any) => {
    try {
      const { documents, fetchData } = useFetchData('agencies', params);
      await fetchData();
      return documents.value;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const updateCatalogAgency = async (agencyId: any, params: any) => {
    const { patch } = useResource(`agencies/${agencyId}/product-catalogs`);
    try {
      const res = await patch(params);
      return res;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }
  
  return {
    getAgencies,
    updateCatalogAgency,
    state
  }
  
}
