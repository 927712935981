export default {
  StatusContinue: 100, //  7231, 6.2.1
  StatusSwitchingProtocols: 101, //  7231, 6.2.2
  StatusProcessing: 102, //  2518, 10.1

  StatusOK: 200, //  7231, 6.3.1
  StatusCreated: 201, //  7231, 6.3.2
  StatusAccepted: 202, //  7231, 6.3.3
  StatusNonAuthoritativeInfo: 203, //  7231, 6.3.4
  StatusNoContent: 204, //  7231, 6.3.5
  StatusResetContent: 205, //  7231, 6.3.6
  StatusPartialContent: 206, //  7233, 4.1
  StatusMultiStatus: 207, //  4918, 11.1
  StatusAlreadyReported: 208, //  5842, 7.1
  StatusIMUsed: 226, //  3229, 10.4.1

  StatusMultipleChoices: 300, //  7231, 6.4.1
  StatusMovedPermanently: 301, //  7231, 6.4.2
  StatusFound: 302, //  7231, 6.4.3
  StatusSeeOther: 303, //  7231, 6.4.4
  StatusNotModified: 304, //  7232, 4.1
  StatusUseProxy: 305, //  7231, 6.4.5
  _: 306, //  7231, 6.4.6 (Unused)
  StatusTemporaryRedirect: 307, //  7231, 6.4.7
  StatusPermanentRedirect: 308, //  7538, 3

  StatusBadRequest: 400, //  7231, 6.5.1
  StatusUnauthorized: 401, //  7235, 3.1
  StatusPaymentRequired: 402, //  7231, 6.5.2
  StatusForbidden: 403, //  7231, 6.5.3
  StatusNotFound: 404, //  7231, 6.5.4
  StatusMethodNotAllowed: 405, //  7231, 6.5.5
  StatusNotAcceptable: 406, //  7231, 6.5.6
  StatusProxyAuthRequired: 407, //  7235, 3.2
  StatusRequestTimeout: 408, //  7231, 6.5.7
  StatusConflict: 409, //  7231, 6.5.8
  StatusGone: 410, //  7231, 6.5.9
  StatusLengthRequired: 411, //  7231, 6.5.10
  StatusPreconditionFailed: 412, //  7232, 4.2
  StatusRequestEntityTooLarge: 413, //  7231, 6.5.11
  StatusRequestURITooLong: 414, //  7231, 6.5.12
  StatusUnsupportedMediaType: 415, //  7231, 6.5.13
  StatusRequestedRangeNotSatisfiable: 416, //  7233, 4.4
  StatusExpectationFailed: 417, //  7231, 6.5.14
  StatusTeapot: 418, //  7168, 2.3.3
  StatusUnprocessableEntity: 422, //  4918, 11.2
  StatusLocked: 423, //  4918, 11.3
  StatusFailedDependency: 424, //  4918, 11.4
  StatusUpgradeRequired: 426, //  7231, 6.5.15
  StatusPreconditionRequired: 428, //  6585, 3
  StatusTooManyRequests: 429, //  6585, 4
  StatusRequestHeaderFieldsTooLarge: 431, //  6585, 5
  StatusUnavailableForLegalReasons: 451, //  7725, 3

  StatusInternalServerError: 500, //  7231, 6.6.1
  StatusNotImplemented: 501, //  7231, 6.6.2
  StatusBadGateway: 502, //  7231, 6.6.3
  StatusServiceUnavailable: 503, //  7231, 6.6.4
  StatusGatewayTimeout: 504, //  7231, 6.6.5
  StatusHTTPVersionNotSupported: 505, //  7231, 6.6.6
  StatusVariantAlsoNegotiates: 506, //  2295, 8.1
  StatusInsufficientStorage: 507, //  4918, 11.5
  StatusLoopDetected: 508, //  5842, 7.2
  StatusNotExtended: 510, //  2774, 7
  StatusNetworkAuthenticationRequired: 511, //  6585, 6
};
