
import { defineComponent, ref } from 'vue'
import { CopyDocument } from '@element-plus/icons'
import { useNotification } from '@/composables'
import { imageUrl } from '@/utils/image'
import { copyToClipboard } from '@/utils/string'

export default defineComponent({
  components: { CopyDocument },
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const dialogVisible = ref<boolean>(false)
    const imgView = ref<string>(props.url)

    const { success } = useNotification()

    const copyToClipboardUrl = (text: string) => {
      copyToClipboard(text)
      success('Copy to clipboard')
    }

    const toggle = (url: any) => {
      imgView.value = url
      dialogVisible.value = !dialogVisible.value
    }

    const close = () => {
      dialogVisible.value = false
    }

    return {
      dialogVisible,
      imgView,
      imageUrl,
      copyToClipboardUrl,
      toggle,
      close,
    }
  },
})
