import Sellers from '@/pages/agency/sellers/index.vue'
import Users from '@/pages/agency/users/index.vue'
import Profile from '@/components/common/Profile.vue'
import Detail from '@/pages/agency/sellers/components/Detail.vue'
import Settings from '@/pages/agency/settings/index.vue'
import Domain from '@/pages/agency/settings/domain/index.vue'
import Payment from '@/pages/agency/settings/payment/index.vue'
import ShippingSetting from '@/pages/agency/settings/shipping/index.vue'
import LandingPageSetting from '@/pages/agency/settings/landing-page/index.vue'
import TemplateFileSetting from '@/pages/agency/settings/template-file/index.vue'
import OthersSetting from '@/pages/agency/settings/others/index.vue'
import EmailSetting from '@/pages/agency/settings/email/index.vue'
import CustomizeEmailTemplate from '@/pages/agency/settings/email/CustomizeEmail.vue'
import { RouterView } from 'vue-router'
import Theme from '@/pages/agency/theme/index.vue'

export default [
  {
    path: '/ag/sellers',
    name: 'ag.sellers',
    component: Sellers,
  },
  {
    path: '/ag/sellers/:id',
    name: 'ag.sellers.detail',
    component: Detail,
  },
  {
    path: '/ag/users',
    name: 'ag.users',
    component: RouterView,
    redirect: '/ag/users/list',
    children: [
      {
        path: '/ag/users/list',
        name: 'ag.users.list',
        component: Users,
      },
      {
        path: '/ag/users/:id',
        name: 'ag.users.profile',
        component: Profile,
      },
    ],
  },
  {
    path: '/ag/settings',
    name: 'ag.settings',
    component: RouterView,
    redirect: '/ag/settings/board',
    children: [
      {
        path: '/ag/settings/board',
        name: 'ag.settings.board',
        component: Settings,
      },
      {
        path: '/ag/settings/domain',
        name: 'ag.settings.domain',
        component: Domain,
      },
      {
        path: '/ag/settings/theme',
        name: 'ag.settings.theme',
        component: Theme,
      },
      {
        path: '/ag/settings/payment',
        name: 'ag.settings.payment',
        component: Payment,
      },
      {
        path: '/ag/settings/email',
        name: 'ag.settings.email',
        component: EmailSetting,
      },
      {
        path: '/ag/settings/shipping',
        name: 'ag.settings.shipping',
        component: ShippingSetting,
      },
      {
        path: '/ag/settings/landing-page',
        name: 'ag.settings.landing-page',
        component: LandingPageSetting,
      },
      {
        path: '/ag/settings/email/customize',
        name: 'ag.settings.email.customize',
        component: CustomizeEmailTemplate,
      },
      {
        path: '/ag/settings/template-file',
        name: 'ag.settings.template-file',
        component: TemplateFileSetting,
      },
      {
        path: '/ag/settings/others',
        name: 'ag.settings.others',
        component: OthersSetting,
      },
    ],
  },
  {
    path: '/ag/orders',
    name: 'agency.orders',
    component: () => import('@/pages/agency/orders/index.vue'),
  },
  {
    path: '/ag/orders/:id',
    name: 'agency.orders.detail',
    component: () => import('@/pages/agency/orders/Detail.vue'),
  },
  {
    path: '/ag/orders/:id/refund',
    name: 'agency.orders.refund',
    component: () => import('@/pages/agency/orders/Refund.vue'),
  },
  {
    path: '/ds/orders',
    name: 'designer.orders',
    component: () => import('@/pages/agency/upload-designs/index.vue'),
  },
  {
    path: '/ds/orders/:id',
    name: 'agency.designer.detail',
    component: () => import('@/pages/agency/upload-designs/Detail.vue'),
  },
  {
    path: '/ag/invoices',
    name: 'agency.invoices',
    component: () => import('@/pages/agency/invoices/index.vue'),
  },
  {
    path: '/ag/deposits',
    name: 'agency.deposits',
    component: () => import('@/pages/agency/deposits/index.vue'),
  },
  {
    path: '/ag/base-cost',
    name: 'agency.baseCost',
    component: () => import('@/pages/agency/base-cost/index.vue'),
  },
  {
    path: '/ag/base-cost/group/:groupId/create',
    name: 'agency.baseCost.create',
    component: () => import('@/pages/agency/base-cost/Create.vue'),
  },
  {
    path: '/ag/base-cost/group/:groupId/update/:id',
    name: 'agency.baseCost.update',
    component: () => import('@/pages/agency/base-cost/Update.vue'),
  },
  {
    path: '/ag/invoices/:id',
    name: 'agency.invoices.detail',
    component: () => import('@/pages/agency/invoices/Detail.vue'),
  },
  {
    path: '/ag/catalogs',
    name: 'ag.catalogs',
    component: () => import('@/pages/agency/catalog/index.vue'),
  },
  {
    path: '/ag/catalogs/create',
    name: 'ag.catalog-create',
    component: () => import('@/pages/agency/catalog/Create.vue'),
  },
  {
    path: '/ag/catalogs/create-dropship',
    name: 'ag.catalog-create.dropship',
    component: () => import('@/pages/agency/catalog/DropCreate.vue'),
  },
  {
    path: '/ag/base-cost/catalogs/:id',
    name: 'ag.base-cost.catalog-update',
    component: () => import('@/pages/agency/catalog/UpdateCatalog.vue'),
  },
  {
    path: '/ag/base-cost/catalog-cost/:id/basecost/:basecostId',
    name: 'ag.base-cost.catalog-update-cost',
    component: () =>
      import('@/pages/agency/base-cost/components/UpdateCatalog.vue'),
  },
  {
    path: '/ag/tracking',
    name: 'ag.tracking',
    component: () => import('@/pages/agency/tracking/index.vue'),
  },
  {
    path: '/ag/tracking/:id',
    name: 'ag.tracking.detail',
    component: () => import('@/pages/agency/tracking/components/Detail.vue'),
  },
  {
    path: '/ag/warehouses',
    name: 'agency.warehouses',
    component: () => import('@/pages/agency/warehouses/index.vue'),
  },
  {
    path: '/ag/category-catalog',
    name: 'agency.category-catalog',
    component: () => import('@/pages/agency/category-catalog/index.vue'),
  },
  {
    path: '/ag/referrals',
    name: 'agency.referrals',
    component: () => import('@/pages/agency/referrals/index.vue'),
  },
  {
    path: '/ag/image-library',
    name: 'agency.image-library',
    component: () => import('@/pages/agency/image-library/index.vue'),
  },
  {
    path: '/ag/tickets',
    name: 'agency.tickets',
    component: () => import('@/pages/agency/tickets/index.vue'),
  },
  {
    path: '/ag/customers',
    name: 'agency.customers',
    component: () => import('@/pages/agency/customers/index.vue'),
  },
  {
    path: '/ag/stores',
    name: 'agency.stores',
    component: () => import('@/pages/agency/stores/index.vue'),
  },
  {
    path: '/ag/order-items',
    name: 'agency.order-items',
    component: () => {
      return import('@/pages/agency/order-items/index.vue')
    },
  },
  {
    path: '/ag/payouts',
    name: 'agency.payouts',
    component: () => import('@/pages/agency/payouts/index.vue'),
  },
  {
    path: '/ag/email-history',
    name: 'agency.email-history',
    component: () => import('@/pages/agency/email-history/index.vue'),
  },
  {
    path: '/ag/kpis',
    name: 'agency.kpis',
    component: () => import('@/pages/agency/kpis/index.vue'),
  },
  {
    path: '/ag/image-library',
    name: 'agency.image-library',
    component: () => import('@/pages/agency/image-library/index.vue'),
  },
  // {
  //   path: '/ag/proxy-groups',
  //   name: 'agency.proxy-groups',
  //   component: () => import('@/pages/agency/proxy-groups/index.vue'),
  // },
]
