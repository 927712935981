import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_close_button = _resolveComponent("close-button")!
  const _component_save_button = _resolveComponent("save-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    title: "Reset Password",
    width: "35%"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_close_button, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close()))
      }),
      _createVNode(_component_save_button, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSubmitChangePwd()))
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: _ctx.form,
        rules: _ctx.formRules,
        "label-width": "25%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            prop: "password",
            label: "Password"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.password) = $event)),
                placeholder: "Password",
                type: "password",
                autocomplete: false,
                "show-password": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "confirmPassword",
            label: "Confirm Password:"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.confirmPassword,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.confirmPassword) = $event)),
                placeholder: "Confirm Password",
                type: "password",
                autocomplete: false,
                "show-password": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}