
import { defineComponent, reactive, ref, watch } from 'vue'
import { createHandler } from '@/services/createHandler'
import { stringRules, required } from '@/utils/formRules'
import { useRoute } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { useNotification } from '@/composables'
import { formatterDollars } from '@/utils/formatter'

// import { Plus } from '@element-plus/icons'

export default defineComponent({
  props: {
    agencyName: {
      type: String,
      default: () => {
        return 'undefined'
      },
    },
    currentAmount: {
      type: Number,
      default: () => {
        return 0
      },
    },
  },
  setup(props: any, context: any) {
    let dialogVisible = ref(false)
    let loading = ref(false)
    const depositForm = ref()
    const route = useRoute()
    const { error, success } = useNotification()
    const statesOfTrans = [
      {
        value: 'approved',
        label: 'Approved',
      },
      {
        value: 'pending',
        label: 'Pending',
      },
    ]
    const typesOfDeposits = [
      {
        value: true,
        label: 'Cashback',
      },
      {
        value: false,
        label: 'Topup',
      },
    ]
    const total = ref(0)
    const state = reactive({
      deposit: {
        amount: 1,
        payWith: '',
        transactionId: '',
        note: '',
        status: 'approved',
        isCashback: true,
      },
    })

    const formRules = {
      transactionId: stringRules('Transaction ID', 3, 50),
      status: required('Status'),
      note: stringRules('Note', 2, 500),
      payWith: required('Paywith'),
    }

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const onSubmit = async () => {
      const agencyId = route.params.id
      if (!agencyId) return

      const resource = `deposits/seller-teams/${route.params.id}`
      const message = `New balance is ${formatterDollars(total.value)}`
      depositForm.value.validate(async (valid: any) => {
        if (valid) {
          ElMessageBox.confirm(message)
            .then(async () => {
              state.deposit.amount = Number(state.deposit.amount)
              const { create, isLoading } = createHandler(
                resource,
                state.deposit
              )
              loading = isLoading
              const { status, data } = await create()
              if (status === 201 || status === 200) {
                success('Transaction confirmed')

                dialogVisible.value = false
                await context.emit('changeBalance')
                depositForm.value?.resetFields()
              } else {
                error(data.message)
              }
            })
            .catch(err => {
              console.log(err)
              error
            })
        }
      })
    }

    const onChangeBalance = (newBalance: any) => {
      total.value = parseFloat(props.currentAmount) + parseFloat(newBalance)
    }

    const watchTotal = watch(props, () => {
      total.value = props.currentAmount
    })

    const closeHandle = () => {
      dialogVisible.value = false
      depositForm.value?.resetFields()
    }

    return {
      state,
      depositForm,
      formRules,
      onSubmit,
      statesOfTrans,
      dialogVisible,
      loading,
      toggle,
      total,
      onChangeBalance,
      watchTotal,
      closeHandle,
      formatterDollars,
      typesOfDeposits,
    }
  },
})
