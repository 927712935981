<template>
  <el-container class="h-full">
    <el-header
      style="
        text-align: right;
        font-size: 12px;
        box-shadow: 0 2px 1px -1px #dddd;
      "
    >
      <div class="flex items-center justify-between w-full h-full logo">
        <div style="max-height: 90px">
          <el-image
            style="max-height: 90px; max-width: 90px"
            :src="config && config.logo ? imageUrl(config.logo, true, 200) : ``"
            :fit="`contain`"
            :title="config?.title"
          />
        </div>
      </div>
    </el-header>
    <el-row v-if="loading"><el-skeleton /></el-row>
    <div
      v-else
      class="w-full lg:w-8/12 mx-auto mt-3 flex gap-8 flex-col lg:flex-row"
    >
      <div class="w-10/12 lg:w-1/2 mx-auto" v-if="product.otherMockup">
        <div v-if="product.otherMockup.length > 1">
          <ImageCarousel :mockup-gallery="product.otherMockup" />
        </div>
        <div v-else>
          <img
            v-if="product.otherMockup[0] == null"
            class="object-cover w-full h-auto"
            src="https://resize.latteprint.com/img/992/992/resize/2023-04-26/1682495798725_no-image.jpg"
            :alt="product.title"
          />
          <img
            v-else
            class="object-cover w-full h-auto"
            :src="resizeMockupGalleryMain(product.otherMockup)"
            :alt="product.title"
          />
        </div>
      </div>
      <div class="information w-10/12 lg:w-1/2 mx-auto">
        <DetailProduct
          v-if="product.options"
          :product="product"
          :options="product.options"
        />
      </div>
    </div>
  </el-container>
</template>
<script>
import { computed, onMounted, reactive, ref } from 'vue'
import ImageCarousel from '@/pages/public/product/components/ImageCarousel.vue'
import DetailProduct from '@/pages/public/product/components/DetailProduct.vue'
import usePublicRepositories from '@/repositories/usePublicRepositories'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  components: {
    ImageCarousel,
    DetailProduct,
  },
  setup() {
    const currentSlide = 0
    const product = ref({})
    const { getters } = useStore()
    const route = useRoute()
    const loading = ref(false)

    let config = computed(() => {
      return getters['app/appConfig']
    })
    const isLattePrintUrl = url => {
      const bucketAppUrl = `https://static.latteprint.com/`
      if (!url?.trim()) {
        return false
      }
      url = url.trim()
      if (url.startsWith(bucketAppUrl)) {
        return true
      }
    }
    const imageUrl = (url, isResize, width, height) => {
      const bucketAppUrl = `https://static.latteprint.com/`
      if (!url?.trim()) {
        return url
      }
      url = url.trim()

      // neu ko phai link latteprint
      if (!isLattePrintUrl(url)) {
        return url
      }
      url = url.replace(bucketAppUrl, '')

      let baseURL = bucketAppUrl
      const resizeBaseUrl = bucketAppUrl.replace(
        'https://static.latteprint.com/',
        'https://resize.bambooz.io/'
      )
      // la link latteprint
      if (isResize) {
        url = url.replace(bucketAppUrl, '')
        if (width > 0) {
          if (height > 0) {
            baseURL = `${resizeBaseUrl}img/${width}/${height}/resize/`
          } else {
            baseURL = `${resizeBaseUrl}img/${width}/resize/`
          }
        } else {
          // ảnh resize với dung lượng nhỏ hơn
          baseURL = `${resizeBaseUrl}img/0/0/resize/`
        }
      }
      return baseURL + url
    }

    const resizeMockupGalleryMain = mockups => {
      const w = 992
      mockups = mockups.map(galleryItem => {
        if (
          galleryItem.endsWith('.png') ||
          galleryItem.endsWith('.jpg') ||
          galleryItem.endsWith('.jpeg') ||
          galleryItem.endsWith('.webp')
        ) {
          if (galleryItem.startsWith('http')) {
            return imageUrl(galleryItem, true, w, w)
          }
          return (galleryItem = `https://resize.bambooz.io/img/${w}/${w}/resize/${galleryItem}`)
        }
        return galleryItem
      })
      return mockups
    }
    const { getProduct } = usePublicRepositories()
    const getData = async () => {
      loading.value = true

      const id = route.params.id
      const response = await getProduct(id)
      if (response && response.data) {
        product.value = response.data
      }

      loading.value = false
    }
    onMounted(() => {
      getData()
    })

    return {
      config,
      currentSlide,
      product,
      loading,
      resizeMockupGalleryMain,
      imageUrl,
    }
  },
}
</script>
<style scoped></style>
