
import { defineComponent, reactive, ref, watch } from 'vue'
// import { ElMessageBox } from 'element-plus'
import { updateHandler } from '@/services/updateHandler'
import { useResource } from '@/services/useResource'
import { useNotification, useFetchData } from '@/composables'
import { emailRule, required, stringRules } from '@/utils/formRules'

// import { Plus } from '@element-plus/icons'
// interface Agency {
//   active: number
//   name: string
//   code: string
//   priceList: string
//   createdAt: string
//   updatedAt: string
//   id: string
// }
export default defineComponent({
  setup(props: any, context: any) {
    let dialogVisible = ref(false)
    let loading = ref(false)
    let resource = 'seller-teams'
    const sellerId = ref('')
    const { error, success } = useNotification()
    let basecosts = ref([])

    const formRules = {
      name: stringRules('Name', 2, 128),
      // code: stringRules('Code', 2, 128),
      manager: emailRule,
      basecost: required('Basecost'),
      referralCode: required('Referral Code'),
      // supporter: required('Supporter'),
    }

    const state = reactive({
      form: {
        id: '',
        name: '',
        basecost: { id: '' },
        email: '',
        code: '',
        autoApproveOrder: false,
        getOrderAPI: true,
        active: 0,
        supporter: { id: '', email: '' },
        sellerType: '',
        rate: 0,
        referralCode: '',
        pendingPayoutDays: 2,
      },
    })

    const fectchRecord = async (id: string) => {
      loading.value = true
      const { get } = useResource(resource)
      const { data, status } = await get(id)
      if (status === 200) {
        state.form = data as any
        if (!state.form.supporter) {
          state.form.supporter = { id: '', email: '' }
        }
        if (!state.form.basecost) {
          state.form.basecost = { id: '' }
        }
        loading.value = false
      } else {
        error('Opps...Unexpected error. Please try again latter!')
      }
    }
    const params = reactive({})
    const getBasecosts = () => {
      const { documents, fetchData, isLoading, total } = useFetchData('basecosts', params)
      loading = isLoading
      basecosts = documents as any
      fetchData()
    }

    getBasecosts()

    const sellerTeamForm = ref()
    const onSubmit = async () => {
      const resource = `seller-teams/${sellerId.value}`
      let patchData = {
        name: state.form.name,
        code: state.form.code,
        basecost: state.form.basecost.id,
        active: state.form.active || false,
        autoApproveOrder: state.form.autoApproveOrder,
        getOrderAPI: state.form.getOrderAPI,
        supporter: state.form.supporter.id || null,
        referralCode: state.form.referralCode,
        sellerType: state.form.sellerType,
        rate: state.form.rate,
        pendingPayoutDays: state.form.pendingPayoutDays,
      }
      sellerTeamForm.value.validate(async (valid: boolean) => {
        if (valid) {
          const { update, isLoading } = updateHandler(resource, patchData)
          loading = isLoading
          const { status } = await update()
          if (status === 200) {
            dialogVisible.value = false
            context.emit('change')
            sellerTeamForm.value?.resetFields()
            success('Updated')
          } else {
            error('Opps...Unexpected error. Please try again latter!')
          }
        }
      })
    }

    const watchModalOpen = watch(dialogVisible, () => {
      if (dialogVisible.value === true) {
        fectchRecord(sellerId.value)
      }
    })

    const handleClose = () => {
      dialogVisible.value = false
      sellerTeamForm.value?.resetFields()
    }
    const toggle = (id: string) => {
      sellerId.value = id
      dialogVisible.value = !dialogVisible.value
    }

    const supportParams = reactive({limit:1000, page:1})
    let supporters = ref([])
    const me = localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info') as string) : {}
    const getSupporters = () => {
      const { documents, fetchData, isLoading, total } = useFetchData(`agencies/staffs`, supportParams)
      loading = isLoading
      supporters = documents as any
      fetchData()
    }

    getSupporters()

    return {
      me,
      state,
      onSubmit,
      sellerTeamForm,
      formRules,
      dialogVisible,
      loading,
      fectchRecord,
      watchModalOpen,
      toggle,
      basecosts,
      supporters,
      getBasecosts,
      handleClose,
    }
  },
})
