import axios from '@/services/axios'

export default function usePublicRepositories() {
  const baseResource = '/public/products'
  const getProduct = async (params: any) => {
    try {
      const resource = `${baseResource}/${params}`
      return await axios.get(resource, {})
    } catch (error) {
      console.log(error)
      return null
    }
  }

  return {
    baseResource,
    getProduct,
  }
}
